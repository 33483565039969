/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DictionaryConfigDto } from '../models/dictionary-config-dto';
import { DictionaryExcelRequestDto } from '../models/dictionary-excel-request-dto';
import { DictionaryExcelResponseDto } from '../models/dictionary-excel-response-dto';
import { DictionaryRequestDto } from '../models/dictionary-request-dto';
import { DictionaryTableDto } from '../models/dictionary-table-dto';
import { Product } from '../models/product';
import { ProductDto } from '../models/product-dto';
import { SearchSelectItemDto } from '../models/search-select-item-dto';

@Injectable({
  providedIn: 'root',
})
export class ProductsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation productsControllerSelect
   */
  static readonly ProductsControllerSelectPath = '/api/products/select';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productsControllerSelect()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  productsControllerSelect$Response(params?: {

    /**
     * Ключ компании
     */
    company?: string;

    /**
     * Год
     */
    year?: number;

    /**
     * Месяц
     */
    month?: number;

    /**
     * Не использовать filterQuery()
     */
    full?: boolean;

  }): Observable<StrictHttpResponse<Array<SearchSelectItemDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ProductsService.ProductsControllerSelectPath, 'get');
    if (params) {

      rb.query('company', params.company, {});
      rb.query('year', params.year, {});
      rb.query('month', params.month, {});
      rb.query('full', params.full, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SearchSelectItemDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `productsControllerSelect$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  productsControllerSelect(params?: {

    /**
     * Ключ компании
     */
    company?: string;

    /**
     * Год
     */
    year?: number;

    /**
     * Месяц
     */
    month?: number;

    /**
     * Не использовать filterQuery()
     */
    full?: boolean;

  }): Observable<Array<SearchSelectItemDto>> {

    return this.productsControllerSelect$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SearchSelectItemDto>>) => r.body as Array<SearchSelectItemDto>)
    );
  }

  /**
   * Path part for operation productsControllerUpdate
   */
  static readonly ProductsControllerUpdatePath = '/api/products/update/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productsControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  productsControllerUpdate$Response(params: {
    id: string;
      body: ProductDto
  }): Observable<StrictHttpResponse<ProductDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProductsService.ProductsControllerUpdatePath, 'post');
    if (params) {

      rb.path('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `productsControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  productsControllerUpdate(params: {
    id: string;
      body: ProductDto
  }): Observable<ProductDto> {

    return this.productsControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<ProductDto>) => r.body as ProductDto)
    );
  }

  /**
   * Path part for operation productsControllerCreate
   */
  static readonly ProductsControllerCreatePath = '/api/products/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productsControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  productsControllerCreate$Response(params: {
      body: ProductDto
  }): Observable<StrictHttpResponse<ProductDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProductsService.ProductsControllerCreatePath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `productsControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  productsControllerCreate(params: {
      body: ProductDto
  }): Observable<ProductDto> {

    return this.productsControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<ProductDto>) => r.body as ProductDto)
    );
  }

  /**
   * Path part for operation productsControllerCount
   */
  static readonly ProductsControllerCountPath = '/api/products/count';

  /**
   * Колличество записей.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productsControllerCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  productsControllerCount$Response(params?: {

    /**
     * Дата на которую делается выборка
     */
    date?: string;

    /**
     * Компания
     */
    company?: string;

  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, ProductsService.ProductsControllerCountPath, 'get');
    if (params) {

      rb.query('date', params.date, {});
      rb.query('company', params.company, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Колличество записей.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `productsControllerCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  productsControllerCount(params?: {

    /**
     * Дата на которую делается выборка
     */
    date?: string;

    /**
     * Компания
     */
    company?: string;

  }): Observable<number> {

    return this.productsControllerCount$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation productsControllerFind
   */
  static readonly ProductsControllerFindPath = '/api/products/find';

  /**
   * Выборка.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productsControllerFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  productsControllerFind$Response(params?: {

    /**
     * Выборка полей
     */
    select?: string;

    /**
     * Сортировка
     */
    sort?: string;

    /**
     * Пропустить N записей
     */
    skip?: number;

    /**
     * Выбрать N записей
     */
    limit?: number;

    /**
     * Дата на которую делается выборка
     */
    date?: string;

    /**
     * Компания
     */
    company?: string;

  }): Observable<StrictHttpResponse<Array<Product>>> {

    const rb = new RequestBuilder(this.rootUrl, ProductsService.ProductsControllerFindPath, 'get');
    if (params) {

      rb.query('select', params.select, {});
      rb.query('sort', params.sort, {});
      rb.query('skip', params.skip, {});
      rb.query('limit', params.limit, {});
      rb.query('date', params.date, {});
      rb.query('company', params.company, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Product>>;
      })
    );
  }

  /**
   * Выборка.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `productsControllerFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  productsControllerFind(params?: {

    /**
     * Выборка полей
     */
    select?: string;

    /**
     * Сортировка
     */
    sort?: string;

    /**
     * Пропустить N записей
     */
    skip?: number;

    /**
     * Выбрать N записей
     */
    limit?: number;

    /**
     * Дата на которую делается выборка
     */
    date?: string;

    /**
     * Компания
     */
    company?: string;

  }): Observable<Array<Product>> {

    return this.productsControllerFind$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Product>>) => r.body as Array<Product>)
    );
  }

  /**
   * Path part for operation productsControllerGet
   */
  static readonly ProductsControllerGetPath = '/api/products/find/{id}';

  /**
   * Получение записи.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productsControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  productsControllerGet$Response(params: {

    /**
     * ObjectId записи
     */
    id: string;

  }): Observable<StrictHttpResponse<Product>> {

    const rb = new RequestBuilder(this.rootUrl, ProductsService.ProductsControllerGetPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Product>;
      })
    );
  }

  /**
   * Получение записи.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `productsControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  productsControllerGet(params: {

    /**
     * ObjectId записи
     */
    id: string;

  }): Observable<Product> {

    return this.productsControllerGet$Response(params).pipe(
      map((r: StrictHttpResponse<Product>) => r.body as Product)
    );
  }

  /**
   * Path part for operation productsControllerDictionaryConfig
   */
  static readonly ProductsControllerDictionaryConfigPath = '/api/products/config';

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productsControllerDictionaryConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  productsControllerDictionaryConfig$Response(params?: {
    query?: {  };

  }): Observable<StrictHttpResponse<DictionaryConfigDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProductsService.ProductsControllerDictionaryConfigPath, 'get');
    if (params) {

      rb.query('query', params.query, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryConfigDto>;
      })
    );
  }

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `productsControllerDictionaryConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  productsControllerDictionaryConfig(params?: {
    query?: {  };

  }): Observable<DictionaryConfigDto> {

    return this.productsControllerDictionaryConfig$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryConfigDto>) => r.body as DictionaryConfigDto)
    );
  }

  /**
   * Path part for operation productsControllerDictionaryTable
   */
  static readonly ProductsControllerDictionaryTablePath = '/api/products/table';

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productsControllerDictionaryTable()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  productsControllerDictionaryTable$Response(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<StrictHttpResponse<DictionaryTableDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProductsService.ProductsControllerDictionaryTablePath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryTableDto>;
      })
    );
  }

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `productsControllerDictionaryTable$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  productsControllerDictionaryTable(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<DictionaryTableDto> {

    return this.productsControllerDictionaryTable$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryTableDto>) => r.body as DictionaryTableDto)
    );
  }

  /**
   * Path part for operation productsControllerDictionaryExcel
   */
  static readonly ProductsControllerDictionaryExcelPath = '/api/products/export';

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productsControllerDictionaryExcel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  productsControllerDictionaryExcel$Response(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<StrictHttpResponse<DictionaryExcelResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProductsService.ProductsControllerDictionaryExcelPath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryExcelResponseDto>;
      })
    );
  }

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `productsControllerDictionaryExcel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  productsControllerDictionaryExcel(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<DictionaryExcelResponseDto> {

    return this.productsControllerDictionaryExcel$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryExcelResponseDto>) => r.body as DictionaryExcelResponseDto)
    );
  }

  /**
   * Path part for operation productsControllerRefresh
   */
  static readonly ProductsControllerRefreshPath = '/api/products/refresh';

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productsControllerRefresh()` instead.
   *
   * This method doesn't expect any request body.
   */
  productsControllerRefresh$Response(params?: {

  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ProductsService.ProductsControllerRefreshPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `productsControllerRefresh$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  productsControllerRefresh(params?: {

  }): Observable<boolean> {

    return this.productsControllerRefresh$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
