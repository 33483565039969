import {NgModule} from '@angular/core';
import {environment} from '../../environments/environment';
import {NgxsLoggerPluginModule} from '@ngxs/logger-plugin';
import {NgxsModule} from '@ngxs/store';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {AuthState} from './auth/auth.state';
import {MessagesState} from './messages/messages.state';
import {TasksState} from './tasks/tasks.state';
import {CentrifugoState} from './centrifugo/centrifugo.state';
import {NgxsRouterPluginModule} from '@ngxs/router-plugin';
import {NgxPermissionsModule} from 'ngx-permissions';
import {LabelsState} from './labels/labels.state';


@NgModule({
  declarations: [],
  imports: [
    NgxPermissionsModule,
    NgxsModule.forRoot([
      AuthState,
      MessagesState,
      TasksState,
      CentrifugoState,
      LabelsState
    ], {
      developmentMode: !environment.production
    }),
    // NgxsLoggerPluginModule.forRoot({
    //   disabled: environment.production
    // }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production
    }),
    NgxsRouterPluginModule.forRoot()
  ]
})
export class StateModule {
}
