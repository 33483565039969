import {Component, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  template: `
    <mat-checkbox [(ngModel)]="value" (ngModelChange)="changed()" fxFlex>
      <ng-content></ng-content>
    </mat-checkbox>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CheckboxComponent),
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {

  value: boolean;

  constructor() {
  }

  /**
   * Function to call when the date changes.
   */
  onChange = (value: any) => {
  }

  /**
   * Function to call when the input is touched.
   */
  onTouched = () => {
  }

  changed() {
    this.onChange(this.value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: boolean): void {
    this.value = obj;
  }

}
