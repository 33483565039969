import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {Auth, AuthState} from '../auth/auth.state';
import * as Centrifuge from 'centrifuge';
import {Subscription} from 'centrifuge';
import {environment} from '../../../environments/environment';
import * as SockJS from 'sockjs-client';

export namespace Centrifugo {
  export class Connect {
    static readonly type = '[Centrifugo] Connect';
  }

  export class Disconnect {
    static readonly type = '[Centrifugo] Disconnect';
  }

  export class Connected {
    static readonly type = '[Centrifugo] Connected';
  }

  export class Disconnected {
    static readonly type = '[Centrifugo] Disconnected';
  }

  export class Error {
    static readonly type = '[Centrifugo] Error';

    constructor(public error: any) {
    }
  }

  export class Subscribe {
    static readonly type = '[Centrifugo] Subscribe';

    constructor(public channel: string) {
    }
  }

  export class Unsubscribe {
    static readonly type = '[Centrifugo] Unsubscribe';

    constructor(public channel: string) {
    }
  }

  export class Message {
    static readonly type = '[Centrifugo] Message';

    constructor(public channel: string, public message: any) {
    }
  }
}

export interface CentrifugoStateModel {
  connected: boolean;
  subscriptions: string[];
}

@State<CentrifugoStateModel>({
  name: 'centrifugo',
  defaults: {
    connected: false,
    subscriptions: []
  }
})
@Injectable()
export class CentrifugoState {
  static channels: { [channel: string]: Subscription } = {};
  private handler: any;

  @Selector()
  static connected(state: CentrifugoStateModel): boolean {
    return state.connected;
  }

  constructor(private store: Store) {
  }

  @Action(Auth.AuthSuccess)
  authSuccess(ctx: StateContext<CentrifugoStateModel>) {
    ctx.dispatch(new Centrifugo.Connect());
  }

  @Action(Centrifugo.Connect)
  connect(ctx: StateContext<CentrifugoStateModel>) {
    return; // TODO: After centrifugo
    const connected = ctx.getState().connected;

    if (connected) {
      return;
    }
    const token = this.store.selectSnapshot(AuthState.token);

    this.handler = new Centrifuge(`${environment.livehost}`, {
      debug: true,
      sockjs: SockJS,
      subscribeEndpoint: `${environment.apiURL}/centrifugo/subscribe`,
      refreshEndpoint: `${environment.apiURL}/centrifugo/refresh`,
      refreshAttempts: 3,
      onRefreshFailed: () => this.store.dispatch(new Auth.Logout()),
      subscribeHeaders: {
        authorization: `Bearer ${token}`,
      },
    });
    this.handler.setToken(token);
    this.handler
      .on('connect', (data) => {
        ctx.dispatch(new Centrifugo.Connected());
      })
      .on('disconnect', (data) => {
        ctx.dispatch(new Centrifugo.Disconnected());
      })
      .on('error', (error) => {
        ctx.dispatch(new Centrifugo.Error(error));
      });

    this.handler.connect();
  }

  @Action(Centrifugo.Disconnect)
  disconnect(ctx: StateContext<CentrifugoStateModel>) {
    if (this.handler) {
      this.handler.disconnect();
    }
  }

  @Action(Centrifugo.Connected)
  connected(ctx: StateContext<CentrifugoStateModel>) {
    ctx.patchState({
      connected: true
    });
  }

  @Action(Centrifugo.Disconnected)
  disconnected(ctx: StateContext<CentrifugoStateModel>) {
    ctx.patchState({
      connected: false,
      subscriptions: []
    });
  }

  @Action(Centrifugo.Subscribe)
  subscribe(ctx: StateContext<CentrifugoStateModel>, action: Centrifugo.Subscribe) {
    if (!action.channel) {
      throw new Error('CentrifugoService: No channel name was provided to new Subscribe(channel: string)');
    }

    const subscription = this.handler.subscribe(action.channel);

    // subscription.on('subscribe', (data) => {
    //   if (self.debug) {
    //     console.log('CentrifugoService: Subscribed to \'' + channel + '\' :', data);
    //   }
    // });
    subscription.on('error', (error) => {
      ctx.dispatch(new Error(error));
    });
    subscription.on('publish', (message) => {
      ctx.dispatch(new Centrifugo.Message(action.channel, message.data));
    });

    CentrifugoState.channels[action.channel] = subscription;
    const subscriptions = [...ctx.getState().subscriptions, action.channel];
    ctx.patchState({
      subscriptions
    });
  }

  @Action(Centrifugo.Unsubscribe)
  unsubscribe(ctx: StateContext<CentrifugoStateModel>, action: Centrifugo.Unsubscribe) {
    if (!action.channel) {
      throw new Error('CentrifugoService: No channel name was provided to new Unsubscribe(channel: string)');
    }
    const subscriptions = [...ctx.getState().subscriptions];


    if (subscriptions.indexOf(action.channel) != -1) {
      subscriptions.splice(subscriptions.indexOf(action.channel), 1);
      CentrifugoState.channels[action.channel].unsubscribe();
      delete CentrifugoState.channels[action.channel];
      ctx.patchState({
        subscriptions
      });
    }
  }

}
