/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SearchSelectItemDto } from '../models/search-select-item-dto';
import { TenderTermCardInfoDto } from '../models/tender-term-card-info-dto';
import { TenderTermCreateDto } from '../models/tender-term-create-dto';
import { TenderTermDto } from '../models/tender-term-dto';
import { TenderTermInfoDto } from '../models/tender-term-info-dto';
import { TenderTermUpdateDto } from '../models/tender-term-update-dto';
import { TenderTypeDto } from '../models/tender-type-dto';

@Injectable({
  providedIn: 'root',
})
export class TenderTermsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation tenderTermControllerInfo
   */
  static readonly TenderTermControllerInfoPath = '/api/tender-terms/info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderTermControllerInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTermControllerInfo$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<TenderTermInfoDto>> {

    const rb = new RequestBuilder(this.rootUrl, TenderTermsService.TenderTermControllerInfoPath, 'get');
    if (params) {

      rb.query('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenderTermInfoDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderTermControllerInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTermControllerInfo(params: {
    id: string;

  }): Observable<TenderTermInfoDto> {

    return this.tenderTermControllerInfo$Response(params).pipe(
      map((r: StrictHttpResponse<TenderTermInfoDto>) => r.body as TenderTermInfoDto)
    );
  }

  /**
   * Path part for operation tenderTermControllerSelect
   */
  static readonly TenderTermControllerSelectPath = '/api/tender-terms/select';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderTermControllerSelect()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTermControllerSelect$Response(params: {
    company: string;

  }): Observable<StrictHttpResponse<Array<SearchSelectItemDto>>> {

    const rb = new RequestBuilder(this.rootUrl, TenderTermsService.TenderTermControllerSelectPath, 'get');
    if (params) {

      rb.query('company', params.company, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SearchSelectItemDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderTermControllerSelect$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTermControllerSelect(params: {
    company: string;

  }): Observable<Array<SearchSelectItemDto>> {

    return this.tenderTermControllerSelect$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SearchSelectItemDto>>) => r.body as Array<SearchSelectItemDto>)
    );
  }

  /**
   * Path part for operation tenderTermControllerProductsSelect
   */
  static readonly TenderTermControllerProductsSelectPath = '/api/tender-terms/products-select';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderTermControllerProductsSelect()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTermControllerProductsSelect$Response(params: {
    term: string;

  }): Observable<StrictHttpResponse<Array<SearchSelectItemDto>>> {

    const rb = new RequestBuilder(this.rootUrl, TenderTermsService.TenderTermControllerProductsSelectPath, 'get');
    if (params) {

      rb.query('term', params.term, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SearchSelectItemDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderTermControllerProductsSelect$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTermControllerProductsSelect(params: {
    term: string;

  }): Observable<Array<SearchSelectItemDto>> {

    return this.tenderTermControllerProductsSelect$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SearchSelectItemDto>>) => r.body as Array<SearchSelectItemDto>)
    );
  }

  /**
   * Path part for operation tenderTermControllerCards
   */
  static readonly TenderTermControllerCardsPath = '/api/tender-terms/cards';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderTermControllerCards()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTermControllerCards$Response(params: {
    term: string;

  }): Observable<StrictHttpResponse<Array<TenderTermCardInfoDto>>> {

    const rb = new RequestBuilder(this.rootUrl, TenderTermsService.TenderTermControllerCardsPath, 'get');
    if (params) {

      rb.query('term', params.term, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TenderTermCardInfoDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderTermControllerCards$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTermControllerCards(params: {
    term: string;

  }): Observable<Array<TenderTermCardInfoDto>> {

    return this.tenderTermControllerCards$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TenderTermCardInfoDto>>) => r.body as Array<TenderTermCardInfoDto>)
    );
  }

  /**
   * Path part for operation tenderTermControllerApprove
   */
  static readonly TenderTermControllerApprovePath = '/api/tender-terms/{id}/approve';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderTermControllerApprove()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tenderTermControllerApprove$Response(params: {
    id: string;
      body: TenderTermUpdateDto
  }): Observable<StrictHttpResponse<TenderTypeDto>> {

    const rb = new RequestBuilder(this.rootUrl, TenderTermsService.TenderTermControllerApprovePath, 'put');
    if (params) {

      rb.path('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenderTypeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderTermControllerApprove$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tenderTermControllerApprove(params: {
    id: string;
      body: TenderTermUpdateDto
  }): Observable<TenderTypeDto> {

    return this.tenderTermControllerApprove$Response(params).pipe(
      map((r: StrictHttpResponse<TenderTypeDto>) => r.body as TenderTypeDto)
    );
  }

  /**
   * Path part for operation tenderTermControllerCreate
   */
  static readonly TenderTermControllerCreatePath = '/api/tender-terms/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderTermControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tenderTermControllerCreate$Response(params: {
      body: TenderTermCreateDto
  }): Observable<StrictHttpResponse<TenderTypeDto>> {

    const rb = new RequestBuilder(this.rootUrl, TenderTermsService.TenderTermControllerCreatePath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenderTypeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderTermControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tenderTermControllerCreate(params: {
      body: TenderTermCreateDto
  }): Observable<TenderTypeDto> {

    return this.tenderTermControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<TenderTypeDto>) => r.body as TenderTypeDto)
    );
  }

  /**
   * Path part for operation tenderTermControllerGet
   */
  static readonly TenderTermControllerGetPath = '/api/tender-terms/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderTermControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTermControllerGet$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<TenderTermDto>> {

    const rb = new RequestBuilder(this.rootUrl, TenderTermsService.TenderTermControllerGetPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenderTermDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderTermControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTermControllerGet(params: {
    id: string;

  }): Observable<TenderTermDto> {

    return this.tenderTermControllerGet$Response(params).pipe(
      map((r: StrictHttpResponse<TenderTermDto>) => r.body as TenderTermDto)
    );
  }

  /**
   * Path part for operation tenderTermControllerUpdate
   */
  static readonly TenderTermControllerUpdatePath = '/api/tender-terms/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderTermControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tenderTermControllerUpdate$Response(params: {
    id: string;
      body: TenderTermUpdateDto
  }): Observable<StrictHttpResponse<TenderTypeDto>> {

    const rb = new RequestBuilder(this.rootUrl, TenderTermsService.TenderTermControllerUpdatePath, 'put');
    if (params) {

      rb.path('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenderTypeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderTermControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tenderTermControllerUpdate(params: {
    id: string;
      body: TenderTermUpdateDto
  }): Observable<TenderTypeDto> {

    return this.tenderTermControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<TenderTypeDto>) => r.body as TenderTypeDto)
    );
  }

  /**
   * Path part for operation tenderTermControllerDelete
   */
  static readonly TenderTermControllerDeletePath = '/api/tender-terms/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderTermControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTermControllerDelete$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TenderTermsService.TenderTermControllerDeletePath, 'delete');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderTermControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTermControllerDelete(params: {
    id: string;

  }): Observable<void> {

    return this.tenderTermControllerDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation tenderTermControllerActivate
   */
  static readonly TenderTermControllerActivatePath = '/api/tender-terms/{id}/activate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderTermControllerActivate()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTermControllerActivate$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TenderTermsService.TenderTermControllerActivatePath, 'patch');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderTermControllerActivate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTermControllerActivate(params: {
    id: string;

  }): Observable<void> {

    return this.tenderTermControllerActivate$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation tenderTermControllerReactivate
   */
  static readonly TenderTermControllerReactivatePath = '/api/tender-terms/{id}/reactivate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderTermControllerReactivate()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTermControllerReactivate$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TenderTermsService.TenderTermControllerReactivatePath, 'post');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderTermControllerReactivate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTermControllerReactivate(params: {
    id: string;

  }): Observable<void> {

    return this.tenderTermControllerReactivate$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation tenderTermControllerBlock
   */
  static readonly TenderTermControllerBlockPath = '/api/tender-terms/{id}/block';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderTermControllerBlock()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTermControllerBlock$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TenderTermsService.TenderTermControllerBlockPath, 'post');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderTermControllerBlock$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTermControllerBlock(params: {
    id: string;

  }): Observable<void> {

    return this.tenderTermControllerBlock$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
