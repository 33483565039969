import {Component, forwardRef} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {SelectPrototype} from '../prototypes/select.prototype';

@Component({
  selector: 'app-morion-group-type-select',
  template: `
    <mat-form-field fxFlex>
      <mat-label translate>TYPE</mat-label>
      <app-search-select
        [formControl]="control"
        [options]="options">
      </app-search-select>
    </mat-form-field>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MorionGroupTypeSelectComponent),
    },
  ]
})
export class MorionGroupTypeSelectComponent extends SelectPrototype {

  options = [
    {id: 'Основная', label: 'Основная'},
    {id: 'Дополнительная', label: 'Дополнительная'}
  ];

}
