import {ChangeDetectorRef, Component, forwardRef, Input, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {SelectPrototype} from '../prototypes/select.prototype';
import {CompanyService} from '../../../../api/services/company.service';
import {takeUntil} from 'rxjs/operators';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

@Component({
  selector: 'app-company-select',
  template: `
    <mat-form-field fxFlex>
      <mat-label translate *ngIf="!multiple">COMPANY</mat-label>
      <mat-label translate *ngIf="multiple">COMPANIES</mat-label>
      <app-search-select
        [formControl]="control"
        [loading]="loading"
        [multiple]="multiple"
        [disabled]="disabled"
        [options]="options">
      </app-search-select>
    </mat-form-field>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CompanySelectComponent),
    },
  ]
})
export class CompanySelectComponent extends SelectPrototype implements OnInit {
  private _multiple: boolean;

  @Input()
  get multiple() {
    return this._multiple;
  }

  set multiple(req) {
    this._multiple = coerceBooleanProperty(req);
  }

  constructor(private companyService: CompanyService,
              private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.companyService.companyControllerSelect()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((options) => {
        this.options = options;
        if (this.options.length === 1) {
          this.control.setValue(this.options[0].id);
          this.disabled = true;
        }
        this.loading = false;
        this.cdr.detectChanges();
      });
  }

}
