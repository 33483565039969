/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateTenderTypeDto } from '../models/create-tender-type-dto';
import { DictionaryConfigDto } from '../models/dictionary-config-dto';
import { DictionaryExcelRequestDto } from '../models/dictionary-excel-request-dto';
import { DictionaryExcelResponseDto } from '../models/dictionary-excel-response-dto';
import { DictionaryRequestDto } from '../models/dictionary-request-dto';
import { DictionaryTableDto } from '../models/dictionary-table-dto';
import { SearchSelectItemDto } from '../models/search-select-item-dto';
import { TenderTypeDto } from '../models/tender-type-dto';
import { TenderTypeInfoDto } from '../models/tender-type-info-dto';

@Injectable({
  providedIn: 'root',
})
export class TenderTypesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation tenderTypeControllerInfo
   */
  static readonly TenderTypeControllerInfoPath = '/api/tender-types/info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderTypeControllerInfo()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  tenderTypeControllerInfo$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<TenderTypeInfoDto>> {

    const rb = new RequestBuilder(this.rootUrl, TenderTypesService.TenderTypeControllerInfoPath, 'get');
    if (params) {

      rb.query('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenderTypeInfoDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderTypeControllerInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  tenderTypeControllerInfo(params: {
    id: string;

  }): Observable<TenderTypeInfoDto> {

    return this.tenderTypeControllerInfo$Response(params).pipe(
      map((r: StrictHttpResponse<TenderTypeInfoDto>) => r.body as TenderTypeInfoDto)
    );
  }

  /**
   * Path part for operation tenderTypeControllerSelect
   */
  static readonly TenderTypeControllerSelectPath = '/api/tender-types/select';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderTypeControllerSelect()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  tenderTypeControllerSelect$Response(params?: {
    company?: string;

  }): Observable<StrictHttpResponse<Array<SearchSelectItemDto>>> {

    const rb = new RequestBuilder(this.rootUrl, TenderTypesService.TenderTypeControllerSelectPath, 'get');
    if (params) {

      rb.query('company', params.company, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SearchSelectItemDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderTypeControllerSelect$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  tenderTypeControllerSelect(params?: {
    company?: string;

  }): Observable<Array<SearchSelectItemDto>> {

    return this.tenderTypeControllerSelect$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SearchSelectItemDto>>) => r.body as Array<SearchSelectItemDto>)
    );
  }

  /**
   * Path part for operation tenderTypeControllerCreate
   */
  static readonly TenderTypeControllerCreatePath = '/api/tender-types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderTypeControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tenderTypeControllerCreate$Response(params: {
      body: CreateTenderTypeDto
  }): Observable<StrictHttpResponse<TenderTypeDto>> {

    const rb = new RequestBuilder(this.rootUrl, TenderTypesService.TenderTypeControllerCreatePath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenderTypeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderTypeControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tenderTypeControllerCreate(params: {
      body: CreateTenderTypeDto
  }): Observable<TenderTypeDto> {

    return this.tenderTypeControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<TenderTypeDto>) => r.body as TenderTypeDto)
    );
  }

  /**
   * Path part for operation tenderTypeControllerUpdate
   */
  static readonly TenderTypeControllerUpdatePath = '/api/tender-types/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderTypeControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tenderTypeControllerUpdate$Response(params: {

    /**
     * ObjectId записи
     */
    id: string;
      body: TenderTypeDto
  }): Observable<StrictHttpResponse<TenderTypeDto>> {

    const rb = new RequestBuilder(this.rootUrl, TenderTypesService.TenderTypeControllerUpdatePath, 'put');
    if (params) {

      rb.path('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenderTypeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderTypeControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tenderTypeControllerUpdate(params: {

    /**
     * ObjectId записи
     */
    id: string;
      body: TenderTypeDto
  }): Observable<TenderTypeDto> {

    return this.tenderTypeControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<TenderTypeDto>) => r.body as TenderTypeDto)
    );
  }

  /**
   * Path part for operation tenderTypeControllerActivate
   */
  static readonly TenderTypeControllerActivatePath = '/api/tender-types/{id}/activate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderTypeControllerActivate()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTypeControllerActivate$Response(params: {

    /**
     * ObjectId записи
     */
    id: string;

  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, TenderTypesService.TenderTypeControllerActivatePath, 'patch');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderTypeControllerActivate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTypeControllerActivate(params: {

    /**
     * ObjectId записи
     */
    id: string;

  }): Observable<boolean> {

    return this.tenderTypeControllerActivate$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation tenderTypeControllerBlock
   */
  static readonly TenderTypeControllerBlockPath = '/api/tender-types/{id}/block';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderTypeControllerBlock()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTypeControllerBlock$Response(params: {

    /**
     * ObjectId записи
     */
    id: string;

  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, TenderTypesService.TenderTypeControllerBlockPath, 'patch');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderTypeControllerBlock$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTypeControllerBlock(params: {

    /**
     * ObjectId записи
     */
    id: string;

  }): Observable<boolean> {

    return this.tenderTypeControllerBlock$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation tenderTypeControllerCount
   */
  static readonly TenderTypeControllerCountPath = '/api/tender-types/count';

  /**
   * Колличество записей.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderTypeControllerCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTypeControllerCount$Response(params?: {

    /**
     * Активные / не активные
     */
    active?: boolean;

    /**
     * Компания
     */
    company?: string;

  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, TenderTypesService.TenderTypeControllerCountPath, 'get');
    if (params) {

      rb.query('active', params.active, {});
      rb.query('company', params.company, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Колличество записей.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderTypeControllerCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTypeControllerCount(params?: {

    /**
     * Активные / не активные
     */
    active?: boolean;

    /**
     * Компания
     */
    company?: string;

  }): Observable<number> {

    return this.tenderTypeControllerCount$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation tenderTypeControllerFind
   */
  static readonly TenderTypeControllerFindPath = '/api/tender-types/find';

  /**
   * Выборка.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderTypeControllerFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTypeControllerFind$Response(params?: {

    /**
     * Выборка полей
     */
    select?: string;

    /**
     * Сортировка
     */
    sort?: string;

    /**
     * Пропустить N записей
     */
    skip?: number;

    /**
     * Выбрать N записей
     */
    limit?: number;

    /**
     * Активные / не активные
     */
    active?: boolean;

    /**
     * Компания
     */
    company?: string;

  }): Observable<StrictHttpResponse<Array<TenderTypeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, TenderTypesService.TenderTypeControllerFindPath, 'get');
    if (params) {

      rb.query('select', params.select, {});
      rb.query('sort', params.sort, {});
      rb.query('skip', params.skip, {});
      rb.query('limit', params.limit, {});
      rb.query('active', params.active, {});
      rb.query('company', params.company, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TenderTypeDto>>;
      })
    );
  }

  /**
   * Выборка.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderTypeControllerFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTypeControllerFind(params?: {

    /**
     * Выборка полей
     */
    select?: string;

    /**
     * Сортировка
     */
    sort?: string;

    /**
     * Пропустить N записей
     */
    skip?: number;

    /**
     * Выбрать N записей
     */
    limit?: number;

    /**
     * Активные / не активные
     */
    active?: boolean;

    /**
     * Компания
     */
    company?: string;

  }): Observable<Array<TenderTypeDto>> {

    return this.tenderTypeControllerFind$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TenderTypeDto>>) => r.body as Array<TenderTypeDto>)
    );
  }

  /**
   * Path part for operation tenderTypeControllerGet
   */
  static readonly TenderTypeControllerGetPath = '/api/tender-types/find/{id}';

  /**
   * Получение записи.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderTypeControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTypeControllerGet$Response(params: {

    /**
     * ObjectId записи
     */
    id: string;

  }): Observable<StrictHttpResponse<TenderTypeDto>> {

    const rb = new RequestBuilder(this.rootUrl, TenderTypesService.TenderTypeControllerGetPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenderTypeDto>;
      })
    );
  }

  /**
   * Получение записи.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderTypeControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTypeControllerGet(params: {

    /**
     * ObjectId записи
     */
    id: string;

  }): Observable<TenderTypeDto> {

    return this.tenderTypeControllerGet$Response(params).pipe(
      map((r: StrictHttpResponse<TenderTypeDto>) => r.body as TenderTypeDto)
    );
  }

  /**
   * Path part for operation tenderTypeControllerDictionaryConfig
   */
  static readonly TenderTypeControllerDictionaryConfigPath = '/api/tender-types/config';

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderTypeControllerDictionaryConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTypeControllerDictionaryConfig$Response(params?: {
    query?: {  };

  }): Observable<StrictHttpResponse<DictionaryConfigDto>> {

    const rb = new RequestBuilder(this.rootUrl, TenderTypesService.TenderTypeControllerDictionaryConfigPath, 'get');
    if (params) {

      rb.query('query', params.query, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryConfigDto>;
      })
    );
  }

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderTypeControllerDictionaryConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTypeControllerDictionaryConfig(params?: {
    query?: {  };

  }): Observable<DictionaryConfigDto> {

    return this.tenderTypeControllerDictionaryConfig$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryConfigDto>) => r.body as DictionaryConfigDto)
    );
  }

  /**
   * Path part for operation tenderTypeControllerDictionaryTable
   */
  static readonly TenderTypeControllerDictionaryTablePath = '/api/tender-types/table';

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderTypeControllerDictionaryTable()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tenderTypeControllerDictionaryTable$Response(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<StrictHttpResponse<DictionaryTableDto>> {

    const rb = new RequestBuilder(this.rootUrl, TenderTypesService.TenderTypeControllerDictionaryTablePath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryTableDto>;
      })
    );
  }

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderTypeControllerDictionaryTable$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tenderTypeControllerDictionaryTable(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<DictionaryTableDto> {

    return this.tenderTypeControllerDictionaryTable$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryTableDto>) => r.body as DictionaryTableDto)
    );
  }

  /**
   * Path part for operation tenderTypeControllerDictionaryExcel
   */
  static readonly TenderTypeControllerDictionaryExcelPath = '/api/tender-types/export';

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderTypeControllerDictionaryExcel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tenderTypeControllerDictionaryExcel$Response(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<StrictHttpResponse<DictionaryExcelResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, TenderTypesService.TenderTypeControllerDictionaryExcelPath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryExcelResponseDto>;
      })
    );
  }

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderTypeControllerDictionaryExcel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tenderTypeControllerDictionaryExcel(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<DictionaryExcelResponseDto> {

    return this.tenderTypeControllerDictionaryExcel$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryExcelResponseDto>) => r.body as DictionaryExcelResponseDto)
    );
  }

  /**
   * Path part for operation tenderTypeControllerRefresh
   */
  static readonly TenderTypeControllerRefreshPath = '/api/tender-types/refresh';

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderTypeControllerRefresh()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTypeControllerRefresh$Response(params?: {

  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, TenderTypesService.TenderTypeControllerRefreshPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderTypeControllerRefresh$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTypeControllerRefresh(params?: {

  }): Observable<boolean> {

    return this.tenderTypeControllerRefresh$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
