/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CardDto } from '../models/card-dto';
import { CreateCardDto } from '../models/create-card-dto';
import { DictionaryConfigDto } from '../models/dictionary-config-dto';
import { DictionaryExcelRequestDto } from '../models/dictionary-excel-request-dto';
import { DictionaryExcelResponseDto } from '../models/dictionary-excel-response-dto';
import { DictionaryRequestDto } from '../models/dictionary-request-dto';
import { DictionaryTableDto } from '../models/dictionary-table-dto';
import { TenderTypeDto } from '../models/tender-type-dto';

@Injectable({
  providedIn: 'root',
})
export class CardsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation cardsControllerCreate
   */
  static readonly CardsControllerCreatePath = '/api/cards';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cardsControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cardsControllerCreate$Response(params: {
      body: CreateCardDto
  }): Observable<StrictHttpResponse<TenderTypeDto>> {

    const rb = new RequestBuilder(this.rootUrl, CardsService.CardsControllerCreatePath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenderTypeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cardsControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cardsControllerCreate(params: {
      body: CreateCardDto
  }): Observable<TenderTypeDto> {

    return this.cardsControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<TenderTypeDto>) => r.body as TenderTypeDto)
    );
  }

  /**
   * Path part for operation cardsControllerUpdate
   */
  static readonly CardsControllerUpdatePath = '/api/cards/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cardsControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cardsControllerUpdate$Response(params: {

    /**
     * ObjectId записи
     */
    id: string;
      body: CardDto
  }): Observable<StrictHttpResponse<TenderTypeDto>> {

    const rb = new RequestBuilder(this.rootUrl, CardsService.CardsControllerUpdatePath, 'put');
    if (params) {

      rb.path('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenderTypeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cardsControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cardsControllerUpdate(params: {

    /**
     * ObjectId записи
     */
    id: string;
      body: CardDto
  }): Observable<TenderTypeDto> {

    return this.cardsControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<TenderTypeDto>) => r.body as TenderTypeDto)
    );
  }

  /**
   * Path part for operation cardsControllerActivate
   */
  static readonly CardsControllerActivatePath = '/api/cards/{id}/activate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cardsControllerActivate()` instead.
   *
   * This method doesn't expect any request body.
   */
  cardsControllerActivate$Response(params: {

    /**
     * ObjectId записи
     */
    id: string;

  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, CardsService.CardsControllerActivatePath, 'patch');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cardsControllerActivate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cardsControllerActivate(params: {

    /**
     * ObjectId записи
     */
    id: string;

  }): Observable<boolean> {

    return this.cardsControllerActivate$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation cardsControllerBlock
   */
  static readonly CardsControllerBlockPath = '/api/cards/{id}/block';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cardsControllerBlock()` instead.
   *
   * This method doesn't expect any request body.
   */
  cardsControllerBlock$Response(params: {

    /**
     * ObjectId записи
     */
    id: string;

  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, CardsService.CardsControllerBlockPath, 'patch');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cardsControllerBlock$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cardsControllerBlock(params: {

    /**
     * ObjectId записи
     */
    id: string;

  }): Observable<boolean> {

    return this.cardsControllerBlock$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation cardsControllerByOrganization
   */
  static readonly CardsControllerByOrganizationPath = '/api/cards/by-organization';

  /**
   * Карточки по организации.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cardsControllerByOrganization()` instead.
   *
   * This method doesn't expect any request body.
   */
  cardsControllerByOrganization$Response(params: {

    /**
     * Организация
     */
    organization: string;

    /**
     * Компания
     */
    company: string;

    /**
     * Заполнение контакта
     */
    populate?: boolean;

  }): Observable<StrictHttpResponse<Array<CardDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CardsService.CardsControllerByOrganizationPath, 'get');
    if (params) {

      rb.query('organization', params.organization, {});
      rb.query('company', params.company, {});
      rb.query('populate', params.populate, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CardDto>>;
      })
    );
  }

  /**
   * Карточки по организации.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cardsControllerByOrganization$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cardsControllerByOrganization(params: {

    /**
     * Организация
     */
    organization: string;

    /**
     * Компания
     */
    company: string;

    /**
     * Заполнение контакта
     */
    populate?: boolean;

  }): Observable<Array<CardDto>> {

    return this.cardsControllerByOrganization$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CardDto>>) => r.body as Array<CardDto>)
    );
  }

  /**
   * Path part for operation cardsControllerByTenderTerm
   */
  static readonly CardsControllerByTenderTermPath = '/api/cards/by-tender-term';

  /**
   * Карточки по условиям БЗ.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cardsControllerByTenderTerm()` instead.
   *
   * This method doesn't expect any request body.
   */
  cardsControllerByTenderTerm$Response(params: {

    /**
     * Условие БЗ
     */
    term: string;

    /**
     * Заполнение контакта
     */
    populate?: boolean;

  }): Observable<StrictHttpResponse<Array<CardDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CardsService.CardsControllerByTenderTermPath, 'get');
    if (params) {

      rb.query('term', params.term, {});
      rb.query('populate', params.populate, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CardDto>>;
      })
    );
  }

  /**
   * Карточки по условиям БЗ.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cardsControllerByTenderTerm$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cardsControllerByTenderTerm(params: {

    /**
     * Условие БЗ
     */
    term: string;

    /**
     * Заполнение контакта
     */
    populate?: boolean;

  }): Observable<Array<CardDto>> {

    return this.cardsControllerByTenderTerm$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CardDto>>) => r.body as Array<CardDto>)
    );
  }

  /**
   * Path part for operation cardsControllerCount
   */
  static readonly CardsControllerCountPath = '/api/cards/count';

  /**
   * Колличество записей.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cardsControllerCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  cardsControllerCount$Response(params?: {

    /**
     * Активные / не активные
     */
    active?: boolean;

    /**
     * Компания
     */
    company?: string;

  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, CardsService.CardsControllerCountPath, 'get');
    if (params) {

      rb.query('active', params.active, {});
      rb.query('company', params.company, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Колличество записей.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cardsControllerCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cardsControllerCount(params?: {

    /**
     * Активные / не активные
     */
    active?: boolean;

    /**
     * Компания
     */
    company?: string;

  }): Observable<number> {

    return this.cardsControllerCount$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation cardsControllerFind
   */
  static readonly CardsControllerFindPath = '/api/cards/find';

  /**
   * Выборка.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cardsControllerFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  cardsControllerFind$Response(params?: {

    /**
     * Выборка полей
     */
    select?: string;

    /**
     * Сортировка
     */
    sort?: string;

    /**
     * Пропустить N записей
     */
    skip?: number;

    /**
     * Выбрать N записей
     */
    limit?: number;

    /**
     * Активные / не активные
     */
    active?: boolean;

    /**
     * Компания
     */
    company?: string;

  }): Observable<StrictHttpResponse<Array<CardDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CardsService.CardsControllerFindPath, 'get');
    if (params) {

      rb.query('select', params.select, {});
      rb.query('sort', params.sort, {});
      rb.query('skip', params.skip, {});
      rb.query('limit', params.limit, {});
      rb.query('active', params.active, {});
      rb.query('company', params.company, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CardDto>>;
      })
    );
  }

  /**
   * Выборка.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cardsControllerFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cardsControllerFind(params?: {

    /**
     * Выборка полей
     */
    select?: string;

    /**
     * Сортировка
     */
    sort?: string;

    /**
     * Пропустить N записей
     */
    skip?: number;

    /**
     * Выбрать N записей
     */
    limit?: number;

    /**
     * Активные / не активные
     */
    active?: boolean;

    /**
     * Компания
     */
    company?: string;

  }): Observable<Array<CardDto>> {

    return this.cardsControllerFind$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CardDto>>) => r.body as Array<CardDto>)
    );
  }

  /**
   * Path part for operation cardsControllerGet
   */
  static readonly CardsControllerGetPath = '/api/cards/find/{id}';

  /**
   * Получение записи.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cardsControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  cardsControllerGet$Response(params: {

    /**
     * ObjectId записи
     */
    id: string;

  }): Observable<StrictHttpResponse<CardDto>> {

    const rb = new RequestBuilder(this.rootUrl, CardsService.CardsControllerGetPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CardDto>;
      })
    );
  }

  /**
   * Получение записи.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cardsControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cardsControllerGet(params: {

    /**
     * ObjectId записи
     */
    id: string;

  }): Observable<CardDto> {

    return this.cardsControllerGet$Response(params).pipe(
      map((r: StrictHttpResponse<CardDto>) => r.body as CardDto)
    );
  }

  /**
   * Path part for operation cardsControllerDictionaryConfig
   */
  static readonly CardsControllerDictionaryConfigPath = '/api/cards/config';

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cardsControllerDictionaryConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  cardsControllerDictionaryConfig$Response(params?: {
    query?: {  };

  }): Observable<StrictHttpResponse<DictionaryConfigDto>> {

    const rb = new RequestBuilder(this.rootUrl, CardsService.CardsControllerDictionaryConfigPath, 'get');
    if (params) {

      rb.query('query', params.query, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryConfigDto>;
      })
    );
  }

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cardsControllerDictionaryConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cardsControllerDictionaryConfig(params?: {
    query?: {  };

  }): Observable<DictionaryConfigDto> {

    return this.cardsControllerDictionaryConfig$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryConfigDto>) => r.body as DictionaryConfigDto)
    );
  }

  /**
   * Path part for operation cardsControllerDictionaryTable
   */
  static readonly CardsControllerDictionaryTablePath = '/api/cards/table';

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cardsControllerDictionaryTable()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cardsControllerDictionaryTable$Response(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<StrictHttpResponse<DictionaryTableDto>> {

    const rb = new RequestBuilder(this.rootUrl, CardsService.CardsControllerDictionaryTablePath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryTableDto>;
      })
    );
  }

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cardsControllerDictionaryTable$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cardsControllerDictionaryTable(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<DictionaryTableDto> {

    return this.cardsControllerDictionaryTable$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryTableDto>) => r.body as DictionaryTableDto)
    );
  }

  /**
   * Path part for operation cardsControllerDictionaryExcel
   */
  static readonly CardsControllerDictionaryExcelPath = '/api/cards/export';

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cardsControllerDictionaryExcel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cardsControllerDictionaryExcel$Response(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<StrictHttpResponse<DictionaryExcelResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, CardsService.CardsControllerDictionaryExcelPath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryExcelResponseDto>;
      })
    );
  }

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cardsControllerDictionaryExcel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cardsControllerDictionaryExcel(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<DictionaryExcelResponseDto> {

    return this.cardsControllerDictionaryExcel$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryExcelResponseDto>) => r.body as DictionaryExcelResponseDto)
    );
  }

  /**
   * Path part for operation cardsControllerRefresh
   */
  static readonly CardsControllerRefreshPath = '/api/cards/refresh';

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cardsControllerRefresh()` instead.
   *
   * This method doesn't expect any request body.
   */
  cardsControllerRefresh$Response(params?: {

  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, CardsService.CardsControllerRefreshPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cardsControllerRefresh$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cardsControllerRefresh(params?: {

  }): Observable<boolean> {

    return this.cardsControllerRefresh$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
