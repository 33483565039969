import {Routes} from '@angular/router';
import {AdminLayoutComponent} from './shared/components/layout-components/layouts/admin-layout/admin-layout.component';
import {AuthLayoutComponent} from './shared/components/layout-components/layouts/auth-layout/auth-layout.component';
import {AuthGuard} from './state/auth/auth.guard';

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then((m) => m.SessionsModule)
      },
    ],
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then((m) => m.DashboardModule),
        data: { breadcrumb: 'DASHBOARD'},
      },
      {
        path: 'inbox',
        loadChildren: () => import('./views/app-inbox/app-inbox.module').then((m) => m.AppInboxModule),
        data: { breadcrumb: 'MESSAGES'},
      },
      {
        path: 'tasks',
        loadChildren: () => import('./views/app-tasks/app-tasks.module').then((m) => m.AppTasksModule),
        data: { breadcrumb: 'TASKS'},
      },
      {
        path: 'profile',
        loadChildren: () => import('./views/profile/profile.module').then((m) => m.ProfileModule),
        data: { breadcrumb: 'PROFILE'},
      },
      {
        path: 'dictionaries',
        loadChildren: () => import('./views/dictionaries/dictionaries.module').then(m => m.DictionariesModule),
        data: { breadcrumb: 'DICTIONARIES'},
      },
      {
        path: 'nd',
        loadChildren: () => import('./views/nd/nd.module').then(m => m.NdModule),
        data: { breadcrumb: 'ND_REPORTS'},
      },
      {
        path: 'tenders',
        loadChildren: () => import('./views/tenders/tenders.module').then(m => m.TendersModule)
      },
      {
        path: 'sales',
        loadChildren: () => import('./views/sales/sales.module').then(m => m.SalesModule)
      },
      {
        path: 'loyalty',
        loadChildren: () => import('./views/loyalty/loyalty.module').then(m => m.LoyaltyModule),
        data: { breadcrumb: 'LOYALTY'},
      },
      {
        path: 'system',
        loadChildren: () => import('./views/system/system.module').then(m => m.SystemModule),
        data: {title: 'Система', breadcrumb: 'Система'},
      },
      {
        path: 'promotions',
        loadChildren: () => import('./views/pharmacy-promotions/pharmacy-promotions.module').then(m => m.PharmacyPromotionsModule),
        data: {title: 'Аптечные акции', breadcrumb: 'Аптечные акции'},
      },
    ],
  },
  /// INFO CARDS
  {
    path: 'card',
    loadChildren: () => import('./views/cards/cards.module').then(m => m.CardsModule),
    outlet: 'i',
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: 'sessions/404',
  },
];
