import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Store} from '@ngxs/store';
import {AuthState} from '../../state/auth/auth.state';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private store: Store) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const token = this.store.selectSnapshot(AuthState.token);

    let changedReq;

    if (token) {
      changedReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        },
      });
    } else {
      changedReq = req;
    }
    return next.handle(changedReq);
  }
}
