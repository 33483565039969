import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, FormArray, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {VipService} from '../../../../api/services/vip.service';
import {BehaviorSubject, combineLatest, Subject, zip} from 'rxjs';
import {filter, skipWhile, switchMap, takeUntil} from 'rxjs/operators';
import * as _ from 'lodash';
import {ProductsService} from '../../../../api/services/products.service';
import {SearchSelectItem} from '../../shared-components/search-select/interfaces/search-select-item';

@Component({
  selector: 'app-project-type-score-form',
  template: `
    <div fxLayout="column">
      <button mat-raised-button color="primary" (click)="addScore()">Добавить баллы</button>
      <mat-tab-group>
        <mat-tab *ngFor="let control of form.controls; let scoreIdx = index">
          <ng-template mat-tab-label>
            <div fxLayout="row" fxLayoutAlign="center center">
              <span fxFlex>{{(form.at(scoreIdx).get('start').value | date) || 'Новые баллы'}}</span>
              <button fxFlex="40px" mat-icon-button color="warn" (click)="form.removeAt(scoreIdx)">
                <mat-icon>remove_circle</mat-icon>
              </button>
            </div>
          </ng-template>
          <div fxLayout="row" fxLayoutAlign="center center">
            <app-month-picker [formControl]="form.at(scoreIdx).get('start')" fxFlex="40"></app-month-picker>
            <mat-checkbox fxFlex="30"
                          [checked]="isAllStdCoefficient(scoreIdx)"
                          [indeterminate]="isSomeStdCoefficient(scoreIdx)"
                          (change)="setAllStdCoefficient(scoreIdx, $event.checked)">
              Коэффициенты
            </mat-checkbox>
            <mat-checkbox fxFlex="30"
                          [checked]="isAllVipCoefficient(scoreIdx)"
                          [indeterminate]="isSomeVipCoefficient(scoreIdx)"
                          (change)="setAllVipCoefficient(scoreIdx, $event.checked)"
                          *ngIf="vipProductsPresent">
              VIP-коэффициенты
            </mat-checkbox>
          </div>
          <mat-divider class="my-8"></mat-divider>
          <div fxLayout="column">
            <ng-container *ngFor="let productRow of productsFormArray(scoreIdx).controls; let productIdx = index">
              <div fxFlex fxLayout="row" fxLayoutAlign="center center">
                <div fxFlex="40" fxFlexAlign="start center">{{getProductLabel(scoreIdx, productIdx)}}</div>
                <div fxFlex="30">
                  <mat-form-field>
                    <mat-label *ngIf="!!productsFormArray(scoreIdx).at(productIdx).get('isCoefficient').value">Коэффициент</mat-label>
                    <mat-label
                      *ngIf="!productsFormArray(scoreIdx).at(productIdx).get('isCoefficient').value">{{'SCORES' | translate}}</mat-label>
                    <input matInput type="number" [formControl]="productsFormArray(scoreIdx).at(productIdx).get('score')"/>
                  </mat-form-field>
                  <app-checkbox [formControl]="productsFormArray(scoreIdx).at(productIdx).get('isCoefficient')">Коэффициент</app-checkbox>
                </div>
                <div fxFlex="30">
                  <ng-container *ngIf="isVipProduct(scoreIdx, productIdx);">
                    <mat-form-field>
                      <mat-label *ngIf="!!productsFormArray(scoreIdx).at(productIdx).get('isVipCoefficient').value">VIP-коэффициент
                      </mat-label>
                      <mat-label
                        *ngIf="!productsFormArray(scoreIdx).at(productIdx).get('isVipCoefficient').value">{{'VIP_SCORES' | translate}}</mat-label>
                      <input matInput type="number" [formControl]="productsFormArray(scoreIdx).at(productIdx).get('vipScore')"/>
                    </mat-form-field>
                    <app-checkbox *ngIf="vipProductsPresent"
                                  [formControl]="productsFormArray(scoreIdx).at(productIdx).get('isVipCoefficient')">VIP-коэффициент
                    </app-checkbox>
                  </ng-container>
                </div>
              </div>
              <mat-divider class="my-8"></mat-divider>
            </ng-container>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  `,
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ProjectTypeScoreFormComponent),
    },
  ]
})
export class ProjectTypeScoreFormComponent implements OnInit, ControlValueAccessor, OnDestroy {

  form: FormArray = new FormArray([]);
  private destroyed$: Subject<void> = new Subject<void>();
  vipProductsPresent = false;
  productItems: SearchSelectItem[];

  company$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  products$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  vipProducts$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  value$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  generating = false;

  @Input()
  set company(value: string) {
    this.company$.next(value);
  }

  get company() {
    return this.company$.getValue();
  }

  @Input()
  set products(value: string[]) {
    this.products$.next(value);
  }

  get products() {
    return this.products$.getValue();
  }

  set vipProducts(value: string[]) {
    this.vipProducts$.next(value);
  }

  get vipProducts() {
    return this.vipProducts$.getValue();
  }

  productsFormArray(idx: number) {
    return this.form?.at(idx)?.get('products') as FormArray;
  }

  isAllStdCoefficient(scoreIdx: number) {
    const products = this.productsFormArray(scoreIdx)?.value;
    const stdLength = products?.filter(p => p.isCoefficient)?.length || 0;
    const productsLength = this?.products?.length || 0;
    return stdLength === productsLength;
  }

  isSomeStdCoefficient(scoreIdx: number) {
    const products = this.productsFormArray(scoreIdx)?.value;
    const stdLength = products?.filter(p => p.isCoefficient)?.length || 0;
    const productsLength = this?.products?.length || 0;
    return stdLength > 0 && stdLength < productsLength;
  }

  isAllVipCoefficient(scoreIdx: number) {
    const products = this.productsFormArray(scoreIdx)?.value;
    const vipLength = products?.filter(p => p.isVipCoefficient)?.length || 0;
    const productsLength = this?.products?.filter(p => this.vipProducts.includes(p))?.length || 0;
    return vipLength === productsLength;
  }

  isSomeVipCoefficient(scoreIdx: number) {
    const products = this.productsFormArray(scoreIdx)?.value;
    const vipLength = products?.filter(p => p.isVipCoefficient)?.length || 0;
    const productsLength = this?.products?.filter(p => this.vipProducts.includes(p))?.length || 0;
    return vipLength > 0 && vipLength < productsLength;
  }


  constructor(private vipService: VipService,
              private productsService: ProductsService) {
  }


  ngOnInit(): void {
    this.company$
      .pipe(
        takeUntil(this.destroyed$),
        filter(company => !!company),
        switchMap((company) => zip(
          this.productsService.productsControllerSelect({company}),
          this.vipService.vipControllerVipProducts({company})
        ))
      )
      .subscribe(([productItems, vipProductOIDs]) => {
        this.vipProducts = vipProductOIDs;
        this.productItems = productItems;
      });

    combineLatest([
      this.products$,
      this.vipProducts$
    ])
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(([products, vipProducts]) => {
        const vipIntersection = _.intersection(products || [], vipProducts || []);
        this.vipProductsPresent = vipIntersection.length > 0;
      });

    combineLatest([
      this.products$,
      this.vipProducts$,
      this.value$
    ])
      .pipe(
        takeUntil(this.destroyed$),
        filter(([products, vipProducts, value]) => !!products?.length && !!value?.length)
      )
      .subscribe(([products, vipProducts, value]) => {
        this.generating = true;
        while (this.form?.controls?.length) {
          this.form.removeAt(0);
        }
        value.forEach(() => this.addScore());
        this.generating = false;
        this.form.patchValue(value);
      });

    this.form.valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        skipWhile(() => this.generating)
      )
      .subscribe((val) => {
        this.onChange(val);
      });
  }

  addScore() {
    const group = new FormGroup({
      start: new FormControl(undefined, Validators.required),
      products: new FormArray(this.products.map(product => {
        const isVip = this.vipProducts.includes(product);
        if (isVip) {
          return new FormGroup({
            product: new FormControl(product),
            score: new FormControl(1),
            vipScore: new FormControl(1),
            isCoefficient: new FormControl(true),
            isVipCoefficient: new FormControl(true),
          });
        }

        return new FormGroup({
          product: new FormControl(product),
          score: new FormControl(1),
          vipScore: new FormControl(0),
          isCoefficient: new FormControl(true),
          isVipCoefficient: new FormControl(false),
        });
      }))
    });
    this.form.push(group);
  }

  onChange: any = () => {
  };
  onTouched: any = () => {
  };

  writeValue(obj: any): void {
    this.value$.next(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnDestroy(): void {
    if (this.destroyed$) {
      this.destroyed$.next();
      this.destroyed$.complete();
    }
  }

  getProductLabel(scoreIdx: number, productIdx: number): string {
    const productId = this.productsFormArray(scoreIdx)?.at(productIdx)?.get('product')?.value;
    if (!productId) {
      return 'Не установлено';
    }
    return this.productItems?.find(p => p.id === productId)?.label;
  }

  isVipProduct(scoreIdx: number, productIdx: number) {
    const productId = this.productsFormArray(scoreIdx).at(productIdx).get('product').value;
    if (!productId) {
      return false;
    }
    return this.vipProducts.includes(productId);
  }

  setAllStdCoefficient(scoreIdx: number, checked: boolean) {
    this.productsFormArray(scoreIdx).controls.forEach((control, index) => {
      this.productsFormArray(scoreIdx).at(index).get('isCoefficient').setValue(checked);
    });
  }

  setAllVipCoefficient(scoreIdx: number, checked: boolean) {
    this.productsFormArray(scoreIdx).controls.forEach((control, index) => {
      if (this.isVipProduct(scoreIdx, index)) {
        this.productsFormArray(scoreIdx).at(index).get('isVipCoefficient').setValue(checked);
      } else {
        this.productsFormArray(scoreIdx).at(index).get('isVipCoefficient').setValue(false);
      }
    });
  }
}
