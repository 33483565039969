import {Action, NgxsOnInit, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {AuthService} from '../../api/services/auth.service';
import {LoginDto} from '../../api/models/login-dto';
import {MeDto} from '../../api/models/me-dto';
import {Navigate} from '@ngxs/router-plugin';
import {NgxPermissionsService} from 'ngx-permissions';
import * as Sentry from '@sentry/angular';


export namespace Auth {
  export class Login {
    static readonly type = '[Auth] Login';

    constructor(public payload: LoginDto, public returnUrl?: string) {
    }
  }

  export class Logout {
    static readonly type = '[Auth] Logout';

    constructor(public returnUrl?: string) {
    }
  }

  export class RestoreToken {
    static readonly type = '[Auth] RestoreToken';
  }

  export class SaveToken {
    static readonly type = '[Auth] SaveToken';

    constructor(public token: string | null) {
    }
  }

  export class EnterGodmode {
    static readonly type = '[Auth] EnterGodmode';

    constructor(public token: string | null) {
    }
  }

  export class RestoreUser {
    static readonly type = '[Auth] RestoreUser';
  }

  export class AuthSuccess {
    static readonly type = '[Auth] AuthSuccess';
  }

  export class AuthFailed {
    static readonly type = '[Auth] AuthFailed';
  }
}

export interface AuthStateModel {
  token: string | null;
  godmode: boolean;
  username: string | null;
  user: MeDto | null;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    token: null,
    godmode: false,
    username: null,
    user: null
  }
})
@Injectable()
export class AuthState implements NgxsOnInit {

  JWT_TOKEN = 'JWT_TOKEN';
  GODMODE = 'JWT_GODMODE';

  @Selector()
  static token(state: AuthStateModel): string | null {
    return state.token;
  }

  @Selector()
  static godmode(state: AuthStateModel): boolean {
    return state.godmode;
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return !!state.token;
  }

  @Selector()
  static permissions(state: AuthStateModel): string[] {
    return state?.user?.permissions || [];
  }

  @Selector()
  static user(state: AuthStateModel): MeDto | null {
    return state.user;
  }

  constructor(private authService: AuthService,
              private permissionsService: NgxPermissionsService) {
  }

  ngxsOnInit(ctx?: StateContext<any>) {
    ctx.dispatch(new Auth.RestoreToken());
  }

  @Action(Auth.Login)
  async login(ctx: StateContext<AuthStateModel>, action: Auth.Login) {
    try {
      const response = await this.authService
        .authControllerLogin({body: action.payload})
        .toPromise();

      ctx.patchState({
        username: action.payload.username
      });

      await ctx.dispatch(new Auth.SaveToken(response.access_token)).toPromise();
      await ctx.dispatch(new Auth.RestoreUser()).toPromise();
      await ctx.dispatch(new Navigate([action.returnUrl || '/'])).toPromise();
    } catch (e) {
      ctx.dispatch(new Auth.AuthFailed());
    }
  }

  @Action(Auth.Logout)
  async logout(ctx: StateContext<AuthStateModel>, action: Auth.Logout) {

    if (ctx.getState().godmode) {
      localStorage.removeItem(this.GODMODE);
      const token = JSON.parse(localStorage.getItem(this.JWT_TOKEN));

      ctx.patchState({
        token,
        godmode: false
      });
      window.location.reload();
    } else {
      ctx.setState({
        token: null,
        godmode: false,
        username: null,
        user: null
      });
      await ctx.dispatch(new Auth.SaveToken(null))
        .toPromise();
      await ctx.dispatch(new Navigate(['/sessions/signin'], {return: action.returnUrl || '/'}))
        .toPromise();
    }
  }

  @Action(Auth.RestoreToken)
  restoreToken(ctx: StateContext<AuthStateModel>) {
    const token = JSON.parse(localStorage.getItem(this.JWT_TOKEN));
    const godmode = JSON.parse(localStorage.getItem(this.GODMODE));

    if (godmode) {
      ctx.patchState({
        token: godmode,
        godmode: true
      });
      ctx.dispatch(new Auth.RestoreUser());
    } else if (token) {
      ctx.patchState({
        token
      });
      ctx.dispatch(new Auth.RestoreUser());
    }
  }

  @Action(Auth.SaveToken)
  saveToken(ctx: StateContext<AuthStateModel>, action: Auth.SaveToken) {
    localStorage.setItem(this.JWT_TOKEN, JSON.stringify(action.token));
    ctx.patchState({
      token: action.token
    });
  }

  @Action(Auth.EnterGodmode)
  enterGodmode(ctx: StateContext<AuthStateModel>, action: Auth.SaveToken) {
    localStorage.setItem(this.GODMODE, JSON.stringify(action.token));
    window.location.reload();
  }

  @Action(Auth.RestoreUser)
  async restoreUser(ctx: StateContext<AuthStateModel>) {
    const user = await this.authService.authControllerMe().toPromise();
    ctx.patchState({user});
    Sentry.setUser({id: user.id, username: user.displayName, ip_address: '{{auto}}'});
    const permissions = [
      'BasicAccess',
      ...user.permissions
    ];
    this.permissionsService.loadPermissions(permissions);
    if (user) {
      ctx.dispatch(new Auth.AuthSuccess());
    } else {
      ctx.dispatch(new Auth.AuthFailed());
    }
  }
}
