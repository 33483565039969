import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-app-loader',
  template: `
    <div class="text-center">
      <h6 class="m-0 pb-16" style="white-space: pre-wrap; line-height: 1.4;">{{ title }}</h6>
      <div mat-dialog-content>
        <mat-spinner [style.margin]="'auto'"></mat-spinner>
      </div>
    </div>`,
  styles: [`
    .mat-dialog-content {
      min-height: 122px;
    }`]
})
export class AppLoaderComponent implements OnInit {
  title;
  message;

  constructor(public dialogRef: MatDialogRef<AppLoaderComponent>) {
  }

  ngOnInit() {
  }

}
