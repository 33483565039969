import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {AppLoaderComponent} from './loader/app-loader.component';
import {AppComfirmComponent} from './confirm/app-confirm.component';
import {AppSuccessComponent} from './success/app-success.component';
import {AppFailedComponent} from './failed/app-failed.component';

interface Config {
  width?: string;
}

interface ConfirmData {
  title?: string;
  message?: string;
}

@Injectable()
export class DialogsService {
  loaderDialogRef: MatDialogRef<AppLoaderComponent>;

  constructor(private dialog: MatDialog) {
  }

  public openLoader(title: string = 'Загрузка', config: Config = {width: '200px'}): Observable<boolean> {
    this.loaderDialogRef = this.dialog.open(AppLoaderComponent, {disableClose: true, backdropClass: 'light-backdrop'});
    this.loaderDialogRef.updateSize(config.width);
    this.loaderDialogRef.componentInstance.title = title;
    return this.loaderDialogRef.afterClosed();
  }

  public closeLoader() {
    if (this.loaderDialogRef) {
      this.loaderDialogRef.close();
    }
  }

  public confirm(data: ConfirmData = {}): Observable<boolean> {
    data.title = data.title || 'Confirm';
    data.message = data.message || 'Are you sure?';
    let dialogRef: MatDialogRef<AppComfirmComponent>;
    dialogRef = this.dialog.open(AppComfirmComponent, {
      minWidth: '380px',
      disableClose: true,
      data: {title: data.title, message: data.message}
    });
    return dialogRef.afterClosed();
  }

  public success(message: string = 'Успех'): Observable<boolean> {
    let dialogRef: MatDialogRef<AppSuccessComponent>;
    dialogRef = this.dialog.open(AppSuccessComponent, {
      width: '380px',
      disableClose: true,
      data: {message}
    });
    setTimeout(() => {
      dialogRef.close();
    }, 2000);
    return dialogRef.afterClosed();
  }

  public error(message = 'Ошибка', text = false): Observable<boolean> {

    if (!text) {
      try {
        const msg: any = message;
        if (typeof msg == 'object' && msg.hasOwnProperty('message')) {
          message = msg.message;
        } else {
          const errorObject = JSON.parse(message);
          if (typeof errorObject === 'object' && errorObject.hasOwnProperty('message')) {
            message = errorObject.message;
          }
        }
      } catch (e) {
        message = 'Ошибка';
      }
    }

    let dialogRef: MatDialogRef<AppFailedComponent>;
    dialogRef = this.dialog.open(AppFailedComponent, {
      width: '380px',
      disableClose: true,
      data: {message}
    });
    setTimeout(() => {
      dialogRef.close();
    }, 5000);
    return dialogRef.afterClosed();
  }

}
