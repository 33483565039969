import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {Router, RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
// import { GestureConfig } from '@angular/material/core';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {rootRouterConfig} from './app.routing';
import {SharedModule} from './shared/shared.module';
import {AppComponent} from './app.component';

import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {ErrorHandlerService} from './shared/services/error-handler.service';
import {TokenInterceptor} from './shared/interceptors/token.interceptor';
import {TranslationLoaderService} from './shared/services/translation-loader/translation-loader.service';
import {ApiModule} from './api/api.module';
import {environment} from '../environments/environment';
import {StateModule} from './state/state.module';
import {AccessDeniedInterceptor} from './shared/interceptors/access-denied.interceptor';
import '@angular/common/locales/global/ru-UA';
import {NgxPermissionsModule} from 'ngx-permissions';
import {
  ClientSideRowModelModule,
  ClipboardModule,
  ColumnsToolPanelModule,
  ExcelExportModule,
  FiltersToolPanelModule,
  LicenseManager,
  MenuModule,
  ModuleRegistry,
  RangeSelectionModule,
  RowGroupingModule,
  ServerSideRowModelModule,
  SideBarModule,
  StatusBarModule,
} from '@ag-grid-enterprise/all-modules';
import {ServiceWorkerModule} from '@angular/service-worker';
import * as Sentry from '@sentry/angular';
import {ReactiveFormsModule} from '@angular/forms';
import {FormlyModule} from '@ngx-formly/core';
import {FormlyMaterialModule} from '@ngx-formly/material';
import * as moment from 'moment-timezone';

moment.tz.setDefault('Europe/Kiev');

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslationLoaderService(http);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

LicenseManager.setLicenseKey('MDT_NTU2NzY4OTc5NDA1NQ==[v2]2bffef3304b89dc0c513ede2d0e282fa');
ModuleRegistry.registerModules([
  ClipboardModule,
  ColumnsToolPanelModule,
  ExcelExportModule,
  FiltersToolPanelModule,
  MenuModule,
  RangeSelectionModule,
  RowGroupingModule,
  SideBarModule,
  StatusBarModule,
  ServerSideRowModelModule,
  ClientSideRowModelModule
]);

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    PerfectScrollbarModule,
    NgxPermissionsModule.forRoot(),
    ApiModule.forRoot({
      rootUrl: environment.apiURL
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule.forRoot(rootRouterConfig, {useHash: false, relativeLinkResolution: 'legacy'}),
    StateModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    ReactiveFormsModule,
    FormlyModule.forRoot({extras: {lazyRender: true}}),
    FormlyMaterialModule
  ],
  exports: [ApiModule, NgxPermissionsModule],
  declarations: [AppComponent],
  providers: [
    {provide: ErrorHandler, useClass: ErrorHandlerService},
    // { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
    // REQUIRED IF YOU USE JWT AUTHENTICATION
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccessDeniedInterceptor,
      multi: true,
    },
    {provide: LOCALE_ID, useValue: 'ru-UA'},
    {provide: ErrorHandler, useValue: Sentry.createErrorHandler({showDialog: false})},
    {provide: Sentry.TraceService, deps: [Router]},
    {
      provide: APP_INITIALIZER, useFactory: () => () => {
      }, deps: [Sentry.TraceService], multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
