import {ControlValueAccessor} from '@angular/forms';
import {BehaviorSubject, Subject} from 'rxjs';
import {Injectable, OnDestroy} from '@angular/core';

@Injectable()
export class FormPrototype<T = any> implements ControlValueAccessor, OnDestroy {

  /**
   * Утанавливается когда есть значение
   */
  hasValue$: Subject<boolean> = new Subject<boolean>();

  /**
   * Утанавливается когда форма уничтожается
   */
  destroyed$: Subject<void> = new Subject<void>();

  value$: BehaviorSubject<T> = new BehaviorSubject<T>(undefined);

  /**
   * Function to call when the date changes.
   * @param {any} value
   */
  onChange(value: any) {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  writeValue(obj: any): void {
    this.hasValue$.next(true);
    this.value$.next(obj);
  }

  ngOnDestroy(): void {
    if (this.destroyed$) {
      this.destroyed$.next();
      this.destroyed$.complete();
    }

    if (this.hasValue$) {
      this.hasValue$.complete();
    }
  }

  getValue(): T {
    return this.value$.value;
  }

  registerOnTouched(fn: any): void {
  }
}
