import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Host, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MatCalendar} from '@angular/material/datepicker';
import {DateAdapter} from '@angular/material/core';

@Component({
  template: `
    <div class="custom-calendar-header">
      <button mat-icon-button class="custom-calendar-header-double-arrow" (click)="previousClicked()">
        <mat-icon>keyboard_arrow_left</mat-icon>
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <span class="custom-calendar-header-label" (click)="goUp()">{{periodLabel}}</span>
      <button mat-icon-button class="custom-calendar-header-double-arrow" (click)="nextClicked()">
        <mat-icon>keyboard_arrow_right</mat-icon>
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>`,
  styleUrls: ['month-picker-header.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonthPickerHeaderComponent<D> implements OnDestroy {
  private _destroyed = new Subject<void>();

  constructor(private _calendar: MatCalendar<D>,
              private _dateAdapter: DateAdapter<D>,
              cdr: ChangeDetectorRef) {
    _calendar.stateChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => cdr.markForCheck());
  }

  get periodLabel() {
    return this._dateAdapter
      .format(this._calendar.activeDate, 'YYYY')
      .toLocaleUpperCase();
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  goUp() {
    if (this._calendar.currentView === 'year') {
      this._calendar.currentView = 'multi-year';
    }
  }

  previousClicked() {
    this._calendar.activeDate = this._calendar.currentView === 'month' ?
      this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1) :
      this._dateAdapter.addCalendarYears(
        this._calendar.activeDate,
        this._calendar.currentView === 'year' ? -1 : -24
      );
  }

  nextClicked() {
    this._calendar.activeDate = this._calendar.currentView === 'month' ?
      this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1) :
      this._dateAdapter.addCalendarYears(
        this._calendar.activeDate,
        this._calendar.currentView === 'year' ? 1 : 24
      );
  }
}
