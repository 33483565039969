/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MatrixDataDto } from '../models/matrix-data-dto';
import { MatrixRowDto } from '../models/matrix-row-dto';
import { MpMatrixRowDto } from '../models/mp-matrix-row-dto';
import { PlanDistributionDataDto } from '../models/plan-distribution-data-dto';
import { PlanDistributionPercentsDataDto } from '../models/plan-distribution-percents-data-dto';
import { PlanExceptionsMatrixParamsDto } from '../models/plan-exceptions-matrix-params-dto';

@Injectable({
  providedIn: 'root',
})
export class PlanDistributionService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation planDistributionControllerMpMatrix
   */
  static readonly PlanDistributionControllerMpMatrixPath = '/api/plan-distribution/mp-matrix';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `planDistributionControllerMpMatrix()` instead.
   *
   * This method doesn't expect any request body.
   */
  planDistributionControllerMpMatrix$Response(params: {
    company: string;
    year: number;
    month: number;

  }): Observable<StrictHttpResponse<Array<MpMatrixRowDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlanDistributionService.PlanDistributionControllerMpMatrixPath, 'get');
    if (params) {

      rb.query('company', params.company, {});
      rb.query('year', params.year, {});
      rb.query('month', params.month, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MpMatrixRowDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `planDistributionControllerMpMatrix$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  planDistributionControllerMpMatrix(params: {
    company: string;
    year: number;
    month: number;

  }): Observable<Array<MpMatrixRowDto>> {

    return this.planDistributionControllerMpMatrix$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MpMatrixRowDto>>) => r.body as Array<MpMatrixRowDto>)
    );
  }

  /**
   * Path part for operation planDistributionControllerMpDivisionsMatrix
   */
  static readonly PlanDistributionControllerMpDivisionsMatrixPath = '/api/plan-distribution/mp-division-matrix';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `planDistributionControllerMpDivisionsMatrix()` instead.
   *
   * This method doesn't expect any request body.
   */
  planDistributionControllerMpDivisionsMatrix$Response(params: {
    company: string;
    year: number;
    month: number;

  }): Observable<StrictHttpResponse<Array<MatrixDataDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlanDistributionService.PlanDistributionControllerMpDivisionsMatrixPath, 'get');
    if (params) {

      rb.query('company', params.company, {});
      rb.query('year', params.year, {});
      rb.query('month', params.month, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MatrixDataDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `planDistributionControllerMpDivisionsMatrix$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  planDistributionControllerMpDivisionsMatrix(params: {
    company: string;
    year: number;
    month: number;

  }): Observable<Array<MatrixDataDto>> {

    return this.planDistributionControllerMpDivisionsMatrix$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MatrixDataDto>>) => r.body as Array<MatrixDataDto>)
    );
  }

  /**
   * Path part for operation planDistributionControllerProductPlanMatrix
   */
  static readonly PlanDistributionControllerProductPlanMatrixPath = '/api/plan-distribution/product-plan-matrix';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `planDistributionControllerProductPlanMatrix()` instead.
   *
   * This method doesn't expect any request body.
   */
  planDistributionControllerProductPlanMatrix$Response(params: {
    company: string;
    year: number;

  }): Observable<StrictHttpResponse<Array<MatrixDataDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlanDistributionService.PlanDistributionControllerProductPlanMatrixPath, 'get');
    if (params) {

      rb.query('company', params.company, {});
      rb.query('year', params.year, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MatrixDataDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `planDistributionControllerProductPlanMatrix$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  planDistributionControllerProductPlanMatrix(params: {
    company: string;
    year: number;

  }): Observable<Array<MatrixDataDto>> {

    return this.planDistributionControllerProductPlanMatrix$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MatrixDataDto>>) => r.body as Array<MatrixDataDto>)
    );
  }

  /**
   * Path part for operation planDistributionControllerAmMatrixRows
   */
  static readonly PlanDistributionControllerAmMatrixRowsPath = '/api/plan-distribution/am-matrix-rows';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `planDistributionControllerAmMatrixRows()` instead.
   *
   * This method doesn't expect any request body.
   */
  planDistributionControllerAmMatrixRows$Response(params: {
    year: number;
    month: number;
    company: string;

  }): Observable<StrictHttpResponse<Array<MatrixRowDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlanDistributionService.PlanDistributionControllerAmMatrixRowsPath, 'get');
    if (params) {

      rb.query('year', params.year, {});
      rb.query('month', params.month, {});
      rb.query('company', params.company, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MatrixRowDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `planDistributionControllerAmMatrixRows$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  planDistributionControllerAmMatrixRows(params: {
    year: number;
    month: number;
    company: string;

  }): Observable<Array<MatrixRowDto>> {

    return this.planDistributionControllerAmMatrixRows$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MatrixRowDto>>) => r.body as Array<MatrixRowDto>)
    );
  }

  /**
   * Path part for operation planDistributionControllerPercentPlanMatrix
   */
  static readonly PlanDistributionControllerPercentPlanMatrixPath = '/api/plan-distribution/percent-plan-matrix';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `planDistributionControllerPercentPlanMatrix()` instead.
   *
   * This method doesn't expect any request body.
   */
  planDistributionControllerPercentPlanMatrix$Response(params: {
    company: string;
    year: number;

  }): Observable<StrictHttpResponse<Array<MatrixDataDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlanDistributionService.PlanDistributionControllerPercentPlanMatrixPath, 'get');
    if (params) {

      rb.query('company', params.company, {});
      rb.query('year', params.year, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MatrixDataDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `planDistributionControllerPercentPlanMatrix$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  planDistributionControllerPercentPlanMatrix(params: {
    company: string;
    year: number;

  }): Observable<Array<MatrixDataDto>> {

    return this.planDistributionControllerPercentPlanMatrix$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MatrixDataDto>>) => r.body as Array<MatrixDataDto>)
    );
  }

  /**
   * Path part for operation planDistributionControllerPercentPlanGroupMatrix
   */
  static readonly PlanDistributionControllerPercentPlanGroupMatrixPath = '/api/plan-distribution/percent-plan-group-matrix';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `planDistributionControllerPercentPlanGroupMatrix()` instead.
   *
   * This method doesn't expect any request body.
   */
  planDistributionControllerPercentPlanGroupMatrix$Response(params: {
    company: string;
    year: number;
    month: number;

  }): Observable<StrictHttpResponse<Array<MatrixDataDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlanDistributionService.PlanDistributionControllerPercentPlanGroupMatrixPath, 'get');
    if (params) {

      rb.query('company', params.company, {});
      rb.query('year', params.year, {});
      rb.query('month', params.month, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MatrixDataDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `planDistributionControllerPercentPlanGroupMatrix$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  planDistributionControllerPercentPlanGroupMatrix(params: {
    company: string;
    year: number;
    month: number;

  }): Observable<Array<MatrixDataDto>> {

    return this.planDistributionControllerPercentPlanGroupMatrix$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MatrixDataDto>>) => r.body as Array<MatrixDataDto>)
    );
  }

  /**
   * Path part for operation planDistributionControllerKamPlanMatrixRows
   */
  static readonly PlanDistributionControllerKamPlanMatrixRowsPath = '/api/plan-distribution/kam-plan-matrix-rows';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `planDistributionControllerKamPlanMatrixRows()` instead.
   *
   * This method doesn't expect any request body.
   */
  planDistributionControllerKamPlanMatrixRows$Response(params: {
    company: string;
    year: number;
    month: number;

  }): Observable<StrictHttpResponse<Array<MatrixRowDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlanDistributionService.PlanDistributionControllerKamPlanMatrixRowsPath, 'get');
    if (params) {

      rb.query('company', params.company, {});
      rb.query('year', params.year, {});
      rb.query('month', params.month, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MatrixRowDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `planDistributionControllerKamPlanMatrixRows$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  planDistributionControllerKamPlanMatrixRows(params: {
    company: string;
    year: number;
    month: number;

  }): Observable<Array<MatrixRowDto>> {

    return this.planDistributionControllerKamPlanMatrixRows$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MatrixRowDto>>) => r.body as Array<MatrixRowDto>)
    );
  }

  /**
   * Path part for operation planDistributionControllerKamPlanMatrix
   */
  static readonly PlanDistributionControllerKamPlanMatrixPath = '/api/plan-distribution/kam-plan-matrix';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `planDistributionControllerKamPlanMatrix()` instead.
   *
   * This method doesn't expect any request body.
   */
  planDistributionControllerKamPlanMatrix$Response(params: {
    company: string;
    year: number;
    month: number;

  }): Observable<StrictHttpResponse<Array<MatrixDataDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlanDistributionService.PlanDistributionControllerKamPlanMatrixPath, 'get');
    if (params) {

      rb.query('company', params.company, {});
      rb.query('year', params.year, {});
      rb.query('month', params.month, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MatrixDataDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `planDistributionControllerKamPlanMatrix$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  planDistributionControllerKamPlanMatrix(params: {
    company: string;
    year: number;
    month: number;

  }): Observable<Array<MatrixDataDto>> {

    return this.planDistributionControllerKamPlanMatrix$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MatrixDataDto>>) => r.body as Array<MatrixDataDto>)
    );
  }

  /**
   * Path part for operation planDistributionControllerFormData
   */
  static readonly PlanDistributionControllerFormDataPath = '/api/plan-distribution/form-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `planDistributionControllerFormData()` instead.
   *
   * This method doesn't expect any request body.
   */
  planDistributionControllerFormData$Response(params: {
    am: string;
    year: number;
    month: number;

  }): Observable<StrictHttpResponse<PlanDistributionDataDto>> {

    const rb = new RequestBuilder(this.rootUrl, PlanDistributionService.PlanDistributionControllerFormDataPath, 'get');
    if (params) {

      rb.query('am', params.am, {});
      rb.query('year', params.year, {});
      rb.query('month', params.month, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlanDistributionDataDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `planDistributionControllerFormData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  planDistributionControllerFormData(params: {
    am: string;
    year: number;
    month: number;

  }): Observable<PlanDistributionDataDto> {

    return this.planDistributionControllerFormData$Response(params).pipe(
      map((r: StrictHttpResponse<PlanDistributionDataDto>) => r.body as PlanDistributionDataDto)
    );
  }

  /**
   * Path part for operation planDistributionControllerDivisionProductMatrix
   */
  static readonly PlanDistributionControllerDivisionProductMatrixPath = '/api/plan-distribution/division-product-matrix';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `planDistributionControllerDivisionProductMatrix()` instead.
   *
   * This method doesn't expect any request body.
   */
  planDistributionControllerDivisionProductMatrix$Response(params: {
    company: string;
    year: number;
    month: number;

  }): Observable<StrictHttpResponse<Array<MatrixDataDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlanDistributionService.PlanDistributionControllerDivisionProductMatrixPath, 'get');
    if (params) {

      rb.query('company', params.company, {});
      rb.query('year', params.year, {});
      rb.query('month', params.month, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MatrixDataDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `planDistributionControllerDivisionProductMatrix$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  planDistributionControllerDivisionProductMatrix(params: {
    company: string;
    year: number;
    month: number;

  }): Observable<Array<MatrixDataDto>> {

    return this.planDistributionControllerDivisionProductMatrix$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MatrixDataDto>>) => r.body as Array<MatrixDataDto>)
    );
  }

  /**
   * Path part for operation planDistributionControllerPercentFormData
   */
  static readonly PlanDistributionControllerPercentFormDataPath = '/api/plan-distribution/percent-form-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `planDistributionControllerPercentFormData()` instead.
   *
   * This method doesn't expect any request body.
   */
  planDistributionControllerPercentFormData$Response(params: {
    am: string;
    year: number;
    month: number;

  }): Observable<StrictHttpResponse<PlanDistributionPercentsDataDto>> {

    const rb = new RequestBuilder(this.rootUrl, PlanDistributionService.PlanDistributionControllerPercentFormDataPath, 'get');
    if (params) {

      rb.query('am', params.am, {});
      rb.query('year', params.year, {});
      rb.query('month', params.month, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlanDistributionPercentsDataDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `planDistributionControllerPercentFormData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  planDistributionControllerPercentFormData(params: {
    am: string;
    year: number;
    month: number;

  }): Observable<PlanDistributionPercentsDataDto> {

    return this.planDistributionControllerPercentFormData$Response(params).pipe(
      map((r: StrictHttpResponse<PlanDistributionPercentsDataDto>) => r.body as PlanDistributionPercentsDataDto)
    );
  }

  /**
   * Path part for operation planDistributionControllerPlanExceptionsMatrix
   */
  static readonly PlanDistributionControllerPlanExceptionsMatrixPath = '/api/plan-distribution/plan-exceptions-matrix';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `planDistributionControllerPlanExceptionsMatrix()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  planDistributionControllerPlanExceptionsMatrix$Response(params: {
      body: PlanExceptionsMatrixParamsDto
  }): Observable<StrictHttpResponse<Array<MatrixDataDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlanDistributionService.PlanDistributionControllerPlanExceptionsMatrixPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MatrixDataDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `planDistributionControllerPlanExceptionsMatrix$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  planDistributionControllerPlanExceptionsMatrix(params: {
      body: PlanExceptionsMatrixParamsDto
  }): Observable<Array<MatrixDataDto>> {

    return this.planDistributionControllerPlanExceptionsMatrix$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MatrixDataDto>>) => r.body as Array<MatrixDataDto>)
    );
  }

  /**
   * Path part for operation planDistributionControllerPlanExceptionsProducts
   */
  static readonly PlanDistributionControllerPlanExceptionsProductsPath = '/api/plan-distribution/plan-exceptions-products';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `planDistributionControllerPlanExceptionsProducts()` instead.
   *
   * This method doesn't expect any request body.
   */
  planDistributionControllerPlanExceptionsProducts$Response(params: {
    company: string;
    year: number;
    month: number;

  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, PlanDistributionService.PlanDistributionControllerPlanExceptionsProductsPath, 'get');
    if (params) {

      rb.query('company', params.company, {});
      rb.query('year', params.year, {});
      rb.query('month', params.month, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `planDistributionControllerPlanExceptionsProducts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  planDistributionControllerPlanExceptionsProducts(params: {
    company: string;
    year: number;
    month: number;

  }): Observable<Array<string>> {

    return this.planDistributionControllerPlanExceptionsProducts$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

}
