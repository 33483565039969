import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {SelectPrototype} from '../prototypes/select.prototype';
import {StructService} from '../../../../api/services/struct.service';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-mp-select',
  template: `
    <mat-form-field fxFlex>
      <mat-label translate *ngIf="!multiple && !label">MP</mat-label>
      <mat-label translate *ngIf="multiple && !label">MP</mat-label>
      <mat-label translate *ngIf="label">{{label | translate}}</mat-label>
      <app-search-select [formControl]="control"
                         [loading]="loading"
                         [disabled]="disabled"
                         [multiple]="multiple"
                         [options]="options">
      </app-search-select>
    </mat-form-field>
  `,
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MpSelectComponent),
    },
  ],
})
export class MpSelectComponent extends SelectPrototype implements OnInit {

  @Input()
  label: string = undefined;

  private _multiple: boolean;

  @Input()
  get multiple() {
    return this._multiple;
  }

  set multiple(req) {
    this._multiple = coerceBooleanProperty(req);
  }

  constructor(private structService: StructService) {
    super();
  }

  ngOnInit() {
    this.loading = true;
    this.structService.structControllerScopeMpSelect()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((options) => {
        this.options = options;
        if (this.options.length === 1) {
          this.control.setValue(this.options[0].id);
          this.disabled = true;
        }
        this.loading = false;
      });
    super.ngOnInit();
  }

}
