import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot,} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable()
export class UserRoleGuard implements CanActivate {
  constructor(private router: Router, private snack: MatSnackBar) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return true;
    // let user = this.jwtAuth.getUser();
    //
    // if (
    //   user &&
    //   route.data &&
    //   route.data.roles &&
    //   route.data.roles.includes(user.role)
    // ) {
    //   return true;
    // } else {
    //   this.snack.distribution-open-tenders('You do not have access to this page!', 'OK');
    //   return false;
    // }
  }
}
