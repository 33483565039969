import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {MessagesState} from '../../../../state/messages/messages.state';
import {Navigate} from '@ngxs/router-plugin';

@Component({
  selector: 'app-messages-indicator',
  templateUrl: './messages-indicator.component.html',
  styleUrls: ['./messages-indicator.component.scss']
})
export class MessagesIndicatorComponent implements OnInit {

  @Select(MessagesState.unreadCount) count$: Observable<number>;

  constructor(private store: Store) {
  }

  ngOnInit(): void {
  }

  gotoMessages() {
    this.store.dispatch(new Navigate(['/inbox']));
  }
}
