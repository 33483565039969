/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MonthWithGroupDto } from '../models/month-with-group-dto';
import { ProductGroupCreateDto } from '../models/product-group-create-dto';
import { ProductGroupDto } from '../models/product-group-dto';

@Injectable({
  providedIn: 'root',
})
export class ProductGroupsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation productGroupsControllerMonthWithGroups
   */
  static readonly ProductGroupsControllerMonthWithGroupsPath = '/api/product-groups/monthWithGroups';

  /**
   * Выборка месяцев с группами.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productGroupsControllerMonthWithGroups()` instead.
   *
   * This method doesn't expect any request body.
   */
  productGroupsControllerMonthWithGroups$Response(params: {

    /**
     * Компания
     */
    company: string;

  }): Observable<StrictHttpResponse<Array<MonthWithGroupDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ProductGroupsService.ProductGroupsControllerMonthWithGroupsPath, 'get');
    if (params) {

      rb.query('company', params.company, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MonthWithGroupDto>>;
      })
    );
  }

  /**
   * Выборка месяцев с группами.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `productGroupsControllerMonthWithGroups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  productGroupsControllerMonthWithGroups(params: {

    /**
     * Компания
     */
    company: string;

  }): Observable<Array<MonthWithGroupDto>> {

    return this.productGroupsControllerMonthWithGroups$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MonthWithGroupDto>>) => r.body as Array<MonthWithGroupDto>)
    );
  }

  /**
   * Path part for operation productGroupsControllerFind
   */
  static readonly ProductGroupsControllerFindPath = '/api/product-groups/find';

  /**
   * Выборка.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productGroupsControllerFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  productGroupsControllerFind$Response(params: {

    /**
     * Год
     */
    year: number;

    /**
     * Месяц
     */
    month: number;

    /**
     * Компания
     */
    company: string;

  }): Observable<StrictHttpResponse<Array<ProductGroupDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ProductGroupsService.ProductGroupsControllerFindPath, 'get');
    if (params) {

      rb.query('year', params.year, {});
      rb.query('month', params.month, {});
      rb.query('company', params.company, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProductGroupDto>>;
      })
    );
  }

  /**
   * Выборка.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `productGroupsControllerFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  productGroupsControllerFind(params: {

    /**
     * Год
     */
    year: number;

    /**
     * Месяц
     */
    month: number;

    /**
     * Компания
     */
    company: string;

  }): Observable<Array<ProductGroupDto>> {

    return this.productGroupsControllerFind$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProductGroupDto>>) => r.body as Array<ProductGroupDto>)
    );
  }

  /**
   * Path part for operation productGroupsControllerClone
   */
  static readonly ProductGroupsControllerClonePath = '/api/product-groups/clone';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productGroupsControllerClone()` instead.
   *
   * This method doesn't expect any request body.
   */
  productGroupsControllerClone$Response(params: {
    fromYear: number;
    fromMonth: number;
    toYear: number;
    toMonth: number;
    company: string;

  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ProductGroupsService.ProductGroupsControllerClonePath, 'post');
    if (params) {

      rb.query('fromYear', params.fromYear, {});
      rb.query('fromMonth', params.fromMonth, {});
      rb.query('toYear', params.toYear, {});
      rb.query('toMonth', params.toMonth, {});
      rb.query('company', params.company, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `productGroupsControllerClone$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  productGroupsControllerClone(params: {
    fromYear: number;
    fromMonth: number;
    toYear: number;
    toMonth: number;
    company: string;

  }): Observable<boolean> {

    return this.productGroupsControllerClone$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation productGroupsControllerCreate
   */
  static readonly ProductGroupsControllerCreatePath = '/api/product-groups';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productGroupsControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  productGroupsControllerCreate$Response(params: {
      body: ProductGroupCreateDto
  }): Observable<StrictHttpResponse<ProductGroupDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProductGroupsService.ProductGroupsControllerCreatePath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProductGroupDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `productGroupsControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  productGroupsControllerCreate(params: {
      body: ProductGroupCreateDto
  }): Observable<ProductGroupDto> {

    return this.productGroupsControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<ProductGroupDto>) => r.body as ProductGroupDto)
    );
  }

  /**
   * Path part for operation productGroupsControllerDelete
   */
  static readonly ProductGroupsControllerDeletePath = '/api/product-groups/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productGroupsControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  productGroupsControllerDelete$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProductGroupsService.ProductGroupsControllerDeletePath, 'delete');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `productGroupsControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  productGroupsControllerDelete(params: {
    id: string;

  }): Observable<void> {

    return this.productGroupsControllerDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
