import {Injectable} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import * as _ from 'lodash';
import {TenderTypeDto} from '../../../api/models/tender-type-dto';
import {TenderTypePercentDto} from '../../../api/models/tender-type-percent-dto';
import * as moment from 'moment-timezone';

@Injectable()
export class FormsService {

  constructor(private _fb: FormBuilder) {
  }

  tenderType(record?: TenderTypeDto) {
    return this._fb.group({
      _id: [_.get(record, '_id', undefined)],
      company: [_.get(record, 'company', undefined), Validators.required],
      name: [_.get(record, 'name', ''), Validators.required],
      products: this._fb.array(
        _.chain(record)
          .get('products', [])
          .filter(row => !!row.product)
          .map((row) => this.tenderTypePercent(row))
          .value()
      )
    });
  }

  tenderTypePercent(record: TenderTypePercentDto) {
    return this._fb.group({
      product: [_.get(record, 'product', undefined), Validators.required],
      percent: [_.get(record, 'percent', 0), [Validators.required, Validators.min(1)]]
    });
  }

  product() {
    return new FormGroup({
      _id: new FormControl(undefined),
      name: new FormControl('', Validators.required),
      fullName: new FormControl('', Validators.required),
      packSize: new FormControl(0, [Validators.required, Validators.min(1)]),
      govPriceReg: new FormControl(false, Validators.required),
      company: new FormControl(undefined, Validators.required),
      validFrom: new FormControl(moment().toDate(), Validators.required),
      validTo: new FormControl(moment().toDate(), Validators.required),
      baseProduct: new FormControl(undefined),
      divisions: new FormControl([], Validators.required),
      direction: new FormControl('', Validators.required),
      brand: new FormControl('', Validators.required),
      row: new FormControl('', Validators.required),
      priorityGroup: new FormControl('', Validators.required),
      reimbursement: new FormControl(false, Validators.required),
      productManager: new FormControl('', Validators.required),
      morion: new FormGroup({
        code: new FormControl('', Validators.required),
        groups: new FormArray([])
      })
    });
  }

  productMorionGroup() {
    return new FormGroup({
      name: new FormControl('', Validators.required),
      type: new FormControl('Основная', Validators.required)
    });
  }

  productLinks(group?: any) {
    return this._fb.group({
      crmKP: [_.get(group, 'crmKP', [])],
      crmKH: [_.get(group, 'crmKH', [])],
      warehouseKP: [_.get(group, 'warehouseKP', [])],
      warehouseKH: [_.get(group, 'warehouseKH', [])],
      contentum: [_.get(group, 'contentum', '')],
    });
  }

  struct() {
    return new FormGroup({
      _id: new FormControl(undefined), //
      name: new FormControl('', Validators.required), //
      fullName: new FormControl('', Validators.required), //
      company: new FormControl(undefined, Validators.required), //
      parent: new FormControl(undefined, Validators.required), //
      scope: new FormControl(undefined), //
      position: new FormControl(undefined, Validators.required), //
      areas: new FormControl([], Validators.required), //
      permissions: new FormControl([]),
      workflowGroups: new FormControl([]),
      division: new FormControl(undefined),
      products: new FormControl([]),
      user: new FormControl(undefined)
    });
  }

  user() {
    return new FormGroup({
      _id: new FormControl(undefined),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      company: new FormControl(undefined, Validators.required),
      email: new FormControl(undefined, Validators.required),
      mobile: new FormControl('', Validators.required),
      username: new FormControl(undefined, Validators.required),
      password: new FormControl(undefined),
      rel: new FormGroup({
        crmKP: new FormControl(undefined, Validators.required),
        crmKH: new FormControl(undefined, Validators.required),
      })
    });
  }

  tenderTerm() {
    return new FormGroup({
      _id: new FormControl(undefined),
      area: new FormControl(undefined, Validators.required),
      company: new FormControl(undefined, Validators.required),
      responsible: new FormControl([], Validators.required),
      organization: new FormControl(undefined, Validators.required),
      type: new FormControl(undefined, Validators.required),
      products: new FormControl([]),
      cards: new FormArray([]),
      comment: new FormControl(''),
    });
  }

  project() {

    function planValidator(control): ValidationErrors | null {
      const cost = control?.get('cost')?.value || 0;
      const plan = (control?.get('plans')?.value || []).reduce((sum, month) => {
        return sum + month.products.reduce((prodSum, prod) => {
          return prodSum + ((prod.quantity || 0) * (prod.score || 0));
        }, 0);
      }, 0);

      if (cost > plan) {
        return {planSumLow: true};
      }
      return null;
    }

    return new FormGroup({
      _id: new FormControl(undefined),
      struct: new FormControl(undefined, Validators.required),
      type: new FormControl(undefined, Validators.required),
      doctor: new FormControl(undefined, Validators.required),
      startFrom: new FormControl(undefined, Validators.required),
      prizeType: new FormControl(undefined, Validators.required),
      prizes: new FormControl([], Validators.required),
      products: new FormControl([], Validators.required),
      cost: new FormControl(undefined, Validators.required),
      isDivided: new FormControl(false),
      costPercent: new FormControl(0, Validators.required),
      plans: new FormControl([], Validators.required),
    }, planValidator);
  }

  projectPrize() {
    return new FormGroup({
      name: new FormControl(undefined),
      description: new FormControl(undefined),
      cardNumber: new FormControl(undefined),
      bill: new FormControl(undefined),
      url: new FormControl(undefined),
      cost: new FormControl(0, Validators.required),
      readonly: new FormControl(true, Validators.required),
      item: new FormControl(undefined),
      quantity: new FormControl(0),
    });
  }

  projectPlanMonth() {
    return new FormGroup({
      year: new FormControl(undefined, Validators.required),
      month: new FormControl(undefined, Validators.required),
      products: new FormArray([])
    });
  }

  projectPlanProduct() {
    return new FormGroup({
      product: new FormControl(undefined, Validators.required),
      score: new FormControl(0, Validators.required),
      standardScore: new FormControl(0, Validators.required),
      quantity: new FormControl(0, Validators.required),
    });
  }

}
