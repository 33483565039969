/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class ReportsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation reportsControllerMininetScores
   */
  static readonly ReportsControllerMininetScoresPath = '/api/reports/pp/mininet-scores';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsControllerMininetScores()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsControllerMininetScores$Response(params: {
    year: number;
    month: number;
    company: string;

  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.ReportsControllerMininetScoresPath, 'get');
    if (params) {

      rb.query('year', params.year, {});
      rb.query('month', params.month, {});
      rb.query('company', params.company, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reportsControllerMininetScores$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsControllerMininetScores(params: {
    year: number;
    month: number;
    company: string;

  }): Observable<string> {

    return this.reportsControllerMininetScores$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation reportsControllerPointScores
   */
  static readonly ReportsControllerPointScoresPath = '/api/reports/pp/point-scores';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsControllerPointScores()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsControllerPointScores$Response(params: {
    year: number;
    month: number;
    company: string;

  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.ReportsControllerPointScoresPath, 'get');
    if (params) {

      rb.query('year', params.year, {});
      rb.query('month', params.month, {});
      rb.query('company', params.company, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reportsControllerPointScores$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsControllerPointScores(params: {
    year: number;
    month: number;
    company: string;

  }): Observable<string> {

    return this.reportsControllerPointScores$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation reportsControllerPrizes
   */
  static readonly ReportsControllerPrizesPath = '/api/reports/pp/prizes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsControllerPrizes()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsControllerPrizes$Response(params: {
    year: number;
    month: number;
    company: string;

  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.ReportsControllerPrizesPath, 'get');
    if (params) {

      rb.query('year', params.year, {});
      rb.query('month', params.month, {});
      rb.query('company', params.company, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reportsControllerPrizes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsControllerPrizes(params: {
    year: number;
    month: number;
    company: string;

  }): Observable<string> {

    return this.reportsControllerPrizes$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation reportsControllerProjectsScore
   */
  static readonly ReportsControllerProjectsScorePath = '/api/reports/projects/score';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsControllerProjectsScore()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsControllerProjectsScore$Response(params: {
    fromYear: number;
    fromMonth: number;
    toYear: number;
    toMonth: number;
    company: string;

  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.ReportsControllerProjectsScorePath, 'get');
    if (params) {

      rb.query('fromYear', params.fromYear, {});
      rb.query('fromMonth', params.fromMonth, {});
      rb.query('toYear', params.toYear, {});
      rb.query('toMonth', params.toMonth, {});
      rb.query('company', params.company, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reportsControllerProjectsScore$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsControllerProjectsScore(params: {
    fromYear: number;
    fromMonth: number;
    toYear: number;
    toMonth: number;
    company: string;

  }): Observable<string> {

    return this.reportsControllerProjectsScore$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation reportsControllerMpBudget
   */
  static readonly ReportsControllerMpBudgetPath = '/api/reports/projects/budget';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsControllerMpBudget()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsControllerMpBudget$Response(params: {
    fromYear: number;
    fromMonth: number;
    toYear: number;
    toMonth: number;
    company: string;

  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.ReportsControllerMpBudgetPath, 'get');
    if (params) {

      rb.query('fromYear', params.fromYear, {});
      rb.query('fromMonth', params.fromMonth, {});
      rb.query('toYear', params.toYear, {});
      rb.query('toMonth', params.toMonth, {});
      rb.query('company', params.company, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reportsControllerMpBudget$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsControllerMpBudget(params: {
    fromYear: number;
    fromMonth: number;
    toYear: number;
    toMonth: number;
    company: string;

  }): Observable<string> {

    return this.reportsControllerMpBudget$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
