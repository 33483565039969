import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import * as _ from 'lodash';

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  svgIcon?: string; // UI Lib icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  permissions?: string[];
}

interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string; // Material icon name
  svgIcon?: string; // UI Lib icon name
  sub?: IChildItem[];
  permissions: string[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService {
  iconMenu: IMenuItem[] = [
    {
      name: 'DASHBOARD',
      type: 'link',
      tooltip: 'Dashboard',
      icon: 'dashboard',
      state: 'dashboard',
      permissions: ['BasicAccess']
    },
    {
      name: 'MESSAGES',
      type: 'link',
      tooltip: 'Messages',
      icon: 'email',
      state: 'inbox',
      permissions: ['BasicAccess']
    },
    {
      name: 'TASKS',
      type: 'link',
      tooltip: 'TASKS',
      icon: 'assignment_ind',
      state: 'tasks',
      permissions: ['BasicAccess']
    },
    {
      name: 'DICTIONARIES',
      type: 'dropDown',
      tooltip: 'Dictionaries',
      icon: 'featured_play_list',
      state: 'dictionaries',
      sub: [
        {name: 'AREAS', state: 'areas', permissions: ['dictionaries.areas']},
        {name: 'PROJECT_TYPES', state: 'project-types', permissions: ['dictionaries.project-types']},
        {name: 'PROJECT_KINDS', state: 'project-kinds', permissions: ['dictionary.project-kinds']},
        {name: 'ORGANIZATIONS', state: 'organizations', permissions: ['dictionaries.organizations']},
        // {
        //   name: 'DISTRIBUTION_PERCENTS',
        //   state: 'distribution-percents',
        //   permissions: ['dictionaries.distribution-percents'],
        // },
        // {name: 'CONTACTS', state: 'contacts', permissions: ['dictionaries.contacts']},
        {name: 'PRODUCTS', state: 'products', permissions: ['dictionaries.products']},
        // {name: 'PLANS', state: 'product-plans', permissions: ['dictionaries.plans']},
        // {name: 'AMPERCENT', state: 'percent-plans', permissions: ['dictionaries.percent-plans']},
        // {name: 'PRODUCT_PRICES', state: 'product-prices', permissions: ['dictionaries.prices']},
        // {name: 'PRODUCT_COEFFICIENTS', state: 'product-coefficients', permissions: ['dictionaries.coefficients']},
        // {name: 'PRODUCT_SCORES', state: 'product-scores', permissions: ['dictionaries.scores']},
        // {name: 'VIP_BRANDS', state: 'vip-brands', permissions: ['dictionaries.vip-brands']},
        // {name: 'HOLIDAYS', state: 'holidays', permissions: ['dictionaries.holidays']},
        // {name: 'COMPETITOR_PRODUCTS', state: 'competitor-products', permissions: ['dictionaries.competitor-products']},
        // {name: 'COMPETITOR_GROUPS', state: 'competitor-groups', permissions: ['dictionaries.competitor-products']},
        {name: 'USERS', state: 'users', permissions: ['dictionaries.users', 'godmode']},
        {name: 'STRUCT', state: 'struct', permissions: ['dictionaries.struct', 'godmode']},
        {name: 'STRUCT_HISTORY', state: 'struct-history', permissions: ['dictionaries.struct-history', 'godmode']},
        // {name: 'PRODUCT_MATRIX', state: 'product-matrix', permissions: ['dictionaries.product-matrix']},
        // {name: 'PERMISSIONS', state: 'permissions', permissions: ['dictionaries.permissions']},
        {name: 'NETWORKS', state: 'networks', permissions: ['dictionaries.networks']}
      ],
    },
    // {
    //   name: 'SALES_PLANS',
    //   type: 'dropDown',
    //   tooltip: 'SALES',
    //   icon: 'trending_up',
    //   state: 'sales',
    //   sub: [
    //     // {name: 'ORGANIZATIONS_SALES', state: 'organizations', permissions: ['godmode']},
    //     {name: 'SALES', state: 'sales', permissions: ['BasicAccess']},
    //     // {name: 'SALES_SUBORDINATES', state: 'subordinates', permissions: ['sales.subordinates']},
    //     // {name: 'MP_PLANS', state: 'mp-plans', permissions: ['sales.mp-plans']},
    //     // {name: 'PLAN_FULFILLMENT', state: 'fulfillment', permissions: ['sales.fulfillment']},
    //   ],
    // },
    {
      name: 'Бюджетные закупки',
      type: 'dropDown',
      tooltip: 'Бюджетные закупки',
      icon: 'shop_two',
      state: 'tenders',
      sub: [
        {name: 'Типы', state: 'types', permissions: ['tender.types']},
        {name: 'Условия', state: 'terms', permissions: ['tender.terms']},
        {name: 'Закупки', state: 'list', permissions: ['tenders']},
        {name: 'Карты', state: 'cards', permissions: ['tender.types.create']},
      ],
    },
    {
      name: 'LOYALTY',
      type: 'dropDown',
      tooltip: 'Loyalty',
      icon: 'loyalty',
      state: 'loyalty',
      sub: [
        {name: 'PROJECT_KINDS', state: 'project-kinds', permissions: ['dictionary.project-kinds']},
        {name: 'PROJECT_TYPES', state: 'project-types', permissions: ['dictionaries.project-types']},
        {name: 'PROJECTS', state: 'projects', permissions: ['loyalty.projects']},
        {name: 'VIP_STATUSES', state: 'vip-statuses', permissions: ['loyalty.vip-statuses']},
        // {name: 'REPORTS', state: 'reports', permissions: ['reports.mp-projects-distribution']},
      ],
    },
    {
      name: 'Аптечные акции',
      type: 'dropDown',
      tooltip: 'Pharmacy promotions',
      icon: 'business',
      state: 'promotions',
      sub: [
        {name: 'Условия', state: 'terms', permissions: ['pharmacy-promotions', 'pharmacy-promotions.edit']},
        {name: 'Участники', state: 'participants', permissions: ['pharmacy-promotions']},
        {name: 'Доп. упаковки', state: 'additional', permissions: ['pharmacy-promotions']},
        {name: 'Сети', state: 'networks', permissions: ['pharmacy-promotions.networks']},
        {name: 'Мини Сети', state: 'mininets', permissions: ['pharmacy-promotions.networks']},
        {name: 'Призы', state: 'prizes', permissions: ['pharmacy-promotions.networks']},
        // {name: 'CALCULATOR', state: 'calculator', permissions: ['pharmacy-terms']},
        // {name: 'REPORTS', state: 'reports', permissions: ['pharmacy-terms']},
      ],
    },
    // {
    //   name: 'COMPETITOR_PRICES',
    //   type: 'dropDown',
    //   tooltip: 'Competitor prices',
    //   icon: 'assignment',
    //   state: 'competitor-prices',
    //   sub: [
    //     {name: 'PRICES', state: 'view', permissions: ['competitor-prices']},
    //     {name: 'GROUPS', state: 'groups', permissions: ['competitor-prices.sync']},
    //     {name: 'COMPETITOR_PRODUCTS', state: 'products', permissions: ['competitor-prices.sync']},
    //     {name: 'UPLOAD', state: 'upload', permissions: ['competitor-prices.upload']},
    //   ],
    // },
    {
      name: 'ND_REPORTS',
      type: 'dropDown',
      tooltip: 'ND Reports',
      icon: 'receipt',
      state: 'nd',
      sub: [
        {name: 'REPORT', state: 'view', permissions: ['nd']},
        // {name: 'DISTRIBUTORS', state: 'distrib', permissions: ['nd.upload']},
        {name: 'UPLOAD', state: 'upload', permissions: ['nd.upload']},
        {name: 'ND_PRODUCT_LINKS', state: 'nd-product-links', permissions: ['nd.upload']},
      ],
    },
    {
      name: 'Система',
      type: 'dropDown',
      tooltip: 'Система',
      icon: 'settings_applications',
      state: 'system',
      sub: [
        {name: 'Пользователи', state: 'users', permissions: ['admin', 'UsersAdmin', 'Developer']},
        {name: 'Структура', state: 'struct', permissions: ['admin', 'StructAdmin', 'Developer']},
        {name: 'История структуры', state: 'struct-history', permissions: ['admin', 'StructAdmin', 'Developer']}
      ],
    }
  ];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle = 'Frequently Accessed';
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.menuWithValidPermissions(this.iconMenu));
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    switch (menuType) {
      case 'separator-menu':
        this.menuItems.next([]);
        break;
      case 'icon-menu':
        this.menuItems.next(this.iconMenu);
        break;
      default:
        this.menuItems.next([]);
    }
  }

  private menuWithValidPermissions(menu: IMenuItem[]): IMenuItem[] {
    return menu.map((menu) => {
      const permissions = this.getMenuPermissions(menu);
      return {
        ...menu,
        permissions
      };
    });
  }

  private getMenuPermissions(menu: IMenuItem | IChildItem): string[] {
    if (menu.hasOwnProperty('permissions') && menu.permissions instanceof Array && menu.permissions.length) {
      return menu.permissions;
    }
    if (menu.type === 'dropDown' && menu.hasOwnProperty('sub')) {
      return _.chain(menu.sub)
        .flatMap(this.getMenuPermissions)
        .uniq()
        .value();

      // return menu.sub
      //   .map(m => this.getMenuPermissions(m))
      //   .reduce((arr, perms) => arr.concat(perms));
    }
    return [];
  }

}
