import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SharedMaterialModule} from '../shared-material.module';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {SharedPipesModule} from '../pipes/shared-pipes.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {SharedDirectivesModule} from '../directives/shared-directives.module';

// ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT
import {HeaderSideComponent} from './layout-components/header-side/header-side.component';
import {SidebarSideComponent} from './layout-components/sidebar-side/sidebar-side.component';

// ONLY REQUIRED FOR **TOP** NAVIGATION LAYOUT
// import {HeaderTopComponent} from './header-top/header-top.component';
// import {SidebarTopComponent} from './sidebar-top/sidebar-top.component';
// ALWAYS REQUIRED
import {AdminLayoutComponent} from './layout-components/layouts/admin-layout/admin-layout.component';
import {AuthLayoutComponent} from './layout-components/layouts/auth-layout/auth-layout.component';
import {NotificationsComponent} from './layout-components/notifications/notifications.component';
import {SidenavComponent} from './layout-components/sidenav/sidenav.component';
import {BreadcrumbComponent} from './layout-components/breadcrumb/breadcrumb.component';
import {AppComfirmComponent} from '../services/dialogs/confirm/app-confirm.component';
import {AppLoaderComponent} from '../services/dialogs/loader/app-loader.component';
import {EgretSidebarComponent, EgretSidebarTogglerDirective} from './layout-components/egret-sidebar/egret-sidebar.component';
import {EgretNotifications2Component} from './layout-components/egret-notifications2/egret-notifications2.component';
import {AssignmentIndicatorComponent} from './layout-components/assignment-indicator/assignment-indicator.component';
import {MessagesIndicatorComponent} from './layout-components/messages-indicator/messages-indicator.component';
import {DialogTitleComponent} from './dialog-title/dialog-title.component';
import {AppSuccessComponent} from '../services/dialogs/success/app-success.component';
import {AppFailedComponent} from '../services/dialogs/failed/app-failed.component';
import {NgxPermissionsModule, NgxPermissionsRestrictStubModule} from 'ngx-permissions';
import {SearchSelectComponent} from './shared-components/search-select/search-select.component';
import {ViewComponentsModule} from './view-components/view-components.module';
import {ViewLabelComponent} from './shared-components/view-label/view-label.component';
import {InfoContainerComponent} from './shared-components/info-container/info-container.component';


const components = [
  // HeaderTopComponent,
  // SidebarTopComponent,
  SidenavComponent,
  NotificationsComponent,
  SidebarSideComponent,
  HeaderSideComponent,
  AdminLayoutComponent,
  AuthLayoutComponent,
  BreadcrumbComponent,
  AppComfirmComponent,
  AppLoaderComponent,
  AppSuccessComponent,
  AppFailedComponent,
  EgretNotifications2Component,
  EgretSidebarComponent,
  EgretSidebarTogglerDirective,
  AssignmentIndicatorComponent,
  MessagesIndicatorComponent,
  DialogTitleComponent,
  SearchSelectComponent,
  ViewLabelComponent,
  InfoContainerComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule,
    FlexLayoutModule,
    PerfectScrollbarModule,
    SharedPipesModule,
    SharedDirectivesModule,
    SharedMaterialModule,
    ReactiveFormsModule,
    NgxPermissionsModule,
    NgxPermissionsRestrictStubModule,
    ViewComponentsModule
  ],
  declarations: components,
  exports: [...components]
})
export class SharedComponentsModule {
}
