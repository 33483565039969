import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

// SERVICES
import {ThemeService} from './services/theme.service';
import {NavigationService} from './services/navigation.service';
import {RoutePartsService} from './services/route-parts.service';
import {AuthGuard} from '../state/auth/auth.guard';
import {UserRoleGuard} from './guards/user-role.guard';

import {SharedComponentsModule} from './components/shared-components.module';
import {SharedPipesModule} from './pipes/shared-pipes.module';
import {SharedDirectivesModule} from './directives/shared-directives.module';
import {SharedMaterialModule} from './shared-material.module';
import {TranslateModule} from '@ngx-translate/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {DialogsService} from './services/dialogs/dialogs.service';
import {NgxPermissionsModule} from 'ngx-permissions';
import {FormsModule} from './components/form-components/forms.module';

@NgModule({
  imports: [
    CommonModule,
    SharedComponentsModule,
    SharedPipesModule,
    SharedDirectivesModule
  ],
  providers: [
    ThemeService,
    NavigationService,
    RoutePartsService,
    AuthGuard,
    UserRoleGuard,
    DialogsService,
  ],
  exports: [
    CommonModule,
    SharedComponentsModule,
    SharedPipesModule,
    SharedDirectivesModule,
    SharedMaterialModule,
    FormsModule,
    TranslateModule,
    FlexLayoutModule,
    NgxPermissionsModule
  ]
})
export class SharedModule {
}
