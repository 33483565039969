/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SearchSelectItemDto } from '../models/search-select-item-dto';

@Injectable({
  providedIn: 'root',
})
export class AreasService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation areasControllerLabel
   */
  static readonly AreasControllerLabelPath = '/api/areas/label/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `areasControllerLabel()` instead.
   *
   * This method doesn't expect any request body.
   */
  areasControllerLabel$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, AreasService.AreasControllerLabelPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `areasControllerLabel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  areasControllerLabel(params: {
    id: string;

  }): Observable<string> {

    return this.areasControllerLabel$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation areasControllerGet
   */
  static readonly AreasControllerGetPath = '/api/areas/rest/get/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `areasControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  areasControllerGet$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AreasService.AreasControllerGetPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `areasControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  areasControllerGet(params: {
    id: string;

  }): Observable<void> {

    return this.areasControllerGet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation areasControllerSelectFull
   */
  static readonly AreasControllerSelectFullPath = '/api/areas/select-full';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `areasControllerSelectFull()` instead.
   *
   * This method doesn't expect any request body.
   */
  areasControllerSelectFull$Response(params?: {

  }): Observable<StrictHttpResponse<Array<SearchSelectItemDto>>> {

    const rb = new RequestBuilder(this.rootUrl, AreasService.AreasControllerSelectFullPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SearchSelectItemDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `areasControllerSelectFull$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  areasControllerSelectFull(params?: {

  }): Observable<Array<SearchSelectItemDto>> {

    return this.areasControllerSelectFull$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SearchSelectItemDto>>) => r.body as Array<SearchSelectItemDto>)
    );
  }

  /**
   * Path part for operation areasControllerSelectScope
   */
  static readonly AreasControllerSelectScopePath = '/api/areas/select-scope';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `areasControllerSelectScope()` instead.
   *
   * This method doesn't expect any request body.
   */
  areasControllerSelectScope$Response(params?: {

  }): Observable<StrictHttpResponse<Array<SearchSelectItemDto>>> {

    const rb = new RequestBuilder(this.rootUrl, AreasService.AreasControllerSelectScopePath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SearchSelectItemDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `areasControllerSelectScope$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  areasControllerSelectScope(params?: {

  }): Observable<Array<SearchSelectItemDto>> {

    return this.areasControllerSelectScope$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SearchSelectItemDto>>) => r.body as Array<SearchSelectItemDto>)
    );
  }

}
