<mat-toolbar class="topbar mat-bg-card">
  <!-- Sidenav toggle button -->
  <button
    *ngIf="layoutConf.sidebarStyle !== 'compact'"
    mat-icon-button
    id="sidenavToggle"
    (click)="toggleSidenav()"
    matTooltip="Toggle Hide/Open"
  >
    <mat-icon>menu</mat-icon>
  </button>

  <!-- Search form -->
<!--  <div fxFlex fxHide.lt-sm="true" class="search-bar">-->
<!--    <form class="top-search-form">-->
<!--      <mat-icon role="img">search</mat-icon>-->
<!--      <input autofocus="true" placeholder="Search" type="text" />-->
<!--    </form>-->
<!--  </div>-->

  <span fxFlex></span>
  <!-- Language Switcher -->
<!--  <button mat-button [matMenuTriggerFor]="menu">-->
<!--    <span class="flag-icon {{currentLang.flag}} mr-8"></span>-->
<!--    <span>{{currentLang.name}}</span>-->
<!--  </button>-->
<!--<mat-menu #menu="matMenu">-->
<!--  <button mat-menu-item *ngFor="let lang of availableLangs" (click)="setLang(lang)">-->
<!--        <span class="flag-icon mr-8 {{lang.flag}}"></span>-->
<!--        <span>{{lang.name}}</span>-->
<!--  </button>-->
<!--</mat-menu>-->


<!--  <egret-search-input-over placeholder="Country (e.g. US)" resultPage="/search">-->
<!--  </egret-search-input-over>-->
  <!-- Open "views/search-view/result-page.component" to understand how to subscribe to input field value -->

  <!-- Notification toggle button -->
  <app-assignment-indicator></app-assignment-indicator>
  <app-messages-indicator></app-messages-indicator>
  <!-- Top left user menu -->
  <button
    mat-icon-button
    [matMenuTriggerFor]="accountMenu"
    class="topbar-button-right img-button"
  >
    <img src="../../../../../assets/images/user.jpg" alt="" />
  </button>

  <mat-menu #accountMenu="matMenu">
    <!--    <button mat-menu-item [routerLink]="['/profile/overview']">-->
    <!--      <mat-icon>account_box</mat-icon>-->
    <!--      <span translate>PROFILE</span>-->
    <!--    </button>-->
    <!--    <button mat-menu-item [routerLink]="['/profile/settings']">-->
    <!--      <mat-icon>settings</mat-icon>-->
    <!--      <span translate>SETTINGS</span>-->
    <!--    </button>-->
    <!--        <button mat-menu-item>-->
    <!--          <mat-icon>notifications_off</mat-icon>-->
    <!--          <span>Disable alerts</span>-->
    <!--        </button>-->
    <button mat-menu-item (click)="signout()">
      <mat-icon>exit_to_app</mat-icon>
      <span translate>SIGN_OUT</span>
    </button>
  </mat-menu>
</mat-toolbar>
