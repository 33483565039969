/* tslint:disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { SystemService } from './services/system.service';
import { UsersService } from './services/users.service';
import { MessagesService } from './services/messages.service';
import { TranslationsService } from './services/translations.service';
import { CompanyService } from './services/company.service';
import { ProductsService } from './services/products.service';
import { TenderTypesService } from './services/tender-types.service';
import { CardsService } from './services/cards.service';
import { ContactsService } from './services/contacts.service';
import { AccountsService } from './services/accounts.service';
import { PromotionTermsService } from './services/promotion-terms.service';
import { PrizesService } from './services/prizes.service';
import { MininetsService } from './services/mininets.service';
import { AuthService } from './services/auth.service';
import { CamundaService } from './services/camunda.service';
import { ChatbotService } from './services/chatbot.service';
import { GodmodeService } from './services/godmode.service';
import { ReportsService } from './services/reports.service';
import { AreasService } from './services/areas.service';
import { DivisionsService } from './services/divisions.service';
import { NdService } from './services/nd.service';
import { PlanDistributionService } from './services/plan-distribution.service';
import { QlikService } from './services/qlik.service';
import { StructService } from './services/struct.service';
import { TasksService } from './services/tasks.service';
import { TenderTermsService } from './services/tender-terms.service';
import { SalesService } from './services/sales.service';
import { PermissionsService } from './services/permissions.service';
import { OrganizationsService } from './services/organizations.service';
import { ProjectTypesService } from './services/project-types.service';
import { ProjectsService } from './services/projects.service';
import { NetworksService } from './services/networks.service';
import { TendersService } from './services/tenders.service';
import { ProjectKindsService } from './services/project-kinds.service';
import { SpecialitiesService } from './services/specialities.service';
import { VipService } from './services/vip.service';
import { LabelsService } from './services/labels.service';
import { ContactLinksService } from './services/contact-links.service';
import { PromotionExtraPacksService } from './services/promotion-extra-packs.service';
import { ProductGroupsService } from './services/product-groups.service';
import { TestService } from './services/test.service';
import { DictionariesService } from './services/dictionaries.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    SystemService,
    UsersService,
    MessagesService,
    TranslationsService,
    CompanyService,
    ProductsService,
    TenderTypesService,
    CardsService,
    ContactsService,
    AccountsService,
    PromotionTermsService,
    PrizesService,
    MininetsService,
    AuthService,
    CamundaService,
    ChatbotService,
    GodmodeService,
    ReportsService,
    AreasService,
    DivisionsService,
    NdService,
    PlanDistributionService,
    QlikService,
    StructService,
    TasksService,
    TenderTermsService,
    SalesService,
    PermissionsService,
    OrganizationsService,
    ProjectTypesService,
    ProjectsService,
    NetworksService,
    TendersService,
    ProjectKindsService,
    SpecialitiesService,
    VipService,
    LabelsService,
    ContactLinksService,
    PromotionExtraPacksService,
    ProductGroupsService,
    TestService,
    DictionariesService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
