import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Host, OnDestroy} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-mdt-picker-custom-header',
  template: `
    <div class="custom-calendar-header">
      <button mat-icon-button class="custom-calendar-header-double-arrow" (click)="previousClicked()">
        <mat-icon>keyboard_arrow_left</mat-icon>
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <span class="custom-calendar-header-label">{{periodLabel}}</span>
      <button mat-icon-button class="custom-calendar-header-double-arrow" (click)="nextClicked()">
        <mat-icon>keyboard_arrow_right</mat-icon>
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>`,
  styleUrls: ['year-picker-header.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YearPickerHeaderComponent<D> implements OnDestroy {
  private _destroyed = new Subject<void>();

  constructor(private _calendar: MatCalendar<D>,
              private _dateAdapter: DateAdapter<D>,
              cdr: ChangeDetectorRef) {
    _calendar.stateChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => cdr.markForCheck());
  }

  get periodLabel() {
    return this._dateAdapter
      .format(this._calendar.activeDate, 'YYYY')
      .toLocaleUpperCase();
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  previousClicked() {
    this._calendar.activeDate =
      this._dateAdapter.addCalendarYears(this._calendar.activeDate, -24);
  }

  nextClicked() {
    this._calendar.activeDate =
      this._dateAdapter.addCalendarYears(this._calendar.activeDate, 24);
  }
}
