/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateTenderDto } from '../models/create-tender-dto';
import { TenderDto } from '../models/tender-dto';
import { TenderInfoDto } from '../models/tender-info-dto';
import { TenderMonthDto } from '../models/tender-month-dto';
import { TenderSaleDto } from '../models/tender-sale-dto';

@Injectable({
  providedIn: 'root',
})
export class TendersService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation tenderControllerInfo
   */
  static readonly TenderControllerInfoPath = '/api/tenders/info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderControllerInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderControllerInfo$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<TenderInfoDto>> {

    const rb = new RequestBuilder(this.rootUrl, TendersService.TenderControllerInfoPath, 'get');
    if (params) {

      rb.query('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenderInfoDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderControllerInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderControllerInfo(params: {
    id: string;

  }): Observable<TenderInfoDto> {

    return this.tenderControllerInfo$Response(params).pipe(
      map((r: StrictHttpResponse<TenderInfoDto>) => r.body as TenderInfoDto)
    );
  }

  /**
   * Path part for operation tenderControllerMonthSelect
   */
  static readonly TenderControllerMonthSelectPath = '/api/tenders/month-select';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderControllerMonthSelect()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderControllerMonthSelect$Response(params: {
    company: string;

  }): Observable<StrictHttpResponse<Array<TenderMonthDto>>> {

    const rb = new RequestBuilder(this.rootUrl, TendersService.TenderControllerMonthSelectPath, 'get');
    if (params) {

      rb.query('company', params.company, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TenderMonthDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderControllerMonthSelect$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderControllerMonthSelect(params: {
    company: string;

  }): Observable<Array<TenderMonthDto>> {

    return this.tenderControllerMonthSelect$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TenderMonthDto>>) => r.body as Array<TenderMonthDto>)
    );
  }

  /**
   * Path part for operation tenderControllerSales
   */
  static readonly TenderControllerSalesPath = '/api/tenders/sales';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderControllerSales()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderControllerSales$Response(params: {
    term: string;
    year: number;
    month: number;

  }): Observable<StrictHttpResponse<Array<TenderSaleDto>>> {

    const rb = new RequestBuilder(this.rootUrl, TendersService.TenderControllerSalesPath, 'get');
    if (params) {

      rb.query('term', params.term, {});
      rb.query('year', params.year, {});
      rb.query('month', params.month, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TenderSaleDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderControllerSales$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderControllerSales(params: {
    term: string;
    year: number;
    month: number;

  }): Observable<Array<TenderSaleDto>> {

    return this.tenderControllerSales$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TenderSaleDto>>) => r.body as Array<TenderSaleDto>)
    );
  }

  /**
   * Path part for operation tenderControllerProductsReport
   */
  static readonly TenderControllerProductsReportPath = '/api/tenders/products-report';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderControllerProductsReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderControllerProductsReport$Response(params: {
    year: number;
    month: number;
    company: string;

  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, TendersService.TenderControllerProductsReportPath, 'get');
    if (params) {

      rb.query('year', params.year, {});
      rb.query('month', params.month, {});
      rb.query('company', params.company, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderControllerProductsReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderControllerProductsReport(params: {
    year: number;
    month: number;
    company: string;

  }): Observable<string> {

    return this.tenderControllerProductsReport$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation tenderControllerCardsReport
   */
  static readonly TenderControllerCardsReportPath = '/api/tenders/cards-report';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderControllerCardsReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderControllerCardsReport$Response(params: {
    year: number;
    month: number;
    company: string;

  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, TendersService.TenderControllerCardsReportPath, 'get');
    if (params) {

      rb.query('year', params.year, {});
      rb.query('month', params.month, {});
      rb.query('company', params.company, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderControllerCardsReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderControllerCardsReport(params: {
    year: number;
    month: number;
    company: string;

  }): Observable<string> {

    return this.tenderControllerCardsReport$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation tenderControllerTermsReport
   */
  static readonly TenderControllerTermsReportPath = '/api/tenders/terms-report';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderControllerTermsReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderControllerTermsReport$Response(params: {
    company: string;

  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, TendersService.TenderControllerTermsReportPath, 'get');
    if (params) {

      rb.query('company', params.company, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderControllerTermsReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderControllerTermsReport(params: {
    company: string;

  }): Observable<string> {

    return this.tenderControllerTermsReport$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation tenderControllerTenderProductsReport
   */
  static readonly TenderControllerTenderProductsReportPath = '/api/tenders/tender-products-report';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderControllerTenderProductsReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderControllerTenderProductsReport$Response(params: {
    year: number;
    month: number;
    company: string;

  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, TendersService.TenderControllerTenderProductsReportPath, 'get');
    if (params) {

      rb.query('year', params.year, {});
      rb.query('month', params.month, {});
      rb.query('company', params.company, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderControllerTenderProductsReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderControllerTenderProductsReport(params: {
    year: number;
    month: number;
    company: string;

  }): Observable<string> {

    return this.tenderControllerTenderProductsReport$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation tenderControllerTenderCardsReport
   */
  static readonly TenderControllerTenderCardsReportPath = '/api/tenders/tender-cards-report';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderControllerTenderCardsReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderControllerTenderCardsReport$Response(params: {
    year: number;
    month: number;
    company: string;

  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, TendersService.TenderControllerTenderCardsReportPath, 'get');
    if (params) {

      rb.query('year', params.year, {});
      rb.query('month', params.month, {});
      rb.query('company', params.company, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderControllerTenderCardsReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderControllerTenderCardsReport(params: {
    year: number;
    month: number;
    company: string;

  }): Observable<string> {

    return this.tenderControllerTenderCardsReport$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation tenderControllerCreate
   */
  static readonly TenderControllerCreatePath = '/api/tenders/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tenderControllerCreate$Response(params: {
      body: CreateTenderDto
  }): Observable<StrictHttpResponse<TenderDto>> {

    const rb = new RequestBuilder(this.rootUrl, TendersService.TenderControllerCreatePath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenderDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tenderControllerCreate(params: {
      body: CreateTenderDto
  }): Observable<TenderDto> {

    return this.tenderControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<TenderDto>) => r.body as TenderDto)
    );
  }

  /**
   * Path part for operation tenderControllerApprove
   */
  static readonly TenderControllerApprovePath = '/api/tenders/{id}/approve';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderControllerApprove()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tenderControllerApprove$Response(params: {
    id: string;
      body: CreateTenderDto
  }): Observable<StrictHttpResponse<TenderDto>> {

    const rb = new RequestBuilder(this.rootUrl, TendersService.TenderControllerApprovePath, 'put');
    if (params) {

      rb.path('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenderDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderControllerApprove$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tenderControllerApprove(params: {
    id: string;
      body: CreateTenderDto
  }): Observable<TenderDto> {

    return this.tenderControllerApprove$Response(params).pipe(
      map((r: StrictHttpResponse<TenderDto>) => r.body as TenderDto)
    );
  }

  /**
   * Path part for operation tenderControllerGet
   */
  static readonly TenderControllerGetPath = '/api/tenders/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderControllerGet$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<TenderDto>> {

    const rb = new RequestBuilder(this.rootUrl, TendersService.TenderControllerGetPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenderDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderControllerGet(params: {
    id: string;

  }): Observable<TenderDto> {

    return this.tenderControllerGet$Response(params).pipe(
      map((r: StrictHttpResponse<TenderDto>) => r.body as TenderDto)
    );
  }

  /**
   * Path part for operation tenderControllerUpdate
   */
  static readonly TenderControllerUpdatePath = '/api/tenders/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tenderControllerUpdate$Response(params: {
    id: string;
      body: CreateTenderDto
  }): Observable<StrictHttpResponse<TenderDto>> {

    const rb = new RequestBuilder(this.rootUrl, TendersService.TenderControllerUpdatePath, 'put');
    if (params) {

      rb.path('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenderDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tenderControllerUpdate(params: {
    id: string;
      body: CreateTenderDto
  }): Observable<TenderDto> {

    return this.tenderControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<TenderDto>) => r.body as TenderDto)
    );
  }

}
