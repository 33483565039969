import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {FormsService} from '../forms.service';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import * as _ from 'lodash';
import {MatChipInputEvent} from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {StructDto} from '../../../../api/models/struct-dto';

@Component({
  selector: 'app-struct-form',
  template: `
    <form [formGroup]="form" fxLayout="column">
      <app-struct-select label="PARENT" formControlName="parent"></app-struct-select>
      <app-struct-select label="SCOPE" formControlName="scope"></app-struct-select>
      <mat-form-field fxFlex>
        <mat-label ngx-translate>NAME</mat-label>
        <input matInput formControlName="name">
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label ngx-translate>FULL_NAME</mat-label>
        <input matInput formControlName="fullName">
      </mat-form-field>
      <app-company-select formControlName="company"></app-company-select>
      <app-position-select formControlName="position"></app-position-select>
      <app-user-select formControlName="user" onlyActive="false" ></app-user-select>
      <app-area-select multiple="true" formControlName="areas"></app-area-select>
      <app-division-select formControlName="division" [company]="company" fxFlex></app-division-select>
      <app-permission-select multiple="true" formControlName="permissions"></app-permission-select>
      <mat-form-field>
        <mat-chip-list #chipList>
          <mat-chip *ngFor="let group of workflowGroups" [selectable]="true"
                    [removable]="true" (removed)="removeGroup(group)">
            {{group}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input placeholder="WORKFLOW_GROUPS"
                 [matChipInputFor]="chipList"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 (matChipInputTokenEnd)="addGroup($event)">
        </mat-chip-list>
      </mat-form-field>
    </form>
  `
})
export class StructFormComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  value: StructDto;

  @Output()
  valueChange: EventEmitter<StructDto> = new EventEmitter<StructDto>();

  @Output()
  validChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  loading = true;
  form: FormGroup;
  destroyed$: Subject<void> = new Subject<void>();
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  get workflowGroups() {
    return this.form.get('workflowGroups').value || [];
  }

  set workflowGroups(groups: string[]) {
    this.form.get('workflowGroups').setValue(groups);
  }

  get company() {
    return this.form?.get('company')?.value || undefined;
  }

  constructor(private formsService: FormsService) {
    this.form = this.formsService.struct();
  }

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        this.valueChange.emit(value);
      });

    this.form.statusChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.validChange.emit(this.form.valid);
      });

    this.form.get('company').valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.form.get('division').setValue(undefined);
      });
  }

  ngOnDestroy(): void {
    if (this.destroyed$) {
      this.destroyed$.next();
      this.destroyed$.complete();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('value')) {
      if (_.isEqual(changes.value.currentValue, changes.value.previousValue)) {
        return;
      }
      this.form.patchValue(changes.value.currentValue);
      this.form.updateValueAndValidity();
    }
  }

  removeGroup(group: string): void {
    const groups = this.workflowGroups;
    const index = groups.indexOf(group);

    if (index >= 0) {
      groups.splice(index, 1);
      this.workflowGroups = groups;
    }
  }

  addGroup(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    const groups = this.workflowGroups;

    if ((value || '').trim()) {
      groups.push(value.trim());
      this.workflowGroups = groups;
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

}
