import * as  moment from 'moment-timezone';

export function monthsBetweenDates(start: Date | string, end: Date | string) {

  const dateStart = moment(start).tz('Europe/Kiev');
  const dateEnd = moment(end).tz('Europe/Kiev');
  const timeValues = [];

  while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
    timeValues.push({
      year: dateStart.year(),
      month: dateStart.month()
    });
    dateStart.add(1, 'month');
  }

  return timeValues;

}
