import {Action, createSelector, Selector, State, StateContext, Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {bufferTime, filter, mergeMap} from 'rxjs/operators';
import {LabelsService} from '../../api/services/labels.service';

export namespace Labels {
  export class Load {
    static readonly type = '[Labels] Load';

    constructor(public id: string) {
    }
  }

  export class Save {
    static readonly type = '[Labels] Save';

    constructor(public labels: Label[]) {
    }
  }

}

export interface Label {
  _id: string;
  label: string;
  description?: string;
}

interface LabelsStateModel {
  list: Label[];
}

@State<LabelsStateModel>({
  name: 'labels',
  defaults: {
    list: []
  }
})
@Injectable()
export class LabelsState {

  loader$: Subject<string> = new Subject<string>();
  @Selector()
  static labels(state: LabelsStateModel) {
    return state.list;
  }

  static getLabelById(id: string) {
    return createSelector(
      [LabelsState],
      (state: LabelsStateModel) => state.list.find(label => label._id === id));
  }

  constructor(private store: Store, private labelsService: LabelsService) {
    this.loader$
      .pipe(
        bufferTime(500),
        filter(ids => !!ids?.length),
        mergeMap(ids => this.labelsService.labelsControllerLoad({
          body: ids
        }))
      )
      .subscribe((labels) => {
        this.store.dispatch(new Labels.Save(labels));
      });
  }

  @Action(Labels.Load)
  loadLabel(ctx: StateContext<LabelsStateModel>, action: Labels.Load) {
    const state = ctx.getState();
    const id = action.id;
    const index = state.list.findIndex(label => label._id === id);
    if (index !== -1) {
      return;
    }
    this.loader$.next(id);
  }

  @Action(Labels.Save)
  saveLabels(ctx: StateContext<LabelsStateModel>, action: Labels.Save) {
    const newLabels = action.labels;
    const state = ctx.getState();
    const labels = state.list;
    ctx.patchState({
      list: labels.concat(newLabels)
    });
  }

}
