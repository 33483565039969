<div class="app-admin-wrap" [dir]='layoutConf?.dir'>
  <!-- Header for top navigation layout -->
  <!-- ONLY REQUIRED FOR **TOP** NAVIGATION LAYOUT -->
  <app-header-top
    *ngIf="layoutConf.navigationPos === 'top'"
    [notificPanel]="notificationPanel">
  </app-header-top>
  <!-- Main Container -->
  <mat-sidenav-container
  [dir]='layoutConf.dir'
  class="app-admin-container app-side-nav-container mat-drawer-transition sidebar-{{layoutConf?.sidebarColor}} topbar-{{layoutConf?.topbarColor}} footer-{{layoutConf?.footerColor}}"
  [ngClass]="adminContainerClasses">
    <mat-sidenav-content>
    <!-- SIDEBAR -->
    <!-- ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT -->
    <app-sidebar-side
    *ngIf="layoutConf.navigationPos === 'side'"
    (mouseenter)="sidebarMouseenter($event)"
    (mouseleave)="sidebarMouseleave($event)"
    ></app-sidebar-side>

    <!-- Top navigation layout (navigation for mobile screen) -->
    <!-- ONLY REQUIRED FOR **TOP** NAVIGATION MOBILE LAYOUT -->
    <app-sidebar-top *ngIf="layoutConf.navigationPos === 'top' && layoutConf.isMobile"></app-sidebar-top>

    <!-- App content -->
    <div class="main-content-wrap" id="main-content-wrap" [perfectScrollbar]="" [disabled]="layoutConf.topbarFixed || !layoutConf.perfectScrollbar">
      <!-- Header for side navigation layout -->
      <!-- ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT -->
      <app-header-side
        *ngIf="layoutConf.navigationPos === 'side'"
        [notificPanel]="notificationPanel">
      </app-header-side>

      <div class="rightside-content-hold" id="rightside-content-hold" [perfectScrollbar]="scrollConfig" [disabled]="!layoutConf.topbarFixed || !layoutConf.perfectScrollbar">
        <!-- View Loader -->
        <div class="view-loader" *ngIf="isModuleLoading" style="position:fixed;"
        fxLayout="column" fxLayoutAlign="center center">
          <div class="spinner">
            <div class="double-bounce1 mat-bg-accent"></div>
            <div class="double-bounce2 mat-bg-primary"></div>
          </div>
        </div>
        <!-- Breadcrumb -->
        <app-breadcrumb></app-breadcrumb>
        <!-- View outlet -->
        <div class="container-dynamic" fxFlex fxLayout="column">
          <router-outlet></router-outlet>
        </div>
        <span class="m-auto" *ngIf="!layoutConf.footerFixed"></span>
      </div>
      <span class="m-auto" *ngIf="layoutConf.footerFixed"></span>
    </div>
    <!-- View overlay for mobile navigation -->
    <div class="sidebar-backdrop"
    [ngClass]="{'visible': layoutConf.sidebarStyle !== 'closed' && layoutConf.isMobile}"
    (click)="closeSidebar()"></div>
    </mat-sidenav-content>


    <!-- Notificaation bar -->
    <mat-sidenav #notificationPanel mode="over" class="" position="end">
      <div class="nofication-panel" fxLayout="column">
        <app-notifications [notificPanel]="notificationPanel"></app-notifications>
      </div>
    </mat-sidenav>

    <egret-notifications2></egret-notifications2>
  </mat-sidenav-container>
</div>


<!-- Only for demo purpose -->
<!-- Remove this from your production version -->
<!--<app-customizer></app-customizer>-->
