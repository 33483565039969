import {ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, FormArray, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {FormsService} from '../forms.service';
import {distinctUntilChanged, filter, switchMap, takeUntil, tap} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, Subject} from 'rxjs';
import {ProjectTypesService} from '../../../../api/services/project-types.service';
import {ProjectTypeDto} from '../../../../api/models/project-type-dto';
import {ProjectPrizeDto} from '../../../../api/models/project-prize-dto';
import {MatListOption, MatSelectionListChange} from '@angular/material/list';
import {ProjectTypePrizeDto} from '../../../../api/models/project-type-prize-dto';
import {SelectionModel} from '@angular/cdk/collections';

@Component({
  selector: 'app-project-prizes-form',
  template: `
    <ng-container *ngIf="!loading">
      <ng-container *ngIf="!!projectTypeEntity && projectTypeEntity?.settings.prizeCreateByUser">
        <form [formGroup]="$any(form)">
          <div fxLayout="column wrap" *ngFor="let control of form.controls; let i = index" [formGroupName]="i">
            <div fxLayout="row" fxLayoutAlign="start center">
              <span style="font-weight: bold" fxFlex>{{ 'PRIZE' | translate }} №{{ i + 1 }}</span>
              <button mat-icon-button color="primary" fxFlex="40px">
                <mat-icon (click)="remove(i)">remove_circle</mat-icon>
              </button>
            </div>
            <mat-form-field fxFlex>
              <mat-label ngx-translate>{{ 'NAME' | translate }}</mat-label>
              <input type="text" required matInput [formControl]="$any(control.get('name'))">
            </mat-form-field>
            <mat-form-field fxFlex>
              <mat-label ngx-translate>{{ 'DESCRIPTION' | translate }}</mat-label>
              <input type="text" matInput [formControl]="$any(control.get('description'))">
            </mat-form-field>
            <mat-form-field fxFlex *ngIf="projectTypeEntity.settings.prizeLinkInvoice">
              <mat-label ngx-translate>{{ 'URL' | translate }}</mat-label>
              <input type="text" matInput [formControl]="$any(control.get('url'))">
            </mat-form-field>
            <mat-form-field fxFlex *ngIf="projectTypeEntity.settings.prizeLinkInvoice">
              <mat-label ngx-translate>{{ 'BILL' | translate }}</mat-label>
              <input type="text" matInput [formControl]="$any(control.get('bill'))">
            </mat-form-field>
            <mat-form-field fxFlex *ngIf="projectTypeEntity.settings.prizeCardNumber">
              <mat-label ngx-translate>Номер карты</mat-label>
              <input type="text" required pattern="[0-9]{16,16}" matInput [formControl]="$any(control.get('cardNumber'))">
            </mat-form-field>
            <mat-form-field fxFlex>
              <mat-label ngx-translate>{{ 'COST' | translate }}</mat-label>
              <input type="number" required matInput [formControl]="$any(control.get('cost'))" min="1">
            </mat-form-field>
          </div>
        </form>
        <button mat-button color="primary" (click)="add()">
          <mat-icon>add_circle</mat-icon>
          {{ 'ADD_PRIZE' | translate }}
        </button>
      </ng-container>
      <ng-container *ngIf="!!projectTypeEntity && !projectTypeEntity?.settings.prizeCreateByUser">
        <ng-container *ngIf="projectTypeEntity?.settings?.prizeChoice">
          <mat-selection-list (selectionChange)="setPrize($event)" #selectionList>
            <ng-container *ngFor="let prize of prizes">
              <mat-list-option [value]="prize"
                               checkboxPosition="after"
                               [selected]="isSelected(prize)" #option>
                <div fxLayout="row" style="align-items: center">
                  <strong fxFlex>{{ prize.name }}</strong>
                  <span fxFlex>Стоимость: {{ prize.cost }}</span>
                  <mat-form-field fxFlex *ngIf="prize?.settings?.quantityEdit">
                    <mat-label ngx-translate>{{ 'QUANTITY' | translate }}</mat-label>
                    <input type="number" required matInput
                           (input)="prizeQuantityChange(selectionList.selectedOptions, option)"
                           (click)="stopPropagation($event)"
                           [(ngModel)]="prize.quantity"
                           [min]="prize?.settings?.minQuantity"
                           [max]="prize?.settings?.maxQuantity">
                  </mat-form-field>
                </div>
              </mat-list-option>
              <mat-divider></mat-divider>
            </ng-container>
          </mat-selection-list>
        </ng-container>
        <ng-container *ngIf="!projectTypeEntity?.settings?.prizeChoice">
          <div fxLayout="column wrap" *ngFor="let prize of prizes">
            <mat-form-field fxFlex>
              <mat-label ngx-translate>{{ 'NAME' | translate }}</mat-label>
              <input type="text" matInput readonly [value]="prize.name">
            </mat-form-field>
            <mat-form-field fxFlex>
              <mat-label ngx-translate>{{ 'DESCRIPTION' | translate }}</mat-label>
              <input type="text" matInput readonly [value]="prize.description">
            </mat-form-field>
            <mat-form-field fxFlex>
              <mat-label ngx-translate>{{ 'COST' | translate }}</mat-label>
              <input type="number" matInput
                     [readonly]="!prize?.settings?.costEdit"
                     [formControl]="$any(getPrizeGroup(prize.name)?.get('cost'))"
                     [min]="prize?.settings?.minCost"
                     [max]="prize?.settings?.maxCost">
            </mat-form-field>
            <mat-form-field fxFlex *ngIf="prize?.settings?.quantityEdit">
              <mat-label ngx-translate>{{ 'QUANTITY' | translate }}</mat-label>
              <input type="number" matInput
                     [formControl]="$any(getPrizeGroup(prize.name)?.get('quantity'))"
                     [min]="prize?.settings?.minQuantity"
                     [max]="prize?.settings?.maxQuantity"/>
            </mat-form-field>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  `,
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ProjectPrizesFormComponent),
    },
  ]
})
export class ProjectPrizesFormComponent implements ControlValueAccessor, OnInit, OnDestroy {
  private destroyed$: Subject<void> = new Subject<void>();
  form: FormArray = new FormArray([]);
  loading = true;

  @Output()
  costChange: EventEmitter<number> = new EventEmitter<number>();

  value$: BehaviorSubject<ProjectPrizeDto[]> = new BehaviorSubject<ProjectPrizeDto[]>([]);
  projectType$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  projectTypeEntity$: BehaviorSubject<ProjectTypeDto> = new BehaviorSubject<ProjectTypeDto>(undefined);

  get projectTypeEntity(): ProjectTypeDto {
    return this.projectTypeEntity$.value;
  }

  @Input()
  set projectType(value: string) {
    this.projectType$.next(value);
  }

  get projectType() {
    return this.projectType$.value;
  }

  constructor(private formsService: FormsService,
              private projectTypesService: ProjectTypesService,
              private readonly cdr: ChangeDetectorRef) {
  }

  get prizes(): ProjectTypePrizeDto[] {
    return this.projectTypeEntity
      ?.prizes
      ?.map(prize => {
        if (prize?.settings?.quantityEdit || prize?.settings?.costEdit) {
          const value = this.form?.value?.find(v => v.name === prize.name);
          if (prize?.settings?.quantityEdit) {
            prize.quantity = value?.quantity ?? prize?.quantity ?? 1;
          }
          if (prize?.settings?.costEdit) {
            prize.cost = value?.cost ?? prize.cost;
          }
        }
        return prize;
      }) || [];
  }

  getPrizeGroup(name: string): FormGroup {
    return this.form.controls
      .find((group) => group.get('name').value === name) as FormGroup;
  }

  ngOnInit(): void {
    this.projectType$
      .pipe(
        tap(() => this.loading = true),
        takeUntil(this.destroyed$),
        distinctUntilChanged(),
        filter((projectType) => !!projectType),
        switchMap((projectType) =>
          this.projectTypesService.projectTypeControllerGet({id: projectType})
        )
      )
      .subscribe((projectType) => {
        this.projectTypeEntity$.next(projectType);
        this.loading = false;
      });

    combineLatest([
      this.value$,
      this.projectTypeEntity$
    ])
      .pipe(
        tap(() => this.loading = true),
        takeUntil(this.destroyed$),
        distinctUntilChanged()
      )
      .subscribe(([value, type]) => {
        while (!!this.form?.controls?.length) {
          this.form.removeAt(0);
        }
        if (!!type && !type?.settings?.prizeCreateByUser && !type?.settings?.prizeChoice) {
          const newValue = type?.prizes?.map(prize => {
            if (prize?.settings?.quantityEdit || prize?.settings?.costEdit) {
              const valuePrize = value?.find(v => v.name === prize.name);
              if (prize?.settings?.quantityEdit) {
                prize.quantity = valuePrize?.quantity ?? prize?.quantity ?? 1;
              }
              if (prize?.settings?.costEdit) {
                prize.cost = valuePrize?.cost ?? prize.cost;
              }
            }
            return prize;
          }) || [];
          newValue.forEach(() => this.add());
          this.form.patchValue(newValue);
          this.costChange.next(this.calculateCost(newValue));
        } else {
          if (Array.isArray(value)) {
            value.forEach(() => this.add());
          }
          this.form.patchValue(value);
          this.costChange.next(this.calculateCost(value));
        }
        this.loading = false;
      });

    /**
     * Реакция на изменение формы
     */
    this.form.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.onChange(value);
        this.costChange.next(this.calculateCost(value));
      });
  }

  isSelected(item) {

    if (item?.settings?.quantityEdit && item.quantity > 0) {
      return true;
    }

    const value = this.form.value;
    if (!value || !value.length) {
      return false;
    }

    const itemInValue = value.find(v => v.name === item.name);
    console.log('itemInValue', itemInValue);
    return !!itemInValue;
  }

  setPrize($event: MatSelectionListChange) {
    if (!this?.projectTypeEntity?.settings?.prizeMultiChoice) {
      $event.source.deselectAll();
      $event.options.forEach(o => o.selected = true);
    } else {
      $event.options.forEach(o => {
        if (o.value?.settings?.quantityEdit) {
          o.selected = o.value?.quantity > 0;
        }
      });
    }

    const prizes = $event.source.options
      .filter(o => {
        if (o.value?.settings?.quantityEdit) {
          return o.value.quantity > 0;
        }
        return o.selected;
      })
      .map(o => o.value);
    this.value$.next(prizes);
  }

  calculateCost(value: ProjectPrizeDto[]) {
    return (value || [])
      .map((prize) => {
        return prize.cost * prize.quantity ?? 1;
      })
      .reduce((sum, cost) => sum + cost, 0);
  }

  add() {
    const prizeForm = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      cardNumber: new FormControl('', Validators.required),
      bill: new FormControl('-', Validators.required),
      url: new FormControl('-', Validators.required),
      cost: new FormControl(0, Validators.required),
      item: new FormControl(undefined),
      quantity: new FormControl(1, Validators.required),
    });
    this.form.push(prizeForm);
  }

  remove(index) {
    this.form.removeAt(index);
  }

  ngOnDestroy(): void {
    if (this.destroyed$) {
      this.destroyed$.next();
      this.destroyed$.complete();
    }
  }

  writeValue(obj: any): void {
    this.value$.next(obj);
  }

  onChange(value: any) {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  prizeQuantityChange(selectedOptions: SelectionModel<MatListOption>, option: MatListOption) {
    if (option.value?.quantity == null || option.value?.quantity == undefined) {
      return;
    }

    if (option.value?.settings?.quantityEdit) {

      if (option.value.quantity > 0) {
        option.selected = true;
      } else {
        option.selected = false;
      }
    }

    const values = selectedOptions
      .selected
      .map(option => option.value)
      .map(prize => ({
        ...prize,
        quantity: parseInt(prize.quantity.toString(), 10)
      }))
      .filter(prize => prize.quantity > 0);
    this.value$.next(values);
    this.cdr.markForCheck();
  }

}
