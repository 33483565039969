import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Store} from '@ngxs/store';
import {Auth} from '../../state/auth/auth.state';
import {tap} from 'rxjs/operators';

@Injectable()
export class AccessDeniedInterceptor implements HttpInterceptor {

  constructor(private store: Store) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => event, (err: any) => {
        if (err instanceof HttpErrorResponse && err.status === 401) {
          this.store.dispatch(new Auth.Logout());
        }
      }));
  }
}
