import {Component, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {TasksState} from '../../../../state/tasks/tasks.state';
import {Navigate} from '@ngxs/router-plugin';

@Component({
  selector: 'app-assignment-indicator',
  template: `
    <button
      mat-icon-button
      matTooltip="Tasks"
      (click)="gotoTasks()"
      [style.overflow]="'visible'"
      class="topbar-button-right"
    >
      <mat-icon>assignment</mat-icon>
      <span class="notification-number mat-bg-warn" *ngIf="(count$ | async) > 0">{{count$ | async}}</span>
    </button>
  `,
  styles: []
})
export class AssignmentIndicatorComponent implements OnInit {

  @Select(TasksState.count) count$: Observable<number>;

  constructor(private store: Store) {
  }

  ngOnInit(): void {
  }

  gotoTasks() {
    this.store.dispatch(new Navigate(['/tasks']));
  }
}
