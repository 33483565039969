/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PrizeCreateDto } from '../models/prize-create-dto';
import { PrizeDto } from '../models/prize-dto';

@Injectable({
  providedIn: 'root',
})
export class PrizesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation prizesControllerCreate
   */
  static readonly PrizesControllerCreatePath = '/api/prizes/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `prizesControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  prizesControllerCreate$Response(params: {
      body: PrizeCreateDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PrizesService.PrizesControllerCreatePath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `prizesControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  prizesControllerCreate(params: {
      body: PrizeCreateDto
  }): Observable<void> {

    return this.prizesControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation prizesControllerList
   */
  static readonly PrizesControllerListPath = '/api/prizes/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `prizesControllerList()` instead.
   *
   * This method doesn't expect any request body.
   */
  prizesControllerList$Response(params?: {

  }): Observable<StrictHttpResponse<Array<PrizeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PrizesService.PrizesControllerListPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PrizeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `prizesControllerList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  prizesControllerList(params?: {

  }): Observable<Array<PrizeDto>> {

    return this.prizesControllerList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PrizeDto>>) => r.body as Array<PrizeDto>)
    );
  }

  /**
   * Path part for operation prizesControllerGet
   */
  static readonly PrizesControllerGetPath = '/api/prizes/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `prizesControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  prizesControllerGet$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<PrizeDto>> {

    const rb = new RequestBuilder(this.rootUrl, PrizesService.PrizesControllerGetPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PrizeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `prizesControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  prizesControllerGet(params: {
    id: string;

  }): Observable<PrizeDto> {

    return this.prizesControllerGet$Response(params).pipe(
      map((r: StrictHttpResponse<PrizeDto>) => r.body as PrizeDto)
    );
  }

  /**
   * Path part for operation prizesControllerUpdate
   */
  static readonly PrizesControllerUpdatePath = '/api/prizes/update/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `prizesControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  prizesControllerUpdate$Response(params: {
    id: string;
      body: PrizeCreateDto
  }): Observable<StrictHttpResponse<PrizeDto>> {

    const rb = new RequestBuilder(this.rootUrl, PrizesService.PrizesControllerUpdatePath, 'post');
    if (params) {

      rb.path('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PrizeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `prizesControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  prizesControllerUpdate(params: {
    id: string;
      body: PrizeCreateDto
  }): Observable<PrizeDto> {

    return this.prizesControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<PrizeDto>) => r.body as PrizeDto)
    );
  }

}
