import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, FormArray, FormGroup, NG_VALUE_ACCESSOR, ValidationErrors, ValidatorFn} from '@angular/forms';
import {FormsService} from '../forms.service';
import * as moment from 'moment-timezone';
import {monthsBetweenDates} from '../../../helpers/monthBetweenDates';
import {ProjectTypesService} from '../../../../api/services/project-types.service';
import {skipWhile, takeUntil} from 'rxjs/operators';
import {DialogsService} from '../../../services/dialogs/dialogs.service';
import {FormPrototype} from '../form-prototype';
import {ProductsService} from '../../../../api/services/products.service';
import {SearchSelectItem} from '../../shared-components/search-select/interfaces/search-select-item';

@Component({
  selector: 'app-project-plan-form',
  template: `
    <div *ngIf="emptyValueErrors.length" fxLayout="column">
      <mat-error *ngFor="let error of emptyValueErrors" fxFlex>{{error}}</mat-error>
    </div>
    <form [formGroup]="form" *ngIf="!loading && !emptyValueErrors.length">
      <table class="mdt-table plan-table">
        <tr>
          <th [attr.colspan]="addedMonths + 1" class="text-right">
            <button mat-icon-button color="primary" (click)="addMonth()">
              <mat-icon>add_circle</mat-icon>
            </button>
            <button mat-icon-button color="accent" (click)="removeMonth()" [disabled]="form.controls.length < 2">
              <mat-icon>remove_circle</mat-icon>
            </button>
          </th>
        </tr>
        <tr>
          <th>ПРОДУКТ</th>
          <th *ngFor="let month of form.controls">
            {{month.get('month').value | monthName}} {{month.get('year').value}}
          </th>
        </tr>
        <tr *ngFor="let product of products">
          <td class="text-left margin-333">
            {{getProduct(product)}}
          </td>
          <td *ngFor="let month of form.controls; let i = index" [formGroupName]="i">
            <div formGroupName="products">
              <mat-form-field [formGroupName]="getGroup(i, product)" style="width: 40px">
                <input matInput fxFlex type="number" formControlName="quantity" min="0" #quantityControl/>
                <input type="hidden" formControlName="score" #scoreControl/>
                <mat-hint>{{(scoreControl.value * quantityControl.value) | number: '2.2-2'}}</mat-hint>
              </mat-form-field>
              <span></span>
            </div>
          </td>
        </tr>
        <tr>
          <th>
            Сумма баллов
          </th>
          <th [attr.colspan]="addedMonths">{{sum | number: '2.2-2'}} / {{cost | number: '2.2-2'}}</th>
        </tr>
      </table>
    </form>
  `,
  styles: [`
    .plan-table {
      width: auto;
    }
  `, `
    .plan-table th {
      padding: 0 5px;
    }
  `
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ProjectPlanFormComponent),
    }
  ]
})
export class ProjectPlanFormComponent extends FormPrototype implements OnInit, OnDestroy, ControlValueAccessor {

  @Input()
  products: string[];

  @Input()
  startFrom: Date | string;

  @Input()
  projectType: string;

  @Input()
  cost: number;

  productSelectItems: SearchSelectItem[] = [];

  loading = true;
  emptyValueErrors = [];
  form = new FormArray([], this.costValidator());
  addedMonths = 0;
  sum = 0;

  constructor(private formsService: FormsService,
              private projectTypesService: ProjectTypesService,
              private productsService: ProductsService,
              private dialogsService: DialogsService) {
    super();
  }

  async ngOnInit() {
    this.loading = true;

    this.productsService.productsControllerSelect()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((products) => {
        this.productSelectItems = products;
      });

    this.form.valueChanges
      .pipe(
        skipWhile(() => this.loading),
        takeUntil(this.destroyed$)
      )
      .subscribe((value) => {
        this.updateSum(value);
        this.onChange(value);
        this.value$.next(value);
      });

    if (!!this.startFrom && !!this.projectType && !!this.products && !!this.products.length) {
      this.emptyValueErrors = [];
      await this.generateForm(this.products, this.form.value);
      this.updateSum(this.form.value);
    } else {
      const errors: string[] = [];
      if (!this.projectType) {
        errors.push('Не выбран тип проекта');
      }
      if (!this.startFrom) {
        errors.push('Не установлена дата начала проекта');
      }
      if (!this.products || !this.products.length) {
        errors.push('Не выбраны продукты');
      }
      this.emptyValueErrors = errors;
    }
    this.loading = false;
  }

  getProduct(id: string) {
    return this.productSelectItems.find(item => item.id === id)?.label || 'Загрузка...';
  }

  getGroup(index, product) {
    const productIndex = (this.form.at(index).get('products') as FormArray)
      .controls
      .findIndex((group: FormGroup) => group.get('product').value === product);
    return productIndex;
  }

  async addMonth() {
    this.dialogsService.openLoader();
    this.addedMonths++;
    const date = moment(this.startFrom)
      .tz('Europe/Kiev')
      .startOf('month')
      .add(this.addedMonths - 1, 'month')
      .endOf('month');

    const previousDate = moment(this.startFrom)
      .tz('Europe/Kiev')
      .startOf('month')
      .add(this.addedMonths - 2, 'month')
      .endOf('month');

    const previousMonth = this.form.value
      .find(m => m.month === previousDate.month() && m.year === previousDate.year());

    await this.addMonthToForm(date.year(), date.month(), this.products, previousMonth?.products || []);
    this.dialogsService.closeLoader();
  }

  removeMonth() {
    this.dialogsService.openLoader();
    const index = this.form.controls.length - 1;
    this.addedMonths--;
    this.form.removeAt(index);
    this.onChange(this.form.value);
    this.dialogsService.closeLoader();
  }

  async generateForm(products, value) {
    if (!this.startFrom) {
      return;
    }
    if (!this.projectType) {
      return;
    }
    if (!this.products) {
      return;
    }
    this.dialogsService.openLoader();
    this.loading = true;

    while (this.form.controls.length) {
      this.form.removeAt(0);
    }

    this.addedMonths = 0;
    const startDate = moment(this.startFrom).tz('Europe/Kiev').startOf('month');
    const endDate = value?.reduce((maxDate, month) => {
      const date = moment().tz('Europe/Kiev').year(month.year).month(month.month).endOf('month');
      if (date.isAfter(maxDate)) {
        return date;
      }
      return maxDate;
    }, startDate) || startDate.clone().endOf('month');
    const months = monthsBetweenDates(startDate.toDate(), endDate.toDate());
    for (const period of months) {
      this.addedMonths++;
      const month = value?.find(v => v.month === period.month && v.year === period.year);
      await this.addMonthToForm(period.year, period.month, products, month?.products || []);
    }
    this.loading = false;
    this.dialogsService.closeLoader();
  }

  async addMonthToForm(year: number, month: number, products, oldValue) {
    const scores = await this.projectTypesService.projectTypeControllerScores({
      projectType: this.projectType,
      year,
      month
    }).toPromise();

    const monthForm = this.formsService.projectPlanMonth();
    monthForm.patchValue({year, month});

    for (const product of products) {
      const val = oldValue.find(p => p.product === product);
      const productForm = this.formsService.projectPlanProduct();
      const score = scores.find(s => s.product === product);
      productForm.patchValue({
        product,
        score: score?.score ?? 0,
        quantity: val?.quantity ?? 0
      });
      (monthForm.get('products') as FormArray).push(productForm);
    }
    this.form.push(monthForm);
  }

  updateSum(val) {
    this.sum = val.reduce((sum, month) => {
      return sum + month.products.reduce((prodSum, prod) => {
        return prodSum + ((prod.quantity || 0) * (prod.score || 0));
      }, 0);
    }, 0);
  }

  costValidator(): ValidatorFn {
    return (): ValidationErrors | null => {
      if (this.cost > this.sum) {
        return {planSumLow: true};
      }
      return null;
    };
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
