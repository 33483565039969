import {Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {SelectPrototype} from '../prototypes/select.prototype';
import {switchMap, takeUntil} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {ProjectKindsService} from '../../../../api/services/project-kinds.service';
import {ProductsService} from '../../../../api/services/products.service';

@Component({
  selector: 'app-product-select',
  template: `
    <mat-form-field fxFlex>
      <mat-label translate *ngIf="!multiple && !label">PRODUCT</mat-label>
      <mat-label translate *ngIf="multiple && !label">PRODUCTS</mat-label>
      <mat-label translate *ngIf="label">{{label | translate}}</mat-label>
      <app-search-select
        [formControl]="control"
        [loading]="loading"
        [disabled]="disabled"
        [multiple]="multiple"
        [options]="options">
      </app-search-select>
    </mat-form-field>
  `,
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ProductSelectComponent),
    },
  ],
})
export class ProductSelectComponent extends SelectPrototype implements OnInit, OnChanges {

  @Input()
  label: string = undefined;

  @Input() company: string;
  company$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);


  private _multiple: boolean;

  @Input()
  get multiple() {
    return this._multiple;
  }

  set multiple(req) {
    this._multiple = coerceBooleanProperty(req);
  }

  constructor(private productsService: ProductsService) {
    super();
  }

  ngOnInit() {
    this.company$
      .pipe(
        takeUntil(this.destroyed$),
        switchMap((company) =>
          this.productsService.productsControllerSelect({company})
        )
      )
      .subscribe((options) => {
        this.options = options;
        if (this.options.length === 1) {
          this.control.setValue(this.options[0].id);
          this.disabled = true;
        }
        this.loading = false;
      });
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('company')) {
      this.company$.next(changes['company'].currentValue);
    }
  }

}
