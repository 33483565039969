import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Store} from '@ngxs/store';
import {Auth, AuthState} from './auth.state';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private store: Store) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isAuthenticated = this.store.selectSnapshot(AuthState.isAuthenticated);

    if (isAuthenticated) {
      return true;
    } else {
      this.store.dispatch(new Auth.Logout(state.url));
      return false;
    }
  }
}
