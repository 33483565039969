import {Component, forwardRef, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {BehaviorSubject, combineLatest, Subject} from 'rxjs';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';

export interface ProjectTypeSettings {
  isParallel: boolean;
  isBalanceAllowed: boolean;
  isWithoutPlan: boolean;
  isDivided: boolean;
  prizeCreateByUser: boolean;
  prizeLinkInvoice: boolean;
  prizeCardNumber: boolean;
  prizeMultiple: boolean;
  prizeChoice: boolean;
  prizeMultiChoice: boolean;
  prizeUseStore: boolean;
}

@Component({
  selector: 'app-project-type-settings-form',
  template: `
    <form [formGroup]="form" fxLayout="column">
      <app-checkbox formControlName="isBalanceAllowed" fxFlex>
        Учитывать баллы, доступные на счете
      </app-checkbox>
      <app-checkbox formControlName="isParallel" fxFlex>
        Допускает параллельные проекты
      </app-checkbox>
      <app-checkbox formControlName="isWithoutPlan" fxFlex>
        Не требовать создания плана для этого типа
      </app-checkbox>
      <app-checkbox formControlName="isDivided" fxFlex>
        Разрешить создание совместных проектов
      </app-checkbox>
      <!--        Призы-->
      <app-checkbox formControlName="prizeCreateByUser" fxFlex>
        Призы создаются при создании проекта
      </app-checkbox>
      <app-checkbox formControlName="prizeLinkInvoice" *ngIf="visibility.prizeLinkInvoice" fxFlex>
        В приз можно добавить ссылку или загрузить файл
      </app-checkbox>
      <app-checkbox formControlName="prizeCardNumber" *ngIf="visibility.prizeCardNumber" fxFlex>
        В приз можно добавить номер карты
      </app-checkbox>
      <app-checkbox formControlName="prizeMultiple" fxFlex>
        Можно создать больше одного приза
      </app-checkbox>
      <app-checkbox formControlName="prizeChoice" *ngIf="visibility.prizeChoice" fxFlex>
        Призы выбираются
      </app-checkbox>
      <app-checkbox formControlName="prizeMultiChoice" *ngIf="visibility.prizeMultiChoice" fxFlex>
        Можно выбрать больше одного приза
      </app-checkbox>
      <app-checkbox formControlName="prizeUseStore" *ngIf="visibility.prizeUseStore" fxFlex>
        Призы добавляются со склада
      </app-checkbox>
    </form>
  `,
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ProjectTypeSettingsFormComponent),
    },
  ]
})
export class ProjectTypeSettingsFormComponent implements OnInit, ControlValueAccessor, OnDestroy {

  form: FormGroup = new FormGroup({
    /**
     * Разрешены параллельные проекты
     */
    isParallel: new FormControl(false, Validators.required),
    /**
     * Разрешено использовать баланс счета
     */
    isBalanceAllowed: new FormControl(true, Validators.required),
    /**
     * Не требовать создания плана для этого типа
     */
    isWithoutPlan: new FormControl(false, Validators.required),
    /**
     * Разрешить создание совместных проектов
     */
    isDivided: new FormControl(false, Validators.required),
    /**
     * Призы создаются при создании проекта
     */
    prizeCreateByUser: new FormControl(false, Validators.required),
    /**
     * Можно добавить ссылку или загрузить файл
     * Активен, если prizeCreateByUser = true
     */
    prizeLinkInvoice: new FormControl(false, Validators.required),
    /**
     * Добавление номера карты в приз
     * Активен, если prizeCreateByUser = true
     */
    prizeCardNumber: new FormControl(false, Validators.required),
    /**
     * Можно создать больше одного приза
     */
    prizeMultiple: new FormControl(true, Validators.required),
    /**
     * Призы выбираются
     * Активен, если prizeCreateByUser = false и prizeMultiple = true
     */
    prizeChoice: new FormControl(false, Validators.required),
    /**
     * Можно выбрать больше одного приза
     * Активен, если prizeCreateByUser = false и prizeMultiple = true
     */
    prizeMultiChoice: new FormControl(false, Validators.required),
    /**
     * В призы добавляются позиции со склада
     * Активен, если prizeCreateByUser = false
     */
    prizeUseStore: new FormControl(false, Validators.required),
  });
  value$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  visibility = {
    prizeLinkInvoice: false,
    prizeCardNumber: false,
    prizeChoice: true,
    prizeMultiChoice: true,
    prizeUseStore: true,
  };
  private destroyed$: Subject<void> = new Subject<void>();

  constructor() {
  }


  ngOnInit(): void {
    this.form.get('prizeCreateByUser').valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        distinctUntilChanged()
      )
      .subscribe(prizeCreateByUser => {
        if (!!prizeCreateByUser) {
          this.form.get('prizeChoice').setValue(false);
          this.form.get('prizeUseStore').setValue(false);
        } else {
          this.form.get('prizeLinkInvoice').setValue(false);
          this.form.get('prizeCardNumber').setValue(false);
        }
      });

    combineLatest([
      this.form.get('prizeMultiple').valueChanges,
      this.form.get('prizeCreateByUser').valueChanges
    ])
      .pipe(
        takeUntil(this.destroyed$),
        distinctUntilChanged()
      )
      .subscribe(([prizeMultiple, prizeCreateByUser]) => {
        if (!prizeMultiple || !!prizeCreateByUser) {
          this.form.get('prizeMultiChoice').setValue(false);
        }
      });

    this.form.get('prizeChoice').valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        distinctUntilChanged()
      )
      .subscribe(prizeChoice => {
        if (!prizeChoice) {
          this.form.get('prizeMultiChoice').setValue(false);
        }
      });

    this.form.valueChanges
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(value => {
        this.onChange(value);
        this.calculateFromVisibility(value);
      });
  }

  calculateFromVisibility(value?: any) {
    if (!value) {
      value = this.form.value;
    }

    if (!!value?.prizeCreateByUser) {
      this.visibility.prizeLinkInvoice = true;
      this.visibility.prizeCardNumber = true;
      this.visibility.prizeChoice = false;
      this.visibility.prizeMultiChoice = false;
      this.visibility.prizeUseStore = false;
    } else {
      this.visibility.prizeLinkInvoice = false;
      this.visibility.prizeCardNumber = false;
      this.visibility.prizeUseStore = true;
      this.visibility.prizeChoice = !!value?.prizeMultiple;
      this.visibility.prizeMultiChoice = !!value?.prizeChoice;
    }
  }

  onChange(value: any) {
  }

  onTouched() {
  }

  writeValue(obj: any): void {
    this.form.patchValue(obj, {emitEvent: false});
    this.calculateFromVisibility(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnDestroy(): void {
    if (this.destroyed$) {
      this.destroyed$.next();
      this.destroyed$.complete();
    }
  }
}
