import {DOCUMENT} from '@angular/common';
import {Component, forwardRef, Inject, Input, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {Moment} from 'moment';
import * as moment from 'moment-timezone';
import {YearPickerHeaderComponent} from './year-picker-header.component';
import {CustomDateAdapter} from '../../../services/CustomDateAdapter';

export const MONTH_MODE_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
  },
};

@Component({
  selector: 'app-year-picker',
  template: `
    <mat-form-field>
      <mat-label>{{label | translate}}</mat-label>
      <input matInput
             [matDatepicker]="datepicker"
             type="number"
             [formControl]="_inputCtrl"
             (click)="_openDatepickerOnClick(datepicker)"
             [required]="required"
             readonly>
      <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
      <mat-datepicker #datepicker
                      [touchUi]="touchUi"
                      startView="multi-year"
                      (yearSelected)="_selectedHandler($event,datepicker)"
                      panelClass="year-picker"
                      [calendarHeaderComponent]="customHeader">
      </mat-datepicker>
    </mat-form-field>
  `,
  styleUrls: ['./year-picker.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MONTH_MODE_FORMATS},
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => YearPickerComponent),
      multi: true,
    },
  ],
})
export class YearPickerComponent implements ControlValueAccessor {

  @Input() label = '';
  @Input() required = false;

  @Input() touchUi = false;
  @ViewChild(MatDatepicker, { static: true }) _picker: MatDatepicker<Moment>;
  _inputCtrl: FormControl = new FormControl({value: null, disabled: false});

  customHeader = YearPickerHeaderComponent;

  constructor(@Inject(DOCUMENT) private _document: any) {
  }

  // Function to call when the date changes.
  onChange = (year: number) => {
  }

  // Function to call when the input is touched.
  onTouched = () => {
  }

  writeValue(year: number): void {
    const momentDate = moment().year(year);
    this._inputCtrl.setValue(momentDate, {emitEvent: false});
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  _selectedHandler(chosenDate: Date | Moment, datepicker: MatDatepicker<any>) {
    // as I'm using the focus event to open the calendar, this is necessary
    // so the calendar isn't opened again after a selection.

    if (chosenDate instanceof Date) {
      // chosenDate = moment(chosenDate).tz('Europe/Kiev');
      chosenDate = moment(chosenDate);
    }

    datepicker.disabled = true;

    const year = chosenDate.year();

    this._inputCtrl.setValue(chosenDate);
    this.onChange(year);
    this.onTouched();
    datepicker.close();

    // wait for some time before enabling the calendar again
    setTimeout(() => {
      datepicker.disabled = false;
    }, 700);
  }

  _openDatepickerOnClick(datepicker: MatDatepicker<any>) {
    if (!datepicker.opened) {
      datepicker.open();
    }
  }
}
