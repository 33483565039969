import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-dialog-title',
  template: `
    <div fxLayout="row" fxLayoutAlign="center start">
      <div fxFlex class="elipsis">
        <ng-content></ng-content>
      </div>
      <button mat-icon-button fxFlex="40px" [mat-dialog-close]="undefined">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  `,
  styles: [`
    :host {
      font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
      display: block;
      height: 40px;
    }`
  ]
})
export class DialogTitleComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
