/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { NetworkCreateDto } from '../models/network-create-dto';
import { NetworkDto } from '../models/network-dto';

@Injectable({
  providedIn: 'root',
})
export class NetworksService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation networksControllerCreate
   */
  static readonly NetworksControllerCreatePath = '/api/networks/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `networksControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  networksControllerCreate$Response(params: {
      body: NetworkCreateDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, NetworksService.NetworksControllerCreatePath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `networksControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  networksControllerCreate(params: {
      body: NetworkCreateDto
  }): Observable<void> {

    return this.networksControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation networksControllerList
   */
  static readonly NetworksControllerListPath = '/api/networks/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `networksControllerList()` instead.
   *
   * This method doesn't expect any request body.
   */
  networksControllerList$Response(params: {
    company: string;

  }): Observable<StrictHttpResponse<Array<NetworkDto>>> {

    const rb = new RequestBuilder(this.rootUrl, NetworksService.NetworksControllerListPath, 'get');
    if (params) {

      rb.query('company', params.company, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<NetworkDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `networksControllerList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  networksControllerList(params: {
    company: string;

  }): Observable<Array<NetworkDto>> {

    return this.networksControllerList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<NetworkDto>>) => r.body as Array<NetworkDto>)
    );
  }

  /**
   * Path part for operation networksControllerGet
   */
  static readonly NetworksControllerGetPath = '/api/networks/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `networksControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  networksControllerGet$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<NetworkDto>> {

    const rb = new RequestBuilder(this.rootUrl, NetworksService.NetworksControllerGetPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NetworkDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `networksControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  networksControllerGet(params: {
    id: string;

  }): Observable<NetworkDto> {

    return this.networksControllerGet$Response(params).pipe(
      map((r: StrictHttpResponse<NetworkDto>) => r.body as NetworkDto)
    );
  }

  /**
   * Path part for operation networksControllerUpdate
   */
  static readonly NetworksControllerUpdatePath = '/api/networks/update/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `networksControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  networksControllerUpdate$Response(params: {
    id: string;
      body: NetworkCreateDto
  }): Observable<StrictHttpResponse<NetworkDto>> {

    const rb = new RequestBuilder(this.rootUrl, NetworksService.NetworksControllerUpdatePath, 'post');
    if (params) {

      rb.path('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NetworkDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `networksControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  networksControllerUpdate(params: {
    id: string;
      body: NetworkCreateDto
  }): Observable<NetworkDto> {

    return this.networksControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<NetworkDto>) => r.body as NetworkDto)
    );
  }

  /**
   * Path part for operation networksControllerGetOrganizations
   */
  static readonly NetworksControllerGetOrganizationsPath = '/api/networks/{id}/organizations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `networksControllerGetOrganizations()` instead.
   *
   * This method doesn't expect any request body.
   */
  networksControllerGetOrganizations$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<NetworkDto>> {

    const rb = new RequestBuilder(this.rootUrl, NetworksService.NetworksControllerGetOrganizationsPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NetworkDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `networksControllerGetOrganizations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  networksControllerGetOrganizations(params: {
    id: string;

  }): Observable<NetworkDto> {

    return this.networksControllerGetOrganizations$Response(params).pipe(
      map((r: StrictHttpResponse<NetworkDto>) => r.body as NetworkDto)
    );
  }

}
