/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SearchSelectItemDto } from '../models/search-select-item-dto';
import { Struct } from '../models/struct';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation companyControllerSelect
   */
  static readonly CompanyControllerSelectPath = '/api/company/select';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyControllerSelect()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  companyControllerSelect$Response(params?: {

  }): Observable<StrictHttpResponse<Array<SearchSelectItemDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.CompanyControllerSelectPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SearchSelectItemDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companyControllerSelect$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  companyControllerSelect(params?: {

  }): Observable<Array<SearchSelectItemDto>> {

    return this.companyControllerSelect$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SearchSelectItemDto>>) => r.body as Array<SearchSelectItemDto>)
    );
  }

  /**
   * Path part for operation companyControllerFind
   */
  static readonly CompanyControllerFindPath = '/api/company/find';

  /**
   * Выборка.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyControllerFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyControllerFind$Response(params?: {

    /**
     * Выборка полей
     */
    select?: string;

    /**
     * Сортировка
     */
    sort?: string;

    /**
     * Пропустить N записей
     */
    skip?: number;

    /**
     * Выбрать N записей
     */
    limit?: number;

  }): Observable<StrictHttpResponse<Array<Struct>>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.CompanyControllerFindPath, 'get');
    if (params) {

      rb.query('select', params.select, {});
      rb.query('sort', params.sort, {});
      rb.query('skip', params.skip, {});
      rb.query('limit', params.limit, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Struct>>;
      })
    );
  }

  /**
   * Выборка.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companyControllerFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyControllerFind(params?: {

    /**
     * Выборка полей
     */
    select?: string;

    /**
     * Сортировка
     */
    sort?: string;

    /**
     * Пропустить N записей
     */
    skip?: number;

    /**
     * Выбрать N записей
     */
    limit?: number;

  }): Observable<Array<Struct>> {

    return this.companyControllerFind$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Struct>>) => r.body as Array<Struct>)
    );
  }

}
