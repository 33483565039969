import {ChangeDetectorRef, Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {SelectPrototype} from '../prototypes/select.prototype';
import {takeUntil} from 'rxjs/operators';
import {DivisionsService} from '../../../../api/services/divisions.service';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

@Component({
  selector: 'app-division-select',
  template: `
    <mat-form-field fxFlex>
      <mat-label translate>DIVISION</mat-label>
      <app-search-select
        [formControl]="control"
        [loading]="loading"
        [options]="options"
        [multiple]="multiple"
      >
      </app-search-select>
    </mat-form-field>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DivisionSelectComponent),
    },
  ],
})
export class DivisionSelectComponent extends SelectPrototype implements OnInit, OnChanges {

  @Input() company: string;
  loading = true;
  private _multiple: boolean;

  @Input()
  get multiple() {
    return this._multiple;
  }

  set multiple(req) {
    this._multiple = coerceBooleanProperty(req);
  }

  constructor(private divisionsService: DivisionsService,
              private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  loadData() {
    this.loading = true;
    this.divisionsService
      .divisionsControllerSelectForCompany({company: this.company})
      .pipe(takeUntil(this.destroyed$))
      .subscribe((options) => {
        this.options = options;
        this.loading = false;
        this.cdr.markForCheck();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('company')) {
      this.loadData();
    }
  }

}
