/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PharmacyParticipantExtraPacksRequest } from '../models/pharmacy-participant-extra-packs-request';

@Injectable({
  providedIn: 'root',
})
export class PromotionExtraPacksService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation promotionExtraPacksControllerApproveParticipantPacks
   */
  static readonly PromotionExtraPacksControllerApproveParticipantPacksPath = '/api/promotion-extra-packs/participant/{id}/approve';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promotionExtraPacksControllerApproveParticipantPacks()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotionExtraPacksControllerApproveParticipantPacks$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PromotionExtraPacksService.PromotionExtraPacksControllerApproveParticipantPacksPath, 'post');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promotionExtraPacksControllerApproveParticipantPacks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotionExtraPacksControllerApproveParticipantPacks(params: {
    id: string;

  }): Observable<boolean> {

    return this.promotionExtraPacksControllerApproveParticipantPacks$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation promotionExtraPacksControllerBlockParticipantPacks
   */
  static readonly PromotionExtraPacksControllerBlockParticipantPacksPath = '/api/promotion-extra-packs/participant/{id}/block';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promotionExtraPacksControllerBlockParticipantPacks()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotionExtraPacksControllerBlockParticipantPacks$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PromotionExtraPacksService.PromotionExtraPacksControllerBlockParticipantPacksPath, 'post');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promotionExtraPacksControllerBlockParticipantPacks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotionExtraPacksControllerBlockParticipantPacks(params: {
    id: string;

  }): Observable<boolean> {

    return this.promotionExtraPacksControllerBlockParticipantPacks$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation promotionExtraPacksControllerGetParticipantPacks
   */
  static readonly PromotionExtraPacksControllerGetParticipantPacksPath = '/api/promotion-extra-packs/participant/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promotionExtraPacksControllerGetParticipantPacks()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotionExtraPacksControllerGetParticipantPacks$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<PharmacyParticipantExtraPacksRequest>> {

    const rb = new RequestBuilder(this.rootUrl, PromotionExtraPacksService.PromotionExtraPacksControllerGetParticipantPacksPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PharmacyParticipantExtraPacksRequest>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promotionExtraPacksControllerGetParticipantPacks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotionExtraPacksControllerGetParticipantPacks(params: {
    id: string;

  }): Observable<PharmacyParticipantExtraPacksRequest> {

    return this.promotionExtraPacksControllerGetParticipantPacks$Response(params).pipe(
      map((r: StrictHttpResponse<PharmacyParticipantExtraPacksRequest>) => r.body as PharmacyParticipantExtraPacksRequest)
    );
  }

}
