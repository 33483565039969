import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Params, Router} from '@angular/router';

interface IRoutePart {
  breadcrumb: string;
  params?: Params;
  url: string;
  urlSegments: any[];
}

@Injectable()
export class RoutePartsService {
  public routeParts: IRoutePart[];

  constructor(private router: Router) {
  }

  generateRouteParts(snapshot: ActivatedRouteSnapshot): IRoutePart[] {
    let routeParts = [] as IRoutePart[];
    if (snapshot) {
      if (snapshot.firstChild) {
        routeParts = routeParts.concat(this.generateRouteParts(snapshot.firstChild));
      }
      if (snapshot.url.length) {
        // console.log(snapshot.data['title'], snapshot.url)
        if (this.isSame(snapshot)) {
          routeParts.push({
            breadcrumb: '',
            url: snapshot.url[0].path,
            urlSegments: snapshot.url,
            params: snapshot.params
          });
        } else {
          routeParts.push({
            breadcrumb: snapshot.data['breadcrumb'],
            url: snapshot.url[0].path,
            urlSegments: snapshot.url,
            params: snapshot.params
          });
        }
      }
    }
    return routeParts;
  }

  private isSame(snapshot: ActivatedRouteSnapshot) {
    return snapshot?.parent?.data?.breadcrumb == snapshot?.data?.breadcrumb;
  }
}
