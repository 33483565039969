import {Pipe, PipeTransform} from '@angular/core';
import {freeze} from '../decorators/freeze';

@Pipe({
  name: 'monthName',
})
export class MonthNamePipe implements PipeTransform {

  @freeze()
  transform(value: any, args?: any): any {
    switch (parseInt(value, 10)) {
      case 0:
        return 'Январь';
      case 1:
        return 'Февраль';
      case 2:
        return 'Март';
      case 3:
        return 'Апрель';
      case 4:
        return 'Май';
      case 5:
        return 'Июнь';
      case 6:
        return 'Июль';
      case 7:
        return 'Август';
      case 8:
        return 'Сентябрь';
      case 9:
        return 'Октябрь';
      case 10:
        return 'Ноябрь';
      case 11:
        return 'Декабрь';
      default:
        return 'Ошибка';
    }
  }

}
