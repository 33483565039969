/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DistributionScoreInfoDto } from '../models/distribution-score-info-dto';
import { DividedProjectCreateDto } from '../models/divided-project-create-dto';
import { ProjectAddPaymentDto } from '../models/project-add-payment-dto';
import { ProjectCancelPaymentDto } from '../models/project-cancel-payment-dto';
import { ProjectCreateDto } from '../models/project-create-dto';
import { ProjectDistributionBody } from '../models/project-distribution-body';
import { ProjectDistributionCheckResponse } from '../models/project-distribution-check-response';
import { ProjectDistributionParseBody } from '../models/project-distribution-parse-body';
import { ProjectDistributionResponse } from '../models/project-distribution-response';
import { ProjectDto } from '../models/project-dto';
import { ProjectTransferDto } from '../models/project-transfer-dto';
import { ProjectUpdateDto } from '../models/project-update-dto';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation projectsControllerRecalculate
   */
  static readonly ProjectsControllerRecalculatePath = '/api/projects/recalculate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerRecalculate()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerRecalculate$Response(params: {
    id: string;
    year: number;
    month: number;

  }): Observable<StrictHttpResponse<ProjectDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ProjectsControllerRecalculatePath, 'get');
    if (params) {

      rb.query('id', params.id, {});
      rb.query('year', params.year, {});
      rb.query('month', params.month, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectsControllerRecalculate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerRecalculate(params: {
    id: string;
    year: number;
    month: number;

  }): Observable<ProjectDto> {

    return this.projectsControllerRecalculate$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectDto>) => r.body as ProjectDto)
    );
  }

  /**
   * Path part for operation projectsControllerRecalculateVip
   */
  static readonly ProjectsControllerRecalculateVipPath = '/api/projects/recalculateVip';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerRecalculateVip()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerRecalculateVip$Response(params: {
    id: string;
    year: number;
    month: number;

  }): Observable<StrictHttpResponse<ProjectDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ProjectsControllerRecalculateVipPath, 'get');
    if (params) {

      rb.query('id', params.id, {});
      rb.query('year', params.year, {});
      rb.query('month', params.month, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectsControllerRecalculateVip$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerRecalculateVip(params: {
    id: string;
    year: number;
    month: number;

  }): Observable<ProjectDto> {

    return this.projectsControllerRecalculateVip$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectDto>) => r.body as ProjectDto)
    );
  }

  /**
   * Path part for operation projectsControllerCreate
   */
  static readonly ProjectsControllerCreatePath = '/api/projects/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsControllerCreate$Response(params: {
      body: ProjectCreateDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ProjectsControllerCreatePath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectsControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsControllerCreate(params: {
      body: ProjectCreateDto
  }): Observable<void> {

    return this.projectsControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation projectsControllerUpdate
   */
  static readonly ProjectsControllerUpdatePath = '/api/projects/update/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsControllerUpdate$Response(params: {

    /**
     * ObjectId записи
     */
    id: string;
      body: ProjectUpdateDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ProjectsControllerUpdatePath, 'post');
    if (params) {

      rb.path('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectsControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsControllerUpdate(params: {

    /**
     * ObjectId записи
     */
    id: string;
      body: ProjectUpdateDto
  }): Observable<void> {

    return this.projectsControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation projectsControllerCreateDivided
   */
  static readonly ProjectsControllerCreateDividedPath = '/api/projects/create-divided';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerCreateDivided()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsControllerCreateDivided$Response(params: {
      body: DividedProjectCreateDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ProjectsControllerCreateDividedPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectsControllerCreateDivided$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsControllerCreateDivided(params: {
      body: DividedProjectCreateDto
  }): Observable<void> {

    return this.projectsControllerCreateDivided$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation projectsControllerGenerateDistribution
   */
  static readonly ProjectsControllerGenerateDistributionPath = '/api/projects/generate-dist-file';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerGenerateDistribution()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsControllerGenerateDistribution$Response(params: {
      body: ProjectDistributionBody
  }): Observable<StrictHttpResponse<ProjectDistributionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ProjectsControllerGenerateDistributionPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectDistributionResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectsControllerGenerateDistribution$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsControllerGenerateDistribution(params: {
      body: ProjectDistributionBody
  }): Observable<ProjectDistributionResponse> {

    return this.projectsControllerGenerateDistribution$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectDistributionResponse>) => r.body as ProjectDistributionResponse)
    );
  }

  /**
   * Path part for operation projectsControllerParseDistribution
   */
  static readonly ProjectsControllerParseDistributionPath = '/api/projects/parse-dist-file';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerParseDistribution()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  projectsControllerParseDistribution$Response(params: {
      body: ProjectDistributionParseBody
  }): Observable<StrictHttpResponse<ProjectDistributionCheckResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ProjectsControllerParseDistributionPath, 'post');
    if (params) {


      rb.body(params.body, 'multipart/form-data');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectDistributionCheckResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectsControllerParseDistribution$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  projectsControllerParseDistribution(params: {
      body: ProjectDistributionParseBody
  }): Observable<ProjectDistributionCheckResponse> {

    return this.projectsControllerParseDistribution$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectDistributionCheckResponse>) => r.body as ProjectDistributionCheckResponse)
    );
  }

  /**
   * Path part for operation projectsControllerDistributionInfo
   */
  static readonly ProjectsControllerDistributionInfoPath = '/api/projects/month-scores';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerDistributionInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerDistributionInfo$Response(params: {
    struct: string;
    year: number;
    month: number;

  }): Observable<StrictHttpResponse<Array<DistributionScoreInfoDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ProjectsControllerDistributionInfoPath, 'get');
    if (params) {

      rb.query('struct', params.struct, {});
      rb.query('year', params.year, {});
      rb.query('month', params.month, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DistributionScoreInfoDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectsControllerDistributionInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerDistributionInfo(params: {
    struct: string;
    year: number;
    month: number;

  }): Observable<Array<DistributionScoreInfoDto>> {

    return this.projectsControllerDistributionInfo$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DistributionScoreInfoDto>>) => r.body as Array<DistributionScoreInfoDto>)
    );
  }

  /**
   * Path part for operation projectsControllerCard
   */
  static readonly ProjectsControllerCardPath = '/api/projects/{id}/card';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerCard()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerCard$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<ProjectDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ProjectsControllerCardPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectsControllerCard$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerCard(params: {
    id: string;

  }): Observable<ProjectDto> {

    return this.projectsControllerCard$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectDto>) => r.body as ProjectDto)
    );
  }

  /**
   * Path part for operation projectsControllerValidateProductsNotDeleted
   */
  static readonly ProjectsControllerValidateProductsNotDeletedPath = '/api/projects/{id}/validate-products-not-deleted';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerValidateProductsNotDeleted()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsControllerValidateProductsNotDeleted$Response(params: {
    id: string;
      body: Array<string>
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ProjectsControllerValidateProductsNotDeletedPath, 'post');
    if (params) {

      rb.path('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectsControllerValidateProductsNotDeleted$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsControllerValidateProductsNotDeleted(params: {
    id: string;
      body: Array<string>
  }): Observable<boolean> {

    return this.projectsControllerValidateProductsNotDeleted$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation projectsControllerTransfer
   */
  static readonly ProjectsControllerTransferPath = '/api/projects/{id}/transfer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerTransfer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsControllerTransfer$Response(params: {
    id: string;
      body: ProjectTransferDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ProjectsControllerTransferPath, 'post');
    if (params) {

      rb.path('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectsControllerTransfer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsControllerTransfer(params: {
    id: string;
      body: ProjectTransferDto
  }): Observable<void> {

    return this.projectsControllerTransfer$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation projectsControllerPayment
   */
  static readonly ProjectsControllerPaymentPath = '/api/projects/{id}/payment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerPayment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsControllerPayment$Response(params: {
    id: string;
      body: ProjectAddPaymentDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ProjectsControllerPaymentPath, 'post');
    if (params) {

      rb.path('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectsControllerPayment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsControllerPayment(params: {
    id: string;
      body: ProjectAddPaymentDto
  }): Observable<void> {

    return this.projectsControllerPayment$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation projectsControllerCancelPayment
   */
  static readonly ProjectsControllerCancelPaymentPath = '/api/projects/{id}/cancel-payment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerCancelPayment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsControllerCancelPayment$Response(params: {
    id: string;
      body: ProjectCancelPaymentDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ProjectsControllerCancelPaymentPath, 'post');
    if (params) {

      rb.path('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectsControllerCancelPayment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsControllerCancelPayment(params: {
    id: string;
      body: ProjectCancelPaymentDto
  }): Observable<void> {

    return this.projectsControllerCancelPayment$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation projectsControllerCancel
   */
  static readonly ProjectsControllerCancelPath = '/api/projects/{id}/cancel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerCancel()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerCancel$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ProjectsControllerCancelPath, 'post');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectsControllerCancel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerCancel(params: {
    id: string;

  }): Observable<void> {

    return this.projectsControllerCancel$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation projectsControllerFinalize
   */
  static readonly ProjectsControllerFinalizePath = '/api/projects/{id}/finalize';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerFinalize()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerFinalize$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ProjectsControllerFinalizePath, 'post');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectsControllerFinalize$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerFinalize(params: {
    id: string;

  }): Observable<void> {

    return this.projectsControllerFinalize$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation projectsControllerGet
   */
  static readonly ProjectsControllerGetPath = '/api/projects/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGet$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<ProjectDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ProjectsControllerGetPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectsControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGet(params: {
    id: string;

  }): Observable<ProjectDto> {

    return this.projectsControllerGet$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectDto>) => r.body as ProjectDto)
    );
  }

}
