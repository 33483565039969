/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DivisionStructsSelectsDto } from '../models/division-structs-selects-dto';
import { SearchSelectItemDto } from '../models/search-select-item-dto';
import { StructDto } from '../models/struct-dto';
import { StructUpdateDto } from '../models/struct-update-dto';

@Injectable({
  providedIn: 'root',
})
export class StructService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation structControllerCompaniesSelect
   */
  static readonly StructControllerCompaniesSelectPath = '/api/struct/companies-select';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `structControllerCompaniesSelect()` instead.
   *
   * This method doesn't expect any request body.
   */
  structControllerCompaniesSelect$Response(params?: {

  }): Observable<StrictHttpResponse<Array<SearchSelectItemDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StructService.StructControllerCompaniesSelectPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SearchSelectItemDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `structControllerCompaniesSelect$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  structControllerCompaniesSelect(params?: {

  }): Observable<Array<SearchSelectItemDto>> {

    return this.structControllerCompaniesSelect$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SearchSelectItemDto>>) => r.body as Array<SearchSelectItemDto>)
    );
  }

  /**
   * Path part for operation structControllerScopeSubordinatesSelect
   */
  static readonly StructControllerScopeSubordinatesSelectPath = '/api/struct/scope-subordinates-select';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `structControllerScopeSubordinatesSelect()` instead.
   *
   * This method doesn't expect any request body.
   */
  structControllerScopeSubordinatesSelect$Response(params?: {
    area?: string;
    company?: string;
    withMe?: any;
    ff?: any;

  }): Observable<StrictHttpResponse<Array<SearchSelectItemDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StructService.StructControllerScopeSubordinatesSelectPath, 'get');
    if (params) {

      rb.query('area', params.area, {});
      rb.query('company', params.company, {});
      rb.query('withMe', params.withMe, {});
      rb.query('ff', params.ff, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SearchSelectItemDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `structControllerScopeSubordinatesSelect$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  structControllerScopeSubordinatesSelect(params?: {
    area?: string;
    company?: string;
    withMe?: any;
    ff?: any;

  }): Observable<Array<SearchSelectItemDto>> {

    return this.structControllerScopeSubordinatesSelect$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SearchSelectItemDto>>) => r.body as Array<SearchSelectItemDto>)
    );
  }

  /**
   * Path part for operation structControllerScopeMpSelect
   */
  static readonly StructControllerScopeMpSelectPath = '/api/struct/scope-mp-select';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `structControllerScopeMpSelect()` instead.
   *
   * This method doesn't expect any request body.
   */
  structControllerScopeMpSelect$Response(params?: {

  }): Observable<StrictHttpResponse<Array<SearchSelectItemDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StructService.StructControllerScopeMpSelectPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SearchSelectItemDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `structControllerScopeMpSelect$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  structControllerScopeMpSelect(params?: {

  }): Observable<Array<SearchSelectItemDto>> {

    return this.structControllerScopeMpSelect$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SearchSelectItemDto>>) => r.body as Array<SearchSelectItemDto>)
    );
  }

  /**
   * Path part for operation structControllerPositionsSelect
   */
  static readonly StructControllerPositionsSelectPath = '/api/struct/positions-select';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `structControllerPositionsSelect()` instead.
   *
   * This method doesn't expect any request body.
   */
  structControllerPositionsSelect$Response(params?: {

  }): Observable<StrictHttpResponse<Array<SearchSelectItemDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StructService.StructControllerPositionsSelectPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SearchSelectItemDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `structControllerPositionsSelect$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  structControllerPositionsSelect(params?: {

  }): Observable<Array<SearchSelectItemDto>> {

    return this.structControllerPositionsSelect$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SearchSelectItemDto>>) => r.body as Array<SearchSelectItemDto>)
    );
  }

  /**
   * Path part for operation structControllerGet
   */
  static readonly StructControllerGetPath = '/api/struct';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `structControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  structControllerGet$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<StructDto>> {

    const rb = new RequestBuilder(this.rootUrl, StructService.StructControllerGetPath, 'get');
    if (params) {

      rb.query('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StructDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `structControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  structControllerGet(params: {
    id: string;

  }): Observable<StructDto> {

    return this.structControllerGet$Response(params).pipe(
      map((r: StrictHttpResponse<StructDto>) => r.body as StructDto)
    );
  }

  /**
   * Path part for operation structControllerUpdate
   */
  static readonly StructControllerUpdatePath = '/api/struct';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `structControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  structControllerUpdate$Response(params: {
    id: string;
      body: StructUpdateDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, StructService.StructControllerUpdatePath, 'post');
    if (params) {

      rb.query('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `structControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  structControllerUpdate(params: {
    id: string;
      body: StructUpdateDto
  }): Observable<void> {

    return this.structControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation structControllerCreate
   */
  static readonly StructControllerCreatePath = '/api/struct/new';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `structControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  structControllerCreate$Response(params: {
      body: StructUpdateDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, StructService.StructControllerCreatePath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `structControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  structControllerCreate(params: {
      body: StructUpdateDto
  }): Observable<void> {

    return this.structControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation structControllerMpByDivisionSelects
   */
  static readonly StructControllerMpByDivisionSelectsPath = '/api/struct/mp-by-division';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `structControllerMpByDivisionSelects()` instead.
   *
   * This method doesn't expect any request body.
   */
  structControllerMpByDivisionSelects$Response(params: {
    area: string;
    company: string;
    year?: number;
    month?: number;

  }): Observable<StrictHttpResponse<Array<DivisionStructsSelectsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StructService.StructControllerMpByDivisionSelectsPath, 'get');
    if (params) {

      rb.query('area', params.area, {});
      rb.query('company', params.company, {});
      rb.query('year', params.year, {});
      rb.query('month', params.month, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DivisionStructsSelectsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `structControllerMpByDivisionSelects$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  structControllerMpByDivisionSelects(params: {
    area: string;
    company: string;
    year?: number;
    month?: number;

  }): Observable<Array<DivisionStructsSelectsDto>> {

    return this.structControllerMpByDivisionSelects$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DivisionStructsSelectsDto>>) => r.body as Array<DivisionStructsSelectsDto>)
    );
  }

  /**
   * Path part for operation structControllerTotalScopeSubordinatesSelect
   */
  static readonly StructControllerTotalScopeSubordinatesSelectPath = '/api/struct/scope-total-subordinates-select';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `structControllerTotalScopeSubordinatesSelect()` instead.
   *
   * This method doesn't expect any request body.
   */
  structControllerTotalScopeSubordinatesSelect$Response(params?: {
    area?: string;
    company?: string;

  }): Observable<StrictHttpResponse<Array<SearchSelectItemDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StructService.StructControllerTotalScopeSubordinatesSelectPath, 'get');
    if (params) {

      rb.query('area', params.area, {});
      rb.query('company', params.company, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SearchSelectItemDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `structControllerTotalScopeSubordinatesSelect$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  structControllerTotalScopeSubordinatesSelect(params?: {
    area?: string;
    company?: string;

  }): Observable<Array<SearchSelectItemDto>> {

    return this.structControllerTotalScopeSubordinatesSelect$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SearchSelectItemDto>>) => r.body as Array<SearchSelectItemDto>)
    );
  }

}
