/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MininetCreateDto } from '../models/mininet-create-dto';
import { MininetDto } from '../models/mininet-dto';
import { Organization } from '../models/organization';
import { ValidateOkpoDto } from '../models/validate-okpo-dto';

@Injectable({
  providedIn: 'root',
})
export class MininetsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation mininentsControllerOrganizations
   */
  static readonly MininentsControllerOrganizationsPath = '/api/mininets/organizations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mininentsControllerOrganizations()` instead.
   *
   * This method doesn't expect any request body.
   */
  mininentsControllerOrganizations$Response(params: {
    okpos: any;
    area: string;
    company: string;

  }): Observable<StrictHttpResponse<Array<Organization>>> {

    const rb = new RequestBuilder(this.rootUrl, MininetsService.MininentsControllerOrganizationsPath, 'get');
    if (params) {

      rb.query('okpos', params.okpos, {});
      rb.query('area', params.area, {});
      rb.query('company', params.company, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Organization>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mininentsControllerOrganizations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mininentsControllerOrganizations(params: {
    okpos: any;
    area: string;
    company: string;

  }): Observable<Array<Organization>> {

    return this.mininentsControllerOrganizations$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Organization>>) => r.body as Array<Organization>)
    );
  }

  /**
   * Path part for operation mininentsControllerGet
   */
  static readonly MininentsControllerGetPath = '/api/mininets/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mininentsControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  mininentsControllerGet$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<MininetDto>> {

    const rb = new RequestBuilder(this.rootUrl, MininetsService.MininentsControllerGetPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MininetDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mininentsControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mininentsControllerGet(params: {
    id: string;

  }): Observable<MininetDto> {

    return this.mininentsControllerGet$Response(params).pipe(
      map((r: StrictHttpResponse<MininetDto>) => r.body as MininetDto)
    );
  }

  /**
   * Path part for operation mininentsControllerUpdate
   */
  static readonly MininentsControllerUpdatePath = '/api/mininets/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mininentsControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mininentsControllerUpdate$Response(params: {
    id: string;
    process: boolean;
      body: MininetCreateDto
  }): Observable<StrictHttpResponse<MininetDto>> {

    const rb = new RequestBuilder(this.rootUrl, MininetsService.MininentsControllerUpdatePath, 'post');
    if (params) {

      rb.path('id', params.id, {});
      rb.query('process', params.process, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MininetDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mininentsControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mininentsControllerUpdate(params: {
    id: string;
    process: boolean;
      body: MininetCreateDto
  }): Observable<MininetDto> {

    return this.mininentsControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<MininetDto>) => r.body as MininetDto)
    );
  }

  /**
   * Path part for operation mininentsControllerValidateOkpo
   */
  static readonly MininentsControllerValidateOkpoPath = '/api/mininets/validate-okpo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mininentsControllerValidateOkpo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mininentsControllerValidateOkpo$Response(params: {
      body: ValidateOkpoDto
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MininetsService.MininentsControllerValidateOkpoPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mininentsControllerValidateOkpo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mininentsControllerValidateOkpo(params: {
      body: ValidateOkpoDto
  }): Observable<boolean> {

    return this.mininentsControllerValidateOkpo$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation mininentsControllerValidateMininets
   */
  static readonly MininentsControllerValidateMininetsPath = '/api/mininets/validate-mininets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mininentsControllerValidateMininets()` instead.
   *
   * This method doesn't expect any request body.
   */
  mininentsControllerValidateMininets$Response(params?: {

  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MininetsService.MininentsControllerValidateMininetsPath, 'post');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mininentsControllerValidateMininets$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mininentsControllerValidateMininets(params?: {

  }): Observable<boolean> {

    return this.mininentsControllerValidateMininets$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation mininentsControllerCreate
   */
  static readonly MininentsControllerCreatePath = '/api/mininets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mininentsControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mininentsControllerCreate$Response(params: {
    process: boolean;
      body: MininetCreateDto
  }): Observable<StrictHttpResponse<MininetDto>> {

    const rb = new RequestBuilder(this.rootUrl, MininetsService.MininentsControllerCreatePath, 'post');
    if (params) {

      rb.query('process', params.process, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MininetDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mininentsControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mininentsControllerCreate(params: {
    process: boolean;
      body: MininetCreateDto
  }): Observable<MininetDto> {

    return this.mininentsControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<MininetDto>) => r.body as MininetDto)
    );
  }

}
