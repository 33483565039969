import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  QueryList,
  Self,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {MatFormFieldControl} from '@angular/material/form-field';
import {ControlValueAccessor, FormBuilder, FormControl, NgControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {FocusMonitor} from '@angular/cdk/a11y';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {MatSelect} from '@angular/material/select';
import {SearchSelectItem} from './interfaces/search-select-item';
import {CdkVirtualScrollViewport, ScrollDispatcher} from '@angular/cdk/scrolling';
import {MatOption, MatOptionSelectionChange} from '@angular/material/core';
import {filter} from 'rxjs/operators';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-search-select',
  template: `
    <div fxLayout="row" fxFlex>
      <mat-select fxFlex
                  [required]="required"
                  [multiple]="multiple"
                  [formControl]="formControl"
                  (openedChange)="openChange($event)">
        <mat-select-trigger>
          {{selectedLabel | translate}}
          <span *ngIf="multiple && formControl.value?.length > 1" class="text-muted">
                      (+ ещё {{formControl.value.length - 1}})
                    </span>
        </mat-select-trigger>
        <cdk-virtual-scroll-viewport itemSize="42" minBufferPx="300" maxBufferPx="500" [style.height.px]=5*42>
          <div fxLayout="row" fxLayoutAlign="end">
            <button mat-button color="primary" (click)="selectAll()" *ngIf="multiple">
              {{'ALL' | translate}}
            </button>
            <button mat-button color="primary" (click)="clear()" *ngIf="multiple">
              {{'CLEAR' | translate}}
            </button>
          </div>
          <mat-option *cdkVirtualFor="let option of options; templateCacheSize: 0"
                      (onSelectionChange)="onSelectionChange($event)" [value]="option.id">
            <div style="height: 48px;" fxLayout="column">
              <div style="margin-top: auto">{{option.label | translate}}</div>
              <div fxFlex="30" class="text-muted" style="font-size: 10px; overflow: hidden; white-space: nowrap">
                {{option.description}}
              </div>
            </div>
          </mat-option>
        </cdk-virtual-scroll-viewport>
      </mat-select>
      <mat-spinner fxFlex="17px" *ngIf="loading" diameter="17" matSuffix mode="indeterminate"></mat-spinner>
      <button mat-button color="warn" class="clear" [disabled]="disabled"  matSuffix (click)="clear()" *ngIf="!loading && !empty">
        <mat-icon>clear</mat-icon>
      </button>
      <button mat-button color="primary" class="search" [disabled]="disabled" matSuffix (click)="openSearchDialog()" *ngIf="searchable">
        <mat-icon>search</mat-icon>
      </button>
    </div>

    <ng-template #searchDialog>
      <mat-dialog-content>
        <div fxLayout="row" fxLayoutAlign="end">
          <mat-form-field fxFlex>
            <mat-label>
              {{'SEARCH' | translate}}
            </mat-label>
            <input matInput [formControl]="searchControl">
          </mat-form-field>
          <button mat-button color="primary" (click)="selectAll()" *ngIf="multiple">
            {{'ALL' | translate}}
          </button>
          <button mat-button color="primary" (click)="clear()" *ngIf="multiple">
            {{'CLEAR' | translate}}
          </button>
        </div>

        <mat-selection-list ngDefaultControl>
          <cdk-virtual-scroll-viewport itemSize="48" minBufferPx="300" maxBufferPx="500" [style.height.px]=10*48>
            <mat-list-option checkboxPosition="before" *cdkVirtualFor="let option of filteredOptions; templateCacheSize: 0"
                             [selected]="isOptionSelected(option.id)" [value]="option.id"
                             (click)="onSearchSelectionChange(option.id)">
              <div style="height: 48px;" fxLayout="column">
                <div style="margin-top: auto">{{option.label}}</div>
                <div fxFlex="30" class="text-muted" style="font-size: 10px; overflow: hidden; white-space: nowrap">
                  {{option.description}}
                </div>
                <mat-divider></mat-divider>
              </div>
            </mat-list-option>
          </cdk-virtual-scroll-viewport>
        </mat-selection-list>
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <button mat-raised-button (click)="closeSearchDialog()">OK</button>
      </mat-dialog-actions>
    </ng-template>
  `,
  styles: [
    `
      :host {
        height: 15px;
      }

      .search {
        height: 20px;
        width: 24px;
        line-height: 15px;
        padding: 0 2px;
        min-width: 24px;
      }
    `, `
      .search mat-icon {
        height: 15px;
        width: 15px;
        line-height: 15px;
        font-size: 15px;
      }
    `,
    `
      .clear {
        height: 20px;
        width: 24px;
        line-height: 15px;
        padding: 0 2px;
        min-width: 24px;
      }
    `, `
      .clear mat-icon {
        height: 15px;
        width: 15px;
        line-height: 15px;
        font-size: 15px;
      }
    `
  ],
  providers: [{provide: MatFormFieldControl, useExisting: SearchSelectComponent}],
})
export class SearchSelectComponent<T = string>
  implements OnInit, AfterViewInit, ControlValueAccessor, MatFormFieldControl<T | T[]>, OnDestroy {

  /**
   * ID
   */
  static nextId = 0;

  /**
   * Control type
   */
  @Input() type: 'select' | 'button' = 'select';

  /**
   * Is multi select
   */
  _multiple: boolean;

  /**
   * FormControl
   */
  formControl = new FormControl();

  /**
   * Search control
   */
  searchControl = new FormControl('');

  /**
   * State changes
   */
  stateChanges = new Subject<void>();
  @HostBinding() id = `app-search-select-${SearchSelectComponent.nextId++}`;

  /**
   * Placeholder
   */
  private _placeholder: string;

  /**
   * Focused
   */
  focused: boolean;

  /**
   * Required
   */
  private _required = false;

  /**
   * Disabled
   */
  private _disabled = false;

  /**
   * Control Type
   */
  readonly controlType: string = 'app-search-select';

  /**
   * Described By Ids
   */
  @HostBinding('attr.aria-describedby') describedBy = '';

  /**
   * Loading state
   */
  _loading = false;

  /**
   * Component value
   */
  value: T | T[] | null;

  /**
   * Options
   */
  _options: SearchSelectItem[] = [];

  private searchDialogRef: MatDialogRef<any>;

  /**
   * Search callback
   */
  @Input()
  onSearch: () => Promise<any>;

  @ViewChild(MatSelect, {static: true}) matSelect: MatSelect;

  @ViewChild(CdkVirtualScrollViewport, {static: false}) cdkVirtualScrollViewPort: CdkVirtualScrollViewport;

  @ViewChildren(MatOption) matOptions: QueryList<MatOption>;

  @ViewChild('searchDialog') dialogRef: TemplateRef<SearchSelectComponent>;

  @Input()
  get placeholder() {
    return !!this._placeholder ? this._placeholder : this.selectedLabel;
  }

  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  /**
   * Empty
   */
  get empty() {
    if (this.multiple && Array.isArray(this.value)) {
      return !this.formControl.value.length;
    }
    return !this.formControl.value;
  }

  /**
   * Should Label Float
   */
  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.matSelect.focused || !this.empty;
  }

  @Input()
  get required() {
    return this._required;
  }

  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.formControl.disable() : this.formControl.enable();
    this.matSelect.setDisabledState(this._disabled);
    this.stateChanges.next();
  }

  /**
   * Error state
   */
  get errorState(): boolean {
    return this.formControl.invalid;
  }

  @Input()
  get loading(): boolean {
    return this._loading;
  }

  set loading(value: boolean) {
    this._loading = coerceBooleanProperty(value);
    this._loading ? this.formControl.disable() : this.formControl.enable();
  }

  get searchable(): boolean {
    return !this.loading;
  }

  constructor(@Optional() @Self() public ngControl: NgControl,
              fb: FormBuilder,
              private fm: FocusMonitor,
              private cd: ChangeDetectorRef,
              readonly sd: ScrollDispatcher,
              private matDialog: MatDialog,
              private elRef: ElementRef<HTMLElement>) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }

    fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  @Input()
  get multiple() {
    return this._multiple;
  }

  set multiple(req) {
    this._multiple = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input() set options(val: SearchSelectItem[]) {
    this._options = val;
  }

  get options() {
    if (!!this._options && Array.isArray(this._options)) {
      if (this.multiple && Array.isArray(this.formControl.value)) {
        return [...this._options].sort((a, b) => {
          const aSelected = this.formControl.value.includes(a.id);
          const bSelected = this.formControl.value.includes(b.id);

          if (aSelected && bSelected) {
            return 0;
          } else if (aSelected && !bSelected) {
            return -1;
          } else {
            return 1;
          }
        });
      } else {
        return [...this._options].sort((a, b) => {
          if (a.id === this.formControl.value) {
            return -1;
          }
          return 1;
        });
      }
    } else {
      return this._options;
    }
  }

  get filteredOptions() {
    if (this.searchControl.value && this.searchControl.value !== '') {
      return this.options.filter(o => {
        if (this.multiple) {
          if ((this.formControl.value || []).includes(o.id)) {
            return true;
          }
        } else {
          if (o.id === this.formControl.value) {
            return true;
          }
        }
        return !!o.label.toLowerCase().match(this.searchControl.value.toLowerCase());
      });
    }
    return this.options;
  }

  get selectedLabel() {
    if (this.options && Array.isArray(this.options)) {
      let value;
      if (this.multiple) {
        if (this.formControl.value && this.formControl.value.length) {
          value = this.options.find(o => o.id === this.formControl.value[0]);
        }
      } else {
        value = this.options.find(o => o.id === this.formControl.value);
      }
      return !!value ? value.label : '';
    }
  }

  /**
   * Function to call when the date changes.
   */
  onChange: (_: any) => void = (value: any) => {
  }

  /**
   * Function to call when the input is touched.
   */
  onTouched: () => void = () => {
  }

  ngOnInit(): void {
    this.formControl.valueChanges
      .subscribe(value => {
        this.value = value;
        this.onChange(value);
      });
  }

  onContainerClick(event: MouseEvent): void {
    event.preventDefault();
    if ((event.target as Element).tagName.toLowerCase() === 'mat-icon') {
      return;
    }
    if ((event.target as Element).tagName.toLowerCase() === 'button') {
      return;
    }
    if (this.disabled) {
      return;
    }
    this.cd.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: T | null): void {
    this.value = obj;
    this.formControl.setValue(obj, {emitEvent: false});
    this.stateChanges.next();
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  openSearchDialog() {
    if (this.disabled) {
      return;
    }
    this.searchControl.setValue('');
    this.searchDialogRef = this.matDialog.open(this.dialogRef, {
      minWidth: '70%'
    });
  }

  closeSearchDialog() {
    if (this.searchDialogRef) {
      this.searchDialogRef.close();
      this.searchDialogRef = undefined;
    }
  }

  openChange($event: boolean) {
    if ($event) {
      this.checkOptions();
    }
    this.cdkVirtualScrollViewPort.scrollToIndex(0);
    this.cdkVirtualScrollViewPort.checkViewportSize();
  }


  onSelectionChange(change: MatOptionSelectionChange): void {
    if (!change.isUserInput) {
      return;
    }

    let selected;
    if (this.multiple) {
      selected = [...this.formControl.value] || [];
      const value = change.source.value;
      const idx = selected.indexOf(value);

      if (idx > -1) {
        selected.splice(idx, 1);
      } else {
        selected.push(value);
      }
    } else {
      selected = change.source.value;
    }

    this.formControl.setValue(selected);
  }

  ngAfterViewInit(): void {
    this.sd.scrolled().pipe(
      filter((scrollable) => this.cdkVirtualScrollViewPort === scrollable),
    ).subscribe(() => {
      this.checkOptions();
    });
  }

  private checkOptions(): void {
    if (!this.multiple) {
      return;
    }
    let needUpdate = false;

    const value = this.formControl.value || [];

    this.matOptions.forEach((option) => {
      const selected = value.includes(option.value);

      if (selected && !option.selected) {
        option.select();
        needUpdate = true;
      } else if (!selected && option.selected) {
        option.deselect();
        needUpdate = true;
      }
    });

    if (needUpdate) {
      this.cd.detectChanges();
    }
  }

  selectAll() {
    this.formControl.patchValue(this.options.map(o => o.id));
  }

  clear() {
    if (this.multiple) {
      this.formControl.patchValue([]);
    } else {
      this.formControl.patchValue(null);
    }
  }

  isOptionSelected(id: string) {
    if (this.multiple) {
      return (this.formControl.value || []).includes(id);
    } else {
      return this.formControl.value === id;
    }
  }

  onSearchSelectionChange(value: string): void {

    let selected;
    if (this.multiple) {
      selected = [...this.formControl.value] || [];
      const idx = selected.indexOf(value);

      if (idx > -1) {
        selected.splice(idx, 1);
      } else {
        selected.push(value);
      }
    } else {
      selected = value;
    }

    this.formControl.setValue(selected);
  }

}
