import {DOCUMENT} from '@angular/common';
import {Component, forwardRef, Inject, Input, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import * as moment_ from 'moment-timezone';
import {Moment, unitOfTime} from 'moment-timezone';
import {MonthPickerHeaderComponent} from './month-picker-header.component';
import {CustomDateAdapter} from '../../../services/CustomDateAdapter';

const moment = moment_;

export const MONTH_MODE_FORMATS = {
  parse: {
    dateInput: 'MMMM YYYY',
  },
  display: {
    dateInput: 'MMMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-month-picker',
  template: `
    <mat-form-field>
      <mat-label>{{label | translate}}</mat-label>
      <input matInput
             [matDatepicker]="datepicker"
             [max]="_max" [min]="_min"
             [formControl]="_inputCtrl"
             [matDatepickerFilter]="_customFilter"
             (click)="_openDatepickerOnClick(datepicker)"
             style="text-transform:uppercase"
             [required]="required"
             readonly>
      <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
      <mat-datepicker #datepicker
                      [touchUi]="touchUi"
                      startView="year"
                      (monthSelected)="_monthSelectedHandler($event,datepicker)"
                      panelClass="month-picker"
                      style="width: 245px"
                      [calendarHeaderComponent]="customHeader">
      </mat-datepicker>
    </mat-form-field>
  `,
  styleUrls: ['./month-picker.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MONTH_MODE_FORMATS},
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MonthPickerComponent),
      multi: true,
    }
  ],
})
export class MonthPickerComponent implements ControlValueAccessor {

  @Input() label = '';
  @Input() required = false;
  @Input() endOf = '';
  @Input() startOf = 'month';

  @Input() touchUi = false;
  _customFilter: (d: Moment) => boolean;
  @ViewChild(MatDatepicker, {static: true}) _picker: MatDatepicker<any>;
  _inputCtrl: FormControl = new FormControl({value: null, disabled: false});

  customHeader = MonthPickerHeaderComponent;

  constructor(@Inject(DOCUMENT) private _document: any) {
  }

  _max: Moment;

  @Input() get max(): string | Date {
    return this._max ? this._max.format('MM/YYYY') : undefined;
  }

  set max(max: string | Date) {
    // expect MM to be 1..12 and YYYY > 0
    if (max) {
      const momentDate = typeof max === 'string' ? moment(max, 'MM/YYYY') : moment(max);
      this._max = momentDate.isValid() ? momentDate : undefined;
    }
  }

  _min: Moment;

  @Input() get min(): string | Date {
    return this._min ? this._min.format('MM/YYYY') : undefined;
  }

  set min(min: string | Date) {
    // expect MM to be 1..12 and YYYY > 0
    if (min) {
      const momentDate = typeof min === 'string' ? moment(min, 'MM/YYYY') : moment(min);
      this._min = momentDate.isValid() ? momentDate : undefined;
    }
  }

  // Function to call when the date changes.
  onChange(monthAndYear: Date) {
  }

  // Function to call when the input is touched.
  onTouched() {
  }

  writeValue(date: Date): void {
    if ((date && Object.prototype.toString.call(date) === '[object Date]'
      && this._isMonthEnabled(date.getFullYear(), date.getMonth()))
    ) {
      const momentDate = moment(date).tz('Europe/Kiev');
      if (momentDate.isValid()) {
        this._inputCtrl.setValue(momentDate, {emitEvent: false});
      }
    }
    if (typeof date === 'string') {
      const momentDate = moment(date).tz('Europe/Kiev');
      if (momentDate.isValid()) {
        this._inputCtrl.setValue(momentDate);
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  _monthSelectedHandler(chosenMonthDate: Date | Moment, datepicker: MatDatepicker<any>) {
    // as I'm using the focus event to distribution-open-tenders the calendar, this is necessary
    // so the calendar isn't opened again after a selection.

    if (chosenMonthDate instanceof Date) {
      chosenMonthDate = moment(chosenMonthDate).tz('Europe/Kiev');
    }

    if (this.endOf !== '') {
      chosenMonthDate.endOf(this.endOf as unitOfTime.StartOf);
    } else if (this.startOf !== '') {
      chosenMonthDate.startOf(this.startOf as unitOfTime.StartOf);
    }

    datepicker.disabled = true;
    if (!this._isMonthEnabled(chosenMonthDate.year(), chosenMonthDate.month())) {
      datepicker.close();
      // wait for some time before enabling the calendar again
      setTimeout(() => {
        datepicker.disabled = false;
      });
      return;
    }

    if (this._max && chosenMonthDate.diff(this._max, 'month') > 0) {
      chosenMonthDate = this._max.clone();
    }

    if (this._min && this._min.diff(chosenMonthDate, 'month') > 0) {
      chosenMonthDate = this._min.clone();
    }

    this._inputCtrl.setValue(chosenMonthDate);
    this.onChange(chosenMonthDate.toDate());
    this.onTouched();
    datepicker.close();

    // wait for some time before enabling the calendar again
    setTimeout(() => {
      datepicker.disabled = false;
    }, 700);
  }

  _openDatepickerOnClick(datepicker: MatDatepicker<any>) {
    if (!datepicker.opened) {
      datepicker.open();
    }
  }

  /** Whether the given year is enabled. */
  private _isMonthEnabled(year: number, month: number) {
    if (month === undefined || month === null ||
      this._isYearAndMonthAfterMaxDate(year, month) ||
      this._isYearAndMonthBeforeMinDate(year, month)) {
      return false;
    }

    if (!this._customFilter) {
      return true;
    }

    const firstOfMonth = moment([year, month, 1]);

    // If any date in the month is enabled count the month as enabled.
    for (const date = firstOfMonth; date.month() === month; date.add(1, 'd')) {
      if (this._customFilter(date)) {
        return true;
      }
    }

    return false;
  }

  /**
   * Tests whether the combination month/year is after this.maxDate, considering
   * just the month and year of this.maxDate
   */
  private _isYearAndMonthAfterMaxDate(year: number, month: number) {
    if (this._max) {
      const maxYear = this._max.year();
      const maxMonth = this._max.month();

      return year > maxYear || (year === maxYear && month > maxMonth);
    }

    return false;
  }

  /**
   * Tests whether the combination month/year is before this.minDate, considering
   * just the month and year of this.minDate
   */
  private _isYearAndMonthBeforeMinDate(year: number, month: number) {
    if (this.min) {
      const minYear = this._min.year();
      const minMonth = this._min.month();

      return year < minYear || (year === minYear && month < minMonth);
    }

    return false;
  }
}
