/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LoginResponseDto } from '../models/login-response-dto';
import { SearchSelectItemDto } from '../models/search-select-item-dto';
import { TokenRequestDto } from '../models/token-request-dto';

@Injectable({
  providedIn: 'root',
})
export class GodmodeService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation godmodeControllerSubordinatesSelect
   */
  static readonly GodmodeControllerSubordinatesSelectPath = '/api/godmode/select';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `godmodeControllerSubordinatesSelect()` instead.
   *
   * This method doesn't expect any request body.
   */
  godmodeControllerSubordinatesSelect$Response(params?: {

  }): Observable<StrictHttpResponse<Array<SearchSelectItemDto>>> {

    const rb = new RequestBuilder(this.rootUrl, GodmodeService.GodmodeControllerSubordinatesSelectPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SearchSelectItemDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `godmodeControllerSubordinatesSelect$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  godmodeControllerSubordinatesSelect(params?: {

  }): Observable<Array<SearchSelectItemDto>> {

    return this.godmodeControllerSubordinatesSelect$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SearchSelectItemDto>>) => r.body as Array<SearchSelectItemDto>)
    );
  }

  /**
   * Path part for operation godmodeControllerGetGodmodeToken
   */
  static readonly GodmodeControllerGetGodmodeTokenPath = '/api/godmode/token';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `godmodeControllerGetGodmodeToken()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  godmodeControllerGetGodmodeToken$Response(params: {
      body: TokenRequestDto
  }): Observable<StrictHttpResponse<LoginResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, GodmodeService.GodmodeControllerGetGodmodeTokenPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoginResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `godmodeControllerGetGodmodeToken$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  godmodeControllerGetGodmodeToken(params: {
      body: TokenRequestDto
  }): Observable<LoginResponseDto> {

    return this.godmodeControllerGetGodmodeToken$Response(params).pipe(
      map((r: StrictHttpResponse<LoginResponseDto>) => r.body as LoginResponseDto)
    );
  }

}
