import {Injectable} from '@angular/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import * as moment from 'moment';

@Injectable()
export class CustomDateAdapter extends MomentDateAdapter {
  private timeZone = 'Europe/Kiev';

  constructor(matDateLocale: string) {
    super(matDateLocale);
    moment.tz.setDefault(this.timeZone);
  }

  getFirstDayOfWeek(): number {
    return 1;
  }
}
