/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ChatbotContactInfoDto } from '../models/chatbot-contact-info-dto';
import { ChatbotContactLinkDto } from '../models/chatbot-contact-link-dto';
import { ChatbotUserInfoDto } from '../models/chatbot-user-info-dto';

@Injectable({
  providedIn: 'root',
})
export class ChatbotService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation chatbotControllerGetUserByUsername
   */
  static readonly ChatbotControllerGetUserByUsernamePath = '/api/chatbot/user/by-username';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatbotControllerGetUserByUsername()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatbotControllerGetUserByUsername$Response(params: {
    username: string;

  }): Observable<StrictHttpResponse<ChatbotUserInfoDto>> {

    const rb = new RequestBuilder(this.rootUrl, ChatbotService.ChatbotControllerGetUserByUsernamePath, 'get');
    if (params) {

      rb.query('username', params.username, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChatbotUserInfoDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chatbotControllerGetUserByUsername$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatbotControllerGetUserByUsername(params: {
    username: string;

  }): Observable<ChatbotUserInfoDto> {

    return this.chatbotControllerGetUserByUsername$Response(params).pipe(
      map((r: StrictHttpResponse<ChatbotUserInfoDto>) => r.body as ChatbotUserInfoDto)
    );
  }

  /**
   * Path part for operation chatbotControllerGetUserByMobile
   */
  static readonly ChatbotControllerGetUserByMobilePath = '/api/chatbot/user/by-mobile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatbotControllerGetUserByMobile()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatbotControllerGetUserByMobile$Response(params: {
    mobile: string;

  }): Observable<StrictHttpResponse<ChatbotUserInfoDto>> {

    const rb = new RequestBuilder(this.rootUrl, ChatbotService.ChatbotControllerGetUserByMobilePath, 'get');
    if (params) {

      rb.query('mobile', params.mobile, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChatbotUserInfoDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chatbotControllerGetUserByMobile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatbotControllerGetUserByMobile(params: {
    mobile: string;

  }): Observable<ChatbotUserInfoDto> {

    return this.chatbotControllerGetUserByMobile$Response(params).pipe(
      map((r: StrictHttpResponse<ChatbotUserInfoDto>) => r.body as ChatbotUserInfoDto)
    );
  }

  /**
   * Path part for operation chatbotControllerFindUsersByName
   */
  static readonly ChatbotControllerFindUsersByNamePath = '/api/chatbot/user/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatbotControllerFindUsersByName()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatbotControllerFindUsersByName$Response(params: {
    query: string;

  }): Observable<StrictHttpResponse<Array<ChatbotUserInfoDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ChatbotService.ChatbotControllerFindUsersByNamePath, 'get');
    if (params) {

      rb.query('query', params.query, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ChatbotUserInfoDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chatbotControllerFindUsersByName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatbotControllerFindUsersByName(params: {
    query: string;

  }): Observable<Array<ChatbotUserInfoDto>> {

    return this.chatbotControllerFindUsersByName$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ChatbotUserInfoDto>>) => r.body as Array<ChatbotUserInfoDto>)
    );
  }

  /**
   * Path part for operation chatbotControllerGetUser
   */
  static readonly ChatbotControllerGetUserPath = '/api/chatbot/user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatbotControllerGetUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatbotControllerGetUser$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<ChatbotUserInfoDto>> {

    const rb = new RequestBuilder(this.rootUrl, ChatbotService.ChatbotControllerGetUserPath, 'get');
    if (params) {

      rb.query('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChatbotUserInfoDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chatbotControllerGetUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatbotControllerGetUser(params: {
    id: string;

  }): Observable<ChatbotUserInfoDto> {

    return this.chatbotControllerGetUser$Response(params).pipe(
      map((r: StrictHttpResponse<ChatbotUserInfoDto>) => r.body as ChatbotUserInfoDto)
    );
  }

  /**
   * Path part for operation chatbotControllerGetActiveContactByMobile
   */
  static readonly ChatbotControllerGetActiveContactByMobilePath = '/api/chatbot/contact/by-mobile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatbotControllerGetActiveContactByMobile()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatbotControllerGetActiveContactByMobile$Response(params: {
    mobile: string;
    company: string;

  }): Observable<StrictHttpResponse<ChatbotContactInfoDto>> {

    const rb = new RequestBuilder(this.rootUrl, ChatbotService.ChatbotControllerGetActiveContactByMobilePath, 'get');
    if (params) {

      rb.query('mobile', params.mobile, {});
      rb.query('company', params.company, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChatbotContactInfoDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chatbotControllerGetActiveContactByMobile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatbotControllerGetActiveContactByMobile(params: {
    mobile: string;
    company: string;

  }): Observable<ChatbotContactInfoDto> {

    return this.chatbotControllerGetActiveContactByMobile$Response(params).pipe(
      map((r: StrictHttpResponse<ChatbotContactInfoDto>) => r.body as ChatbotContactInfoDto)
    );
  }

  /**
   * Path part for operation chatbotControllerGetActiveContact
   */
  static readonly ChatbotControllerGetActiveContactPath = '/api/chatbot/contact';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatbotControllerGetActiveContact()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatbotControllerGetActiveContact$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<ChatbotContactInfoDto>> {

    const rb = new RequestBuilder(this.rootUrl, ChatbotService.ChatbotControllerGetActiveContactPath, 'get');
    if (params) {

      rb.query('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChatbotContactInfoDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chatbotControllerGetActiveContact$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatbotControllerGetActiveContact(params: {
    id: string;

  }): Observable<ChatbotContactInfoDto> {

    return this.chatbotControllerGetActiveContact$Response(params).pipe(
      map((r: StrictHttpResponse<ChatbotContactInfoDto>) => r.body as ChatbotContactInfoDto)
    );
  }

  /**
   * Path part for operation chatbotControllerGetContactLinks
   */
  static readonly ChatbotControllerGetContactLinksPath = '/api/chatbot/contact-links';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatbotControllerGetContactLinks()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatbotControllerGetContactLinks$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<Array<ChatbotContactLinkDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ChatbotService.ChatbotControllerGetContactLinksPath, 'get');
    if (params) {

      rb.query('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ChatbotContactLinkDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chatbotControllerGetContactLinks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatbotControllerGetContactLinks(params: {
    id: string;

  }): Observable<Array<ChatbotContactLinkDto>> {

    return this.chatbotControllerGetContactLinks$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ChatbotContactLinkDto>>) => r.body as Array<ChatbotContactLinkDto>)
    );
  }

}
