import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewEncapsulation
} from '@angular/core';
import {ControlValueAccessor, FormControl, NgControl} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as moment_ from 'moment-timezone';
import {Subject, Subscription} from 'rxjs';
import {MatFormFieldControl} from '@angular/material/form-field';
import {FocusMonitor} from '@angular/cdk/a11y';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {KisDateAdapter} from './kis-date.adapter';
import StartOf = moment_.unitOfTime.StartOf;
import {CustomDateAdapter} from '../../../services/CustomDateAdapter';

const moment = moment_;

export const DATE_MODE_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-date-picker',
  template: `
    <mat-datepicker #datePicker></mat-datepicker>
    <input matInput
           [formControl]="dateControl"
           [matDatepicker]="datePicker"
           [placeholder]="placeholder"
           [required]="required"
           (click)="datePicker.open()"
           class="m-0"
           fxFlex
           readonly>
    <button fxFlex="24px" mat-icon-button class="m-0" (click)="datePicker.open()">
      <mat-icon>today</mat-icon>
    </button>
  `,
  // tslint:disable-next-line:use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
  styles: [`
    app-date-picker span {
      opacity: 0;
      transition: opacity 200ms;
    }

    app-date-picker .mat-icon-button {
      height: 24px;
      line-height: 24px;
    }

    app-date-picker.floating span {
      opacity: 1;
    }`
  ],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: DATE_MODE_FORMATS},
    {provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MatFormFieldControl, useExisting: DatePickerComponent}
  ],
})
export class DatePickerComponent implements MatFormFieldControl<Date>, ControlValueAccessor, OnInit, OnDestroy {

  static nextId = 0;

  stateChanges = new Subject<void>();

  @HostBinding() id = `kis-date-picker-${DatePickerComponent.nextId++}`;

  private _placeholder: string;

  focused: boolean;

  private _required = false;

  private _disabled = false;

  controlType = 'app-date-picker';
  autofilled?: boolean;

  @Input() endOf: StartOf | undefined = undefined;
  @Input() startOf: StartOf | undefined = 'day';
  dateControl: FormControl = new FormControl();
  changeSub: Subscription;

  @HostBinding('attr.aria-describedby') describedBy = '';

  @Input()
  get value() {
    return this.dateControl.value;
  }

  set value(val: Date) {
    this.dateControl.setValue(val);
    this.stateChanges.next();
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }

  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  get empty() {
    return !this.dateControl.value;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get required() {
    return this._required;
  }

  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.dateControl.disable() : this.dateControl.enable();
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return !!this.dateControl.errors && !!Object.keys(this.dateControl.errors).length;
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = this.id;
  }

  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elRef.nativeElement.querySelector('input').focus();
    }
  }

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>) {
    // noinspection EqualityComparisonWithCoercionJS
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }

    fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  onChange(date: Date) {
  }

  onTouched() {
  }

  ngOnInit() {
    this.changeSub = this.dateControl.valueChanges
      .subscribe((value) => {

        const dateValue = moment(value).tz('Europe/Kiev');

        if (this.endOf) {
          dateValue.endOf(this.endOf);
        } else if (this.startOf) {
          dateValue.startOf(this.startOf);
        }
        this.onChange(dateValue.toDate());
        this.stateChanges.next();
      });
  }

  ngOnDestroy() {
    if (this.changeSub) {
      this.changeSub.unsubscribe();
    }
    this.fm.stopMonitoring(this.elRef.nativeElement);
    this.stateChanges.next();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.dateControl.disable() : this.dateControl.enable();
  }

  writeValue(obj: any): void {
    this.dateControl.setValue(obj, {emitEvent: false});
  }
}
