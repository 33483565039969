/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Contact } from '../models/contact';
import { DictionaryConfigDto } from '../models/dictionary-config-dto';
import { DictionaryExcelRequestDto } from '../models/dictionary-excel-request-dto';
import { DictionaryExcelResponseDto } from '../models/dictionary-excel-response-dto';
import { DictionaryRequestDto } from '../models/dictionary-request-dto';
import { DictionaryTableDto } from '../models/dictionary-table-dto';
import { SearchSelectItemDto } from '../models/search-select-item-dto';

@Injectable({
  providedIn: 'root',
})
export class ContactsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation contactsControllerSelect
   */
  static readonly ContactsControllerSelectPath = '/api/contacts/select';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactsControllerSelect()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerSelect$Response(params?: {
    company?: string;
    target?: boolean;
    year?: number;
    month?: number;
    projectType?: string;
    organization?: string;
    struct?: any;

  }): Observable<StrictHttpResponse<Array<SearchSelectItemDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ContactsService.ContactsControllerSelectPath, 'get');
    if (params) {

      rb.query('company', params.company, {});
      rb.query('target', params.target, {});
      rb.query('year', params.year, {});
      rb.query('month', params.month, {});
      rb.query('projectType', params.projectType, {});
      rb.query('organization', params.organization, {});
      rb.query('struct', params.struct, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SearchSelectItemDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `contactsControllerSelect$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerSelect(params?: {
    company?: string;
    target?: boolean;
    year?: number;
    month?: number;
    projectType?: string;
    organization?: string;
    struct?: any;

  }): Observable<Array<SearchSelectItemDto>> {

    return this.contactsControllerSelect$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SearchSelectItemDto>>) => r.body as Array<SearchSelectItemDto>)
    );
  }

  /**
   * Path part for operation contactsControllerCount
   */
  static readonly ContactsControllerCountPath = '/api/contacts/count';

  /**
   * Колличество записей.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactsControllerCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerCount$Response(params: {
    date: string;

    /**
     * Компания
     */
    company?: string;

    /**
     * Активна / не активна
     */
    active?: boolean;

  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, ContactsService.ContactsControllerCountPath, 'get');
    if (params) {

      rb.query('date', params.date, {});
      rb.query('company', params.company, {});
      rb.query('active', params.active, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Колличество записей.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `contactsControllerCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerCount(params: {
    date: string;

    /**
     * Компания
     */
    company?: string;

    /**
     * Активна / не активна
     */
    active?: boolean;

  }): Observable<number> {

    return this.contactsControllerCount$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation contactsControllerFind
   */
  static readonly ContactsControllerFindPath = '/api/contacts/find';

  /**
   * Выборка.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactsControllerFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerFind$Response(params?: {

    /**
     * Выборка полей
     */
    select?: string;

    /**
     * Сортировка
     */
    sort?: string;

    /**
     * Пропустить N записей
     */
    skip?: number;

    /**
     * Выбрать N записей
     */
    limit?: number;

    /**
     * Компания
     */
    company?: string;

    /**
     * Активна / не активна
     */
    active?: boolean;

    /**
     * Активна / не активна
     */
    organization?: string;

  }): Observable<StrictHttpResponse<Array<Contact>>> {

    const rb = new RequestBuilder(this.rootUrl, ContactsService.ContactsControllerFindPath, 'get');
    if (params) {

      rb.query('select', params.select, {});
      rb.query('sort', params.sort, {});
      rb.query('skip', params.skip, {});
      rb.query('limit', params.limit, {});
      rb.query('company', params.company, {});
      rb.query('active', params.active, {});
      rb.query('organization', params.organization, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Contact>>;
      })
    );
  }

  /**
   * Выборка.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `contactsControllerFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerFind(params?: {

    /**
     * Выборка полей
     */
    select?: string;

    /**
     * Сортировка
     */
    sort?: string;

    /**
     * Пропустить N записей
     */
    skip?: number;

    /**
     * Выбрать N записей
     */
    limit?: number;

    /**
     * Компания
     */
    company?: string;

    /**
     * Активна / не активна
     */
    active?: boolean;

    /**
     * Активна / не активна
     */
    organization?: string;

  }): Observable<Array<Contact>> {

    return this.contactsControllerFind$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Contact>>) => r.body as Array<Contact>)
    );
  }

  /**
   * Path part for operation contactsControllerGet
   */
  static readonly ContactsControllerGetPath = '/api/contacts/find/{id}';

  /**
   * Получение записи.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactsControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerGet$Response(params: {

    /**
     * ObjectId записи
     */
    id: string;

  }): Observable<StrictHttpResponse<Contact>> {

    const rb = new RequestBuilder(this.rootUrl, ContactsService.ContactsControllerGetPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Contact>;
      })
    );
  }

  /**
   * Получение записи.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `contactsControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerGet(params: {

    /**
     * ObjectId записи
     */
    id: string;

  }): Observable<Contact> {

    return this.contactsControllerGet$Response(params).pipe(
      map((r: StrictHttpResponse<Contact>) => r.body as Contact)
    );
  }

  /**
   * Path part for operation contactsControllerDictionaryConfig
   */
  static readonly ContactsControllerDictionaryConfigPath = '/api/contacts/config';

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactsControllerDictionaryConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerDictionaryConfig$Response(params?: {
    query?: {  };

  }): Observable<StrictHttpResponse<DictionaryConfigDto>> {

    const rb = new RequestBuilder(this.rootUrl, ContactsService.ContactsControllerDictionaryConfigPath, 'get');
    if (params) {

      rb.query('query', params.query, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryConfigDto>;
      })
    );
  }

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `contactsControllerDictionaryConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerDictionaryConfig(params?: {
    query?: {  };

  }): Observable<DictionaryConfigDto> {

    return this.contactsControllerDictionaryConfig$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryConfigDto>) => r.body as DictionaryConfigDto)
    );
  }

  /**
   * Path part for operation contactsControllerDictionaryTable
   */
  static readonly ContactsControllerDictionaryTablePath = '/api/contacts/table';

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactsControllerDictionaryTable()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactsControllerDictionaryTable$Response(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<StrictHttpResponse<DictionaryTableDto>> {

    const rb = new RequestBuilder(this.rootUrl, ContactsService.ContactsControllerDictionaryTablePath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryTableDto>;
      })
    );
  }

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `contactsControllerDictionaryTable$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactsControllerDictionaryTable(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<DictionaryTableDto> {

    return this.contactsControllerDictionaryTable$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryTableDto>) => r.body as DictionaryTableDto)
    );
  }

  /**
   * Path part for operation contactsControllerDictionaryExcel
   */
  static readonly ContactsControllerDictionaryExcelPath = '/api/contacts/export';

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactsControllerDictionaryExcel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactsControllerDictionaryExcel$Response(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<StrictHttpResponse<DictionaryExcelResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ContactsService.ContactsControllerDictionaryExcelPath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryExcelResponseDto>;
      })
    );
  }

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `contactsControllerDictionaryExcel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactsControllerDictionaryExcel(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<DictionaryExcelResponseDto> {

    return this.contactsControllerDictionaryExcel$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryExcelResponseDto>) => r.body as DictionaryExcelResponseDto)
    );
  }

  /**
   * Path part for operation contactsControllerRefresh
   */
  static readonly ContactsControllerRefreshPath = '/api/contacts/refresh';

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactsControllerRefresh()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerRefresh$Response(params?: {

  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ContactsService.ContactsControllerRefreshPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `contactsControllerRefresh$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerRefresh(params?: {

  }): Observable<boolean> {

    return this.contactsControllerRefresh$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
