/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DictionaryConfigDto } from '../models/dictionary-config-dto';
import { DictionaryExcelRequestDto } from '../models/dictionary-excel-request-dto';
import { DictionaryExcelResponseDto } from '../models/dictionary-excel-response-dto';
import { DictionaryRequestDto } from '../models/dictionary-request-dto';
import { DictionaryTableDto } from '../models/dictionary-table-dto';
import { Organization } from '../models/organization';
import { SearchSelectItemDto } from '../models/search-select-item-dto';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation organizationsControllerSelectItems
   */
  static readonly OrganizationsControllerSelectItemsPath = '/api/organizations/select-scope';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationsControllerSelectItems()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  organizationsControllerSelectItems$Response(params?: {
    area?: string;
    company?: string;

  }): Observable<StrictHttpResponse<Array<SearchSelectItemDto>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.OrganizationsControllerSelectItemsPath, 'get');
    if (params) {

      rb.query('area', params.area, {});
      rb.query('company', params.company, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SearchSelectItemDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationsControllerSelectItems$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  organizationsControllerSelectItems(params?: {
    area?: string;
    company?: string;

  }): Observable<Array<SearchSelectItemDto>> {

    return this.organizationsControllerSelectItems$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SearchSelectItemDto>>) => r.body as Array<SearchSelectItemDto>)
    );
  }

  /**
   * Path part for operation organizationsControllerCount
   */
  static readonly OrganizationsControllerCountPath = '/api/organizations/count';

  /**
   * Колличество записей.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationsControllerCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationsControllerCount$Response(params: {
    date: string;

    /**
     * Компания
     */
    company?: string;

    /**
     * Активна / не активна
     */
    active?: boolean;

  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.OrganizationsControllerCountPath, 'get');
    if (params) {

      rb.query('date', params.date, {});
      rb.query('company', params.company, {});
      rb.query('active', params.active, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Колличество записей.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationsControllerCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationsControllerCount(params: {
    date: string;

    /**
     * Компания
     */
    company?: string;

    /**
     * Активна / не активна
     */
    active?: boolean;

  }): Observable<number> {

    return this.organizationsControllerCount$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation organizationsControllerFind
   */
  static readonly OrganizationsControllerFindPath = '/api/organizations/find';

  /**
   * Выборка.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationsControllerFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationsControllerFind$Response(params?: {

    /**
     * Выборка полей
     */
    select?: string;

    /**
     * Сортировка
     */
    sort?: string;

    /**
     * Пропустить N записей
     */
    skip?: number;

    /**
     * Выбрать N записей
     */
    limit?: number;

    /**
     * Компания
     */
    company?: string;

    /**
     * Область
     */
    area?: string;

    /**
     * Активна / не активна
     */
    active?: boolean;

  }): Observable<StrictHttpResponse<Array<Organization>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.OrganizationsControllerFindPath, 'get');
    if (params) {

      rb.query('select', params.select, {});
      rb.query('sort', params.sort, {});
      rb.query('skip', params.skip, {});
      rb.query('limit', params.limit, {});
      rb.query('company', params.company, {});
      rb.query('area', params.area, {});
      rb.query('active', params.active, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Organization>>;
      })
    );
  }

  /**
   * Выборка.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationsControllerFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationsControllerFind(params?: {

    /**
     * Выборка полей
     */
    select?: string;

    /**
     * Сортировка
     */
    sort?: string;

    /**
     * Пропустить N записей
     */
    skip?: number;

    /**
     * Выбрать N записей
     */
    limit?: number;

    /**
     * Компания
     */
    company?: string;

    /**
     * Область
     */
    area?: string;

    /**
     * Активна / не активна
     */
    active?: boolean;

  }): Observable<Array<Organization>> {

    return this.organizationsControllerFind$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Organization>>) => r.body as Array<Organization>)
    );
  }

  /**
   * Path part for operation organizationsControllerGet
   */
  static readonly OrganizationsControllerGetPath = '/api/organizations/find/{id}';

  /**
   * Получение записи.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationsControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationsControllerGet$Response(params: {

    /**
     * ObjectId записи
     */
    id: string;

  }): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.OrganizationsControllerGetPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * Получение записи.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationsControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationsControllerGet(params: {

    /**
     * ObjectId записи
     */
    id: string;

  }): Observable<Organization> {

    return this.organizationsControllerGet$Response(params).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * Path part for operation organizationsControllerDictionaryConfig
   */
  static readonly OrganizationsControllerDictionaryConfigPath = '/api/organizations/config';

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationsControllerDictionaryConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationsControllerDictionaryConfig$Response(params?: {
    query?: {  };

  }): Observable<StrictHttpResponse<DictionaryConfigDto>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.OrganizationsControllerDictionaryConfigPath, 'get');
    if (params) {

      rb.query('query', params.query, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryConfigDto>;
      })
    );
  }

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationsControllerDictionaryConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationsControllerDictionaryConfig(params?: {
    query?: {  };

  }): Observable<DictionaryConfigDto> {

    return this.organizationsControllerDictionaryConfig$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryConfigDto>) => r.body as DictionaryConfigDto)
    );
  }

  /**
   * Path part for operation organizationsControllerDictionaryTable
   */
  static readonly OrganizationsControllerDictionaryTablePath = '/api/organizations/table';

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationsControllerDictionaryTable()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationsControllerDictionaryTable$Response(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<StrictHttpResponse<DictionaryTableDto>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.OrganizationsControllerDictionaryTablePath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryTableDto>;
      })
    );
  }

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationsControllerDictionaryTable$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationsControllerDictionaryTable(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<DictionaryTableDto> {

    return this.organizationsControllerDictionaryTable$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryTableDto>) => r.body as DictionaryTableDto)
    );
  }

  /**
   * Path part for operation organizationsControllerDictionaryExcel
   */
  static readonly OrganizationsControllerDictionaryExcelPath = '/api/organizations/export';

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationsControllerDictionaryExcel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationsControllerDictionaryExcel$Response(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<StrictHttpResponse<DictionaryExcelResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.OrganizationsControllerDictionaryExcelPath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryExcelResponseDto>;
      })
    );
  }

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationsControllerDictionaryExcel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationsControllerDictionaryExcel(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<DictionaryExcelResponseDto> {

    return this.organizationsControllerDictionaryExcel$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryExcelResponseDto>) => r.body as DictionaryExcelResponseDto)
    );
  }

  /**
   * Path part for operation organizationsControllerRefresh
   */
  static readonly OrganizationsControllerRefreshPath = '/api/organizations/refresh';

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationsControllerRefresh()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationsControllerRefresh$Response(params?: {

  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.OrganizationsControllerRefreshPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizationsControllerRefresh$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationsControllerRefresh(params?: {

  }): Observable<boolean> {

    return this.organizationsControllerRefresh$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
