import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'app-rx-total-struct-select',
  template: `
    <div fxLayout="row" fxLayoutAlign="center center">
      <div fxFlex>
        <app-rx-struct-select *ngIf="mode === 'rx'"
                              [formControl]="control"
                              [company]="company"
                              [area]="area">
        </app-rx-struct-select>
        <app-total-struct-select *ngIf="mode === 'total'"
                                 [formControl]="control"
                                 [company]="company"
                                 [area]="area">
        </app-total-struct-select>
      </div>
      <button mat-icon-button (click)="changeMode()">
        <mat-icon>autorenew</mat-icon>
      </button>
    </div>
  `,
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => RxTotalStructSelectComponent),
    },
  ],
})
export class RxTotalStructSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @Input() company: string;
  @Input() area: string;

  mode: 'total' | 'rx' = 'total';

  destroyed$: Subject<void> = new Subject<void>();

  value: any[];
  control: FormControl = new FormControl(undefined, Validators.required);

  constructor() {
  }

  ngOnInit() {
    this.control.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (this.mode === 'total') {
          if (!!value) {
            this.onChange([value]);
          }
        } else {
          if (_.isArray(value)) {
            const filtered = value.filter(v => !!v);
            if (filtered.length > 1) {
              this.onChange(value);
            }
          }
        }
      });
  }

  writeValue(obj: any): void {
    if (_.isArray(obj) && obj.length) {
      if (obj.length === 1) {
        this.mode = 'total';
        this.value = obj[0];
        this.control.patchValue(obj[0], {emitEvent: false});
      } else if (obj.length > 1) {
        this.mode = 'rx';
        this.value = obj;
        this.control.patchValue(obj, {emitEvent: false});
      }
    }
    // if (this.mode == 'total') {
    //   if (_.isArray(obj) && obj.length) {
    //     this.value = obj[0];
    //     this.control.patchValue(obj[0], {emitEvent: false});
    //   } else {
    //     this.value = undefined;
    //     this.control.patchValue(undefined, {emitEvent: false});
    //   }
    // } else {
    //   this.value = obj;
    //   this.control.patchValue(obj, {emitEvent: false});
    // }
  }

  /**
   * Function to call when the date changes.
   * @param {any} value
   */
  private onChange(value: any) {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  private onTouched() {
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
  }

  ngOnDestroy(): void {
    if (this.destroyed$) {
      this.destroyed$.next();
      this.destroyed$.complete();
    }
  }

  changeMode() {
    if (this.mode === 'total') {
      this.mode = 'rx';
      this.control.patchValue(undefined);
    } else {
      this.mode = 'total';
      this.control.patchValue(undefined);
    }
  }
}
