import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {SearchSelectItem} from '../../shared-components/search-select/interfaces/search-select-item';

@Injectable()
export abstract class SelectPrototype implements OnInit, OnDestroy, ControlValueAccessor {

  options: SearchSelectItem[];
  loading = true;
  disabled = false;
  control: FormControl = new FormControl();
  destroyed$: Subject<void> = new Subject<void>();

  /**
   * Function to call when the date changes.
   * @param {any} value
   */
  onChange(value: any) {
  }

  /**
   * Function to call when the input is touched.
   */
  onTouched() {
  }

  ngOnDestroy(): void {
    if (this.destroyed$) {
      this.destroyed$.next();
      this.destroyed$.complete();
    }
  }

  get readonlyValue() {
    const selectedOption = this.options?.find(option => option.id === this.control.value);
    return selectedOption?.label || 'Значение не найдено';
  }

  // tslint:disable-next-line:contextual-lifecycle
  ngOnInit(): void {
    this.control.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.onChange(value);
      });
    this.loading = false;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    this.control.setValue(obj, {emitEvent: false});
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
