import {Action, createSelector, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {TasksService} from '../../api/services/tasks.service';
import {TaskDto} from '../../api/models/task-dto';
import {Auth} from '../auth/auth.state';
import {Navigate} from '@ngxs/router-plugin';
import {CompleteTaskDto} from '../../api/models';
import {Subscription, timer} from 'rxjs';
import {exhaustMap} from 'rxjs/operators';

export interface TasksStateModel {
  records: TaskDto[];
}

export namespace Tasks {
  export class Load {
    static readonly type = '[Tasks] Load';
  }

  export class Open {
    static readonly type = '[Tasks] Open';

    constructor(public taskId: string) {
    }
  }

  export class Close {
    static readonly type = '[Tasks] Close';
  }

  export class Complete {
    static readonly type = '[Tasks] Complete';

    constructor(public taskId: string, public variables: Record<string, any> = {}) {
    }
  }
}


@State<TasksStateModel>({
  name: 'tasks',
  defaults: {
    records: []
  }
})
@Injectable()
export class TasksState {

  private pollSub: Subscription;

  @Selector()
  static count(state: TasksStateModel) {
    return state.records.length;
  }

  @Selector()
  static tasks(state: TasksStateModel) {
    return state.records;
  }

  static task(id: string) {
    return createSelector([TasksState], (state: TasksStateModel) => {
      return state.records.find(task => task.id === id);
    });
  }

  constructor(private service: TasksService) {
  }

  @Action(Tasks.Load)
  async loadTasks(ctx: StateContext<TasksStateModel>) {

    const tasks = await this.service.tasksControllerUserTasks({}).toPromise();

    ctx.patchState({
      records: tasks
    });
  }

  @Action(Tasks.Open)
  open(ctx: StateContext<TasksStateModel>, action: Tasks.Open) {
    const task = ctx.getState().records.find(task => task.id === action.taskId);

    const processName = task.processDefinitionId.indexOf(':') != -1 ?
      task.processDefinitionId.substr(0, task.processDefinitionId.indexOf(':')) :
      task.processDefinitionId;

    const formKey = !!task.formKey && task.formKey.length > 0 ?
      task.formKey :
      'default';

    ctx.dispatch(new Navigate(['tasks', 'forms', processName, formKey, task.id]));
  }

  @Action(Tasks.Close)
  close(ctx: StateContext<TasksStateModel>) {
    return ctx.dispatch(new Navigate(['tasks']));
  }

  @Action(Tasks.Complete)
  complete(ctx: StateContext<TasksStateModel>, action: Tasks.Complete) {

    const dto: CompleteTaskDto = {
      id: action.taskId,
      variables: action.variables
    };

    return this.service
      .tasksControllerCompleteTask({body: dto})
      .toPromise()
      .then(() => {
        const tasks = [...ctx.getState().records];
        const taskIndex = tasks.findIndex(t => t.id === action.taskId);
        tasks.splice(taskIndex, 1);
        ctx.patchState({
          records: tasks
        });
        return ctx.dispatch(new Navigate(['tasks']));
      });
  }

  @Action(Auth.AuthSuccess)
  authSuccess(ctx: StateContext<TasksStateModel>) {

    if (!!this.pollSub) {
      this.pollSub.unsubscribe();
    }

    this.pollSub = timer(0, 10000)
      .pipe(exhaustMap(() => ctx.dispatch(new Tasks.Load())))
      .subscribe();
  }

  @Action(Auth.Logout)
  authLogout() {
    if (!!this.pollSub) {
      this.pollSub.unsubscribe();
    }
  }
}
