import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {MessagesService} from '../../api/services/messages.service';
import {MessageDto} from '../../api/models/message-dto';
import {Auth} from '../auth/auth.state';

export namespace Messages {
  export class Load {
    static readonly type = '[Messages] Load';
  }
}

export type MessageStateDto = MessageDto & { selected?: boolean };

export interface MessagesStateModel {
  records: MessageStateDto[];
}

@State<MessagesStateModel>({
  name: 'messages',
  defaults: {
    records: []
  }
})
@Injectable()
export class MessagesState {

  @Selector()
  static records(state: MessagesStateModel): MessageStateDto[] {
    return state.records || [];
  }

  @Selector()
  static unreadCount(state: MessagesStateModel): number {
    return state.records.filter(m => !m.read).length;
  }

  constructor(private service: MessagesService) {
  }

  @Action(Auth.AuthSuccess)
  authSuccess(ctx: StateContext<MessagesStateModel>) {
    ctx.dispatch(new Messages.Load());
  }

  @Action(Messages.Load)
  async loadMessages(ctx: StateContext<MessagesStateModel>) {
    const messages = await this.service.messagesControllerUserMessages().toPromise();
    ctx.patchState({
      records: messages
    });
  }
}
