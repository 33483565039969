/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MonthScoreDto } from '../models/month-score-dto';
import { ProjectTypeDto } from '../models/project-type-dto';
import { ProjectTypePrizeDto } from '../models/project-type-prize-dto';
import { SearchSelectItemDto } from '../models/search-select-item-dto';
import { TenderTypeDto } from '../models/tender-type-dto';

@Injectable({
  providedIn: 'root',
})
export class ProjectTypesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation projectTypeControllerSelect
   */
  static readonly ProjectTypeControllerSelectPath = '/api/project-types/select';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectTypeControllerSelect()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTypeControllerSelect$Response(params?: {
    area?: string;
    struct?: any;

  }): Observable<StrictHttpResponse<Array<SearchSelectItemDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectTypesService.ProjectTypeControllerSelectPath, 'get');
    if (params) {

      rb.query('area', params.area, {});
      rb.query('struct', params.struct, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SearchSelectItemDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectTypeControllerSelect$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTypeControllerSelect(params?: {
    area?: string;
    struct?: any;

  }): Observable<Array<SearchSelectItemDto>> {

    return this.projectTypeControllerSelect$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SearchSelectItemDto>>) => r.body as Array<SearchSelectItemDto>)
    );
  }

  /**
   * Path part for operation projectTypeControllerPrizes
   */
  static readonly ProjectTypeControllerPrizesPath = '/api/project-types/prizes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectTypeControllerPrizes()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTypeControllerPrizes$Response(params: {
    projectType: string;

  }): Observable<StrictHttpResponse<Array<ProjectTypePrizeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectTypesService.ProjectTypeControllerPrizesPath, 'get');
    if (params) {

      rb.query('projectType', params.projectType, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProjectTypePrizeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectTypeControllerPrizes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTypeControllerPrizes(params: {
    projectType: string;

  }): Observable<Array<ProjectTypePrizeDto>> {

    return this.projectTypeControllerPrizes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProjectTypePrizeDto>>) => r.body as Array<ProjectTypePrizeDto>)
    );
  }

  /**
   * Path part for operation projectTypeControllerScores
   */
  static readonly ProjectTypeControllerScoresPath = '/api/project-types/scores';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectTypeControllerScores()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTypeControllerScores$Response(params: {
    projectType: string;
    year: number;
    month: number;

  }): Observable<StrictHttpResponse<Array<MonthScoreDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectTypesService.ProjectTypeControllerScoresPath, 'get');
    if (params) {

      rb.query('projectType', params.projectType, {});
      rb.query('year', params.year, {});
      rb.query('month', params.month, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MonthScoreDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectTypeControllerScores$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTypeControllerScores(params: {
    projectType: string;
    year: number;
    month: number;

  }): Observable<Array<MonthScoreDto>> {

    return this.projectTypeControllerScores$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MonthScoreDto>>) => r.body as Array<MonthScoreDto>)
    );
  }

  /**
   * Path part for operation projectTypeControllerProductsSelect
   */
  static readonly ProjectTypeControllerProductsSelectPath = '/api/project-types/product-select';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectTypeControllerProductsSelect()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTypeControllerProductsSelect$Response(params: {
    struct: string;
    projectType: string;
    contact: string;
    startFrom: string;

  }): Observable<StrictHttpResponse<Array<SearchSelectItemDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectTypesService.ProjectTypeControllerProductsSelectPath, 'get');
    if (params) {

      rb.query('struct', params.struct, {});
      rb.query('projectType', params.projectType, {});
      rb.query('contact', params.contact, {});
      rb.query('startFrom', params.startFrom, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SearchSelectItemDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectTypeControllerProductsSelect$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTypeControllerProductsSelect(params: {
    struct: string;
    projectType: string;
    contact: string;
    startFrom: string;

  }): Observable<Array<SearchSelectItemDto>> {

    return this.projectTypeControllerProductsSelect$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SearchSelectItemDto>>) => r.body as Array<SearchSelectItemDto>)
    );
  }

  /**
   * Path part for operation projectTypeControllerTypeProductsSelect
   */
  static readonly ProjectTypeControllerTypeProductsSelectPath = '/api/project-types/type-products';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectTypeControllerTypeProductsSelect()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTypeControllerTypeProductsSelect$Response(params: {
    projectType: string;

  }): Observable<StrictHttpResponse<Array<SearchSelectItemDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectTypesService.ProjectTypeControllerTypeProductsSelectPath, 'get');
    if (params) {

      rb.query('projectType', params.projectType, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SearchSelectItemDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectTypeControllerTypeProductsSelect$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTypeControllerTypeProductsSelect(params: {
    projectType: string;

  }): Observable<Array<SearchSelectItemDto>> {

    return this.projectTypeControllerTypeProductsSelect$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SearchSelectItemDto>>) => r.body as Array<SearchSelectItemDto>)
    );
  }

  /**
   * Path part for operation projectTypeControllerIsContactAllowed
   */
  static readonly ProjectTypeControllerIsContactAllowedPath = '/api/project-types/is-contact-allowed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectTypeControllerIsContactAllowed()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTypeControllerIsContactAllowed$Response(params: {
    type: string;
    contact: string;

  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectTypesService.ProjectTypeControllerIsContactAllowedPath, 'get');
    if (params) {

      rb.query('type', params.type, {});
      rb.query('contact', params.contact, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectTypeControllerIsContactAllowed$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTypeControllerIsContactAllowed(params: {
    type: string;
    contact: string;

  }): Observable<boolean> {

    return this.projectTypeControllerIsContactAllowed$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation projectTypeControllerIsStartDateAllowed
   */
  static readonly ProjectTypeControllerIsStartDateAllowedPath = '/api/project-types/is-start-date-allowed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectTypeControllerIsStartDateAllowed()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTypeControllerIsStartDateAllowed$Response(params: {
    type: string;
    startDate: string;

  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectTypesService.ProjectTypeControllerIsStartDateAllowedPath, 'get');
    if (params) {

      rb.query('type', params.type, {});
      rb.query('startDate', params.startDate, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectTypeControllerIsStartDateAllowed$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTypeControllerIsStartDateAllowed(params: {
    type: string;
    startDate: string;

  }): Observable<boolean> {

    return this.projectTypeControllerIsStartDateAllowed$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation projectTypeControllerGet
   */
  static readonly ProjectTypeControllerGetPath = '/api/project-types/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectTypeControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTypeControllerGet$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<ProjectTypeDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectTypesService.ProjectTypeControllerGetPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectTypeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectTypeControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTypeControllerGet(params: {
    id: string;

  }): Observable<ProjectTypeDto> {

    return this.projectTypeControllerGet$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectTypeDto>) => r.body as ProjectTypeDto)
    );
  }

  /**
   * Path part for operation projectTypeControllerUpdate
   */
  static readonly ProjectTypeControllerUpdatePath = '/api/project-types/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectTypeControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectTypeControllerUpdate$Response(params: {
    id: string;
      body: ProjectTypeDto
  }): Observable<StrictHttpResponse<TenderTypeDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectTypesService.ProjectTypeControllerUpdatePath, 'put');
    if (params) {

      rb.path('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenderTypeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectTypeControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectTypeControllerUpdate(params: {
    id: string;
      body: ProjectTypeDto
  }): Observable<TenderTypeDto> {

    return this.projectTypeControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<TenderTypeDto>) => r.body as TenderTypeDto)
    );
  }

  /**
   * Path part for operation projectTypeControllerCreate
   */
  static readonly ProjectTypeControllerCreatePath = '/api/project-types/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectTypeControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectTypeControllerCreate$Response(params: {
      body: ProjectTypeDto
  }): Observable<StrictHttpResponse<TenderTypeDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectTypesService.ProjectTypeControllerCreatePath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenderTypeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectTypeControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectTypeControllerCreate(params: {
      body: ProjectTypeDto
  }): Observable<TenderTypeDto> {

    return this.projectTypeControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<TenderTypeDto>) => r.body as TenderTypeDto)
    );
  }

  /**
   * Path part for operation projectTypeControllerActivate
   */
  static readonly ProjectTypeControllerActivatePath = '/api/project-types/{id}/activate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectTypeControllerActivate()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTypeControllerActivate$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<TenderTypeDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectTypesService.ProjectTypeControllerActivatePath, 'put');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenderTypeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectTypeControllerActivate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTypeControllerActivate(params: {
    id: string;

  }): Observable<TenderTypeDto> {

    return this.projectTypeControllerActivate$Response(params).pipe(
      map((r: StrictHttpResponse<TenderTypeDto>) => r.body as TenderTypeDto)
    );
  }

  /**
   * Path part for operation projectTypeControllerDeactivate
   */
  static readonly ProjectTypeControllerDeactivatePath = '/api/project-types/{id}/deactivate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectTypeControllerDeactivate()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTypeControllerDeactivate$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<TenderTypeDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectTypesService.ProjectTypeControllerDeactivatePath, 'put');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenderTypeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectTypeControllerDeactivate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTypeControllerDeactivate(params: {
    id: string;

  }): Observable<TenderTypeDto> {

    return this.projectTypeControllerDeactivate$Response(params).pipe(
      map((r: StrictHttpResponse<TenderTypeDto>) => r.body as TenderTypeDto)
    );
  }

  /**
   * Path part for operation projectTypeControllerUpdateInContentum
   */
  static readonly ProjectTypeControllerUpdateInContentumPath = '/api/project-types/updateInContentum';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectTypeControllerUpdateInContentum()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTypeControllerUpdateInContentum$Response(params: {
    projectTypeId: string;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectTypesService.ProjectTypeControllerUpdateInContentumPath, 'post');
    if (params) {

      rb.query('projectTypeId', params.projectTypeId, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectTypeControllerUpdateInContentum$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTypeControllerUpdateInContentum(params: {
    projectTypeId: string;

  }): Observable<void> {

    return this.projectTypeControllerUpdateInContentum$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
