import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BehaviorSubject, Subject} from 'rxjs';
import {Store} from '@ngxs/store';
import {Labels, LabelsState} from '../../../../state/labels/labels.state';
import {filter, startWith, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-info-container',
  template: `
    <div>
      <strong>{{name | translate}}</strong>
      <p class="content" [attr.title]="info">
        <ng-content></ng-content>
      </p>
    </div>
  `,
  styles: [`
    .content {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }`, `
    :host {
      margin: 0 10px;
    }
  `
  ]
})
export class InfoContainerComponent {
  @Input() name;
  @Input() info;
}
