import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {RelativeTimePipe} from './relative-time.pipe';
import {ExcerptPipe} from './excerpt.pipe';
import {GetValueByKeyPipe} from './get-value-by-key.pipe';
import {MomentDifferencePipe} from './moment-difference.pipe';
import {KeysPipe} from './keys.pipe';
import {YesNoPipe} from './yes-no.pipe';
import {MonthNamePipe} from './month-name.pipe';

const pipes = [
  RelativeTimePipe,
  ExcerptPipe,
  GetValueByKeyPipe,
  MomentDifferencePipe,
  KeysPipe,
  YesNoPipe,
  MonthNamePipe
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: pipes,
  exports: pipes
})
export class SharedPipesModule {
}
