/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { QlikAreaDto } from '../models/qlik-area-dto';
import { QlikBaseProductDto } from '../models/qlik-base-product-dto';
import { QlikCoefficientDto } from '../models/qlik-coefficient-dto';
import { QlikOrganizationDto } from '../models/qlik-organization-dto';
import { QlikOrganizationSaleDto } from '../models/qlik-organization-sale-dto';
import { QlikPriceDto } from '../models/qlik-price-dto';
import { QlikProductDto } from '../models/qlik-product-dto';
import { QlikProductPlanDto } from '../models/qlik-product-plan-dto';
import { QlikSaleDivisionDto } from '../models/qlik-sale-division-dto';
import { QlikScoreDto } from '../models/qlik-score-dto';
import { QlikStructDto } from '../models/qlik-struct-dto';
import { QlikStructHistoryDto } from '../models/qlik-struct-history-dto';
import { QlikStructPlanDto } from '../models/qlik-struct-plan-dto';
import { QlikStructSaleDto } from '../models/qlik-struct-sale-dto';
import { QlikUserDto } from '../models/qlik-user-dto';

@Injectable({
  providedIn: 'root',
})
export class QlikService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation qlikControllerEmployee
   */
  static readonly QlikControllerEmployeePath = '/api/qlick/employee';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerEmployee()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerEmployee$Response(params?: {

  }): Observable<StrictHttpResponse<Array<QlikUserDto>>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerEmployeePath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QlikUserDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerEmployee$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerEmployee(params?: {

  }): Observable<Array<QlikUserDto>> {

    return this.qlikControllerEmployee$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QlikUserDto>>) => r.body as Array<QlikUserDto>)
    );
  }

  /**
   * Path part for operation qlikControllerStruct
   */
  static readonly QlikControllerStructPath = '/api/qlick/struct';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerStruct()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerStruct$Response(params?: {

  }): Observable<StrictHttpResponse<Array<QlikStructDto>>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerStructPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QlikStructDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerStruct$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerStruct(params?: {

  }): Observable<Array<QlikStructDto>> {

    return this.qlikControllerStruct$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QlikStructDto>>) => r.body as Array<QlikStructDto>)
    );
  }

  /**
   * Path part for operation qlikControllerStructHistory
   */
  static readonly QlikControllerStructHistoryPath = '/api/qlick/struct-history/{year}/{month}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerStructHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerStructHistory$Response(params: {
    year: number;
    month: number;

  }): Observable<StrictHttpResponse<Array<QlikStructHistoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerStructHistoryPath, 'get');
    if (params) {

      rb.path('year', params.year, {});
      rb.path('month', params.month, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QlikStructHistoryDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerStructHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerStructHistory(params: {
    year: number;
    month: number;

  }): Observable<Array<QlikStructHistoryDto>> {

    return this.qlikControllerStructHistory$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QlikStructHistoryDto>>) => r.body as Array<QlikStructHistoryDto>)
    );
  }

  /**
   * Path part for operation qlikControllerAreas
   */
  static readonly QlikControllerAreasPath = '/api/qlick/areas';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerAreas()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerAreas$Response(params?: {

  }): Observable<StrictHttpResponse<Array<QlikAreaDto>>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerAreasPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QlikAreaDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerAreas$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerAreas(params?: {

  }): Observable<Array<QlikAreaDto>> {

    return this.qlikControllerAreas$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QlikAreaDto>>) => r.body as Array<QlikAreaDto>)
    );
  }

  /**
   * Path part for operation qlikControllerProducts
   */
  static readonly QlikControllerProductsPath = '/api/qlick/products';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerProducts()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerProducts$Response(params?: {

  }): Observable<StrictHttpResponse<Array<QlikProductDto>>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerProductsPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QlikProductDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerProducts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerProducts(params?: {

  }): Observable<Array<QlikProductDto>> {

    return this.qlikControllerProducts$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QlikProductDto>>) => r.body as Array<QlikProductDto>)
    );
  }

  /**
   * Path part for operation qlikControllerDivisions
   */
  static readonly QlikControllerDivisionsPath = '/api/qlick/divisions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerDivisions()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerDivisions$Response(params?: {

  }): Observable<StrictHttpResponse<Array<QlikSaleDivisionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerDivisionsPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QlikSaleDivisionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerDivisions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerDivisions(params?: {

  }): Observable<Array<QlikSaleDivisionDto>> {

    return this.qlikControllerDivisions$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QlikSaleDivisionDto>>) => r.body as Array<QlikSaleDivisionDto>)
    );
  }

  /**
   * Path part for operation qlikControllerBaseProducts
   */
  static readonly QlikControllerBaseProductsPath = '/api/qlick/base-products';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerBaseProducts()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerBaseProducts$Response(params?: {

  }): Observable<StrictHttpResponse<Array<QlikBaseProductDto>>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerBaseProductsPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QlikBaseProductDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerBaseProducts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerBaseProducts(params?: {

  }): Observable<Array<QlikBaseProductDto>> {

    return this.qlikControllerBaseProducts$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QlikBaseProductDto>>) => r.body as Array<QlikBaseProductDto>)
    );
  }

  /**
   * Path part for operation qlikControllerOrganizations
   */
  static readonly QlikControllerOrganizationsPath = '/api/qlick/organizations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerOrganizations()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerOrganizations$Response(params?: {

  }): Observable<StrictHttpResponse<Array<QlikOrganizationDto>>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerOrganizationsPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QlikOrganizationDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerOrganizations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerOrganizations(params?: {

  }): Observable<Array<QlikOrganizationDto>> {

    return this.qlikControllerOrganizations$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QlikOrganizationDto>>) => r.body as Array<QlikOrganizationDto>)
    );
  }

  /**
   * Path part for operation qlikControllerPrices
   */
  static readonly QlikControllerPricesPath = '/api/qlick/prices/{year}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerPrices()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerPrices$Response(params: {
    year: number;

  }): Observable<StrictHttpResponse<Array<QlikPriceDto>>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerPricesPath, 'get');
    if (params) {

      rb.path('year', params.year, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QlikPriceDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerPrices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerPrices(params: {
    year: number;

  }): Observable<Array<QlikPriceDto>> {

    return this.qlikControllerPrices$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QlikPriceDto>>) => r.body as Array<QlikPriceDto>)
    );
  }

  /**
   * Path part for operation qlikControllerCoefficients
   */
  static readonly QlikControllerCoefficientsPath = '/api/qlick/coefficients/{year}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerCoefficients()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerCoefficients$Response(params: {
    year: number;

  }): Observable<StrictHttpResponse<Array<QlikCoefficientDto>>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerCoefficientsPath, 'get');
    if (params) {

      rb.path('year', params.year, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QlikCoefficientDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerCoefficients$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerCoefficients(params: {
    year: number;

  }): Observable<Array<QlikCoefficientDto>> {

    return this.qlikControllerCoefficients$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QlikCoefficientDto>>) => r.body as Array<QlikCoefficientDto>)
    );
  }

  /**
   * Path part for operation qlikControllerScores
   */
  static readonly QlikControllerScoresPath = '/api/qlick/scores/{year}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerScores()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerScores$Response(params: {
    year: number;

  }): Observable<StrictHttpResponse<Array<QlikScoreDto>>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerScoresPath, 'get');
    if (params) {

      rb.path('year', params.year, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QlikScoreDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerScores$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerScores(params: {
    year: number;

  }): Observable<Array<QlikScoreDto>> {

    return this.qlikControllerScores$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QlikScoreDto>>) => r.body as Array<QlikScoreDto>)
    );
  }

  /**
   * Path part for operation qlikControllerSales
   */
  static readonly QlikControllerSalesPath = '/api/qlick/sales/{year}/{month}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerSales()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerSales$Response(params: {
    year: number;
    month: number;

  }): Observable<StrictHttpResponse<Array<QlikOrganizationSaleDto>>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerSalesPath, 'get');
    if (params) {

      rb.path('year', params.year, {});
      rb.path('month', params.month, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QlikOrganizationSaleDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerSales$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerSales(params: {
    year: number;
    month: number;

  }): Observable<Array<QlikOrganizationSaleDto>> {

    return this.qlikControllerSales$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QlikOrganizationSaleDto>>) => r.body as Array<QlikOrganizationSaleDto>)
    );
  }

  /**
   * Path part for operation qlikControllerPlans
   */
  static readonly QlikControllerPlansPath = '/api/qlick/plans/{year}/{month}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerPlans()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerPlans$Response(params: {
    year: number;
    month: number;

  }): Observable<StrictHttpResponse<Array<QlikProductPlanDto>>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerPlansPath, 'get');
    if (params) {

      rb.path('year', params.year, {});
      rb.path('month', params.month, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QlikProductPlanDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerPlans$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerPlans(params: {
    year: number;
    month: number;

  }): Observable<Array<QlikProductPlanDto>> {

    return this.qlikControllerPlans$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QlikProductPlanDto>>) => r.body as Array<QlikProductPlanDto>)
    );
  }

  /**
   * Path part for operation qlikControllerStructSales
   */
  static readonly QlikControllerStructSalesPath = '/api/qlick/struct-sales/{year}/{month}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerStructSales()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerStructSales$Response(params: {
    year: number;
    month: number;

  }): Observable<StrictHttpResponse<Array<QlikStructSaleDto>>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerStructSalesPath, 'get');
    if (params) {

      rb.path('year', params.year, {});
      rb.path('month', params.month, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QlikStructSaleDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerStructSales$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerStructSales(params: {
    year: number;
    month: number;

  }): Observable<Array<QlikStructSaleDto>> {

    return this.qlikControllerStructSales$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QlikStructSaleDto>>) => r.body as Array<QlikStructSaleDto>)
    );
  }

  /**
   * Path part for operation qlikControllerStructPlans
   */
  static readonly QlikControllerStructPlansPath = '/api/qlick/struct-plans/{year}/{month}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerStructPlans()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerStructPlans$Response(params: {
    year: number;
    month: number;

  }): Observable<StrictHttpResponse<Array<QlikStructPlanDto>>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerStructPlansPath, 'get');
    if (params) {

      rb.path('year', params.year, {});
      rb.path('month', params.month, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QlikStructPlanDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerStructPlans$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerStructPlans(params: {
    year: number;
    month: number;

  }): Observable<Array<QlikStructPlanDto>> {

    return this.qlikControllerStructPlans$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QlikStructPlanDto>>) => r.body as Array<QlikStructPlanDto>)
    );
  }

  /**
   * Path part for operation qlikControllerPrimarySales
   */
  static readonly QlikControllerPrimarySalesPath = '/api/qlick/primary-sales/{year}/{month}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerPrimarySales()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerPrimarySales$Response(params: {
    year: number;
    month: number;

  }): Observable<StrictHttpResponse<Array<QlikStructPlanDto>>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerPrimarySalesPath, 'get');
    if (params) {

      rb.path('year', params.year, {});
      rb.path('month', params.month, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QlikStructPlanDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerPrimarySales$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerPrimarySales(params: {
    year: number;
    month: number;

  }): Observable<Array<QlikStructPlanDto>> {

    return this.qlikControllerPrimarySales$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QlikStructPlanDto>>) => r.body as Array<QlikStructPlanDto>)
    );
  }

  /**
   * Path part for operation qlikControllerPrimaryPlan
   */
  static readonly QlikControllerPrimaryPlanPath = '/api/qlick/primary-plan/{year}/{month}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerPrimaryPlan()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerPrimaryPlan$Response(params: {
    year: number;
    month: number;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerPrimaryPlanPath, 'get');
    if (params) {

      rb.path('year', params.year, {});
      rb.path('month', params.month, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerPrimaryPlan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerPrimaryPlan(params: {
    year: number;
    month: number;

  }): Observable<void> {

    return this.qlikControllerPrimaryPlan$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation qlikControllerDistributors
   */
  static readonly QlikControllerDistributorsPath = '/api/qlick/nd/distributors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerDistributors()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerDistributors$Response(params?: {

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerDistributorsPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerDistributors$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerDistributors(params?: {

  }): Observable<void> {

    return this.qlikControllerDistributors$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation qlikControllerWarehouses
   */
  static readonly QlikControllerWarehousesPath = '/api/qlick/nd/warehouses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerWarehouses()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerWarehouses$Response(params?: {

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerWarehousesPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerWarehouses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerWarehouses(params?: {

  }): Observable<void> {

    return this.qlikControllerWarehouses$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation qlikControllerNdRemains
   */
  static readonly QlikControllerNdRemainsPath = '/api/qlick/nd/remains/{year}/{month}/{day}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerNdRemains()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerNdRemains$Response(params: {
    year: number;
    month: number;
    day: number;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerNdRemainsPath, 'get');
    if (params) {

      rb.path('year', params.year, {});
      rb.path('month', params.month, {});
      rb.path('day', params.day, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerNdRemains$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerNdRemains(params: {
    year: number;
    month: number;
    day: number;

  }): Observable<void> {

    return this.qlikControllerNdRemains$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation qlikControllerNdSales
   */
  static readonly QlikControllerNdSalesPath = '/api/qlick/nd/sales/{year}/{month}/{day}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerNdSales()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerNdSales$Response(params: {
    year: number;
    month: number;
    day: number;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerNdSalesPath, 'get');
    if (params) {

      rb.path('year', params.year, {});
      rb.path('month', params.month, {});
      rb.path('day', params.day, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerNdSales$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerNdSales(params: {
    year: number;
    month: number;
    day: number;

  }): Observable<void> {

    return this.qlikControllerNdSales$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation qlikControllerNdSalesFull
   */
  static readonly QlikControllerNdSalesFullPath = '/api/qlick/nd/sales/{year}/{month}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerNdSalesFull()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerNdSalesFull$Response(params: {
    year: number;
    month: number;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerNdSalesFullPath, 'get');
    if (params) {

      rb.path('year', params.year, {});
      rb.path('month', params.month, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerNdSalesFull$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerNdSalesFull(params: {
    year: number;
    month: number;

  }): Observable<void> {

    return this.qlikControllerNdSalesFull$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation qlikControllerNetworks
   */
  static readonly QlikControllerNetworksPath = '/api/qlick/networks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerNetworks()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerNetworks$Response(params?: {

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerNetworksPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerNetworks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerNetworks(params?: {

  }): Observable<void> {

    return this.qlikControllerNetworks$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation qlikControllerNetworkSales
   */
  static readonly QlikControllerNetworkSalesPath = '/api/qlick/networks/sales/{year}/{month}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerNetworkSales()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerNetworkSales$Response(params: {
    year: number;
    month: number;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerNetworkSalesPath, 'get');
    if (params) {

      rb.path('year', params.year, {});
      rb.path('month', params.month, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerNetworkSales$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerNetworkSales(params: {
    year: number;
    month: number;

  }): Observable<void> {

    return this.qlikControllerNetworkSales$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation qlikControllerNetworkPlans
   */
  static readonly QlikControllerNetworkPlansPath = '/api/qlick/networks/plans/{year}/{month}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerNetworkPlans()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerNetworkPlans$Response(params: {
    year: number;
    month: number;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerNetworkPlansPath, 'get');
    if (params) {

      rb.path('year', params.year, {});
      rb.path('month', params.month, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerNetworkPlans$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerNetworkPlans(params: {
    year: number;
    month: number;

  }): Observable<void> {

    return this.qlikControllerNetworkPlans$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation qlikControllerCalendar
   */
  static readonly QlikControllerCalendarPath = '/api/qlick/calendar/{year}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerCalendar()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerCalendar$Response(params: {
    year: number;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerCalendarPath, 'get');
    if (params) {

      rb.path('year', params.year, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerCalendar$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerCalendar(params: {
    year: number;

  }): Observable<void> {

    return this.qlikControllerCalendar$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation qlikControllerContacts
   */
  static readonly QlikControllerContactsPath = '/api/qlick/contacts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerContacts()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerContacts$Response(params?: {

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerContactsPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerContacts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerContacts(params?: {

  }): Observable<void> {

    return this.qlikControllerContacts$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation qlikControllerSpecialities
   */
  static readonly QlikControllerSpecialitiesPath = '/api/qlick/specialities';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerSpecialities()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerSpecialities$Response(params?: {

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerSpecialitiesPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerSpecialities$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerSpecialities(params?: {

  }): Observable<void> {

    return this.qlikControllerSpecialities$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation qlikControllerContactSpecialities
   */
  static readonly QlikControllerContactSpecialitiesPath = '/api/qlick/contact-specialities';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerContactSpecialities()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerContactSpecialities$Response(params?: {

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerContactSpecialitiesPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerContactSpecialities$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerContactSpecialities(params?: {

  }): Observable<void> {

    return this.qlikControllerContactSpecialities$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation qlikControllerProjectType
   */
  static readonly QlikControllerProjectTypePath = '/api/qlick/project-type';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerProjectType()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerProjectType$Response(params?: {

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerProjectTypePath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerProjectType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerProjectType(params?: {

  }): Observable<void> {

    return this.qlikControllerProjectType$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation qlikControllerProjects
   */
  static readonly QlikControllerProjectsPath = '/api/qlick/projects';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerProjects()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerProjects$Response(params?: {

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerProjectsPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerProjects$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerProjects(params?: {

  }): Observable<void> {

    return this.qlikControllerProjects$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation qlikControllerProjectPlans
   */
  static readonly QlikControllerProjectPlansPath = '/api/qlick/project-plans/{year}/{month}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerProjectPlans()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerProjectPlans$Response(params: {
    year: number;
    month: number;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerProjectPlansPath, 'get');
    if (params) {

      rb.path('year', params.year, {});
      rb.path('month', params.month, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerProjectPlans$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerProjectPlans(params: {
    year: number;
    month: number;

  }): Observable<void> {

    return this.qlikControllerProjectPlans$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation qlikControllerProjectSales
   */
  static readonly QlikControllerProjectSalesPath = '/api/qlick/project-sales/{year}/{month}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerProjectSales()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerProjectSales$Response(params: {
    year: number;
    month: number;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerProjectSalesPath, 'get');
    if (params) {

      rb.path('year', params.year, {});
      rb.path('month', params.month, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerProjectSales$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerProjectSales(params: {
    year: number;
    month: number;

  }): Observable<void> {

    return this.qlikControllerProjectSales$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation qlikControllerTenderSales
   */
  static readonly QlikControllerTenderSalesPath = '/api/qlick/tender-sales/{year}/{month}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerTenderSales()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerTenderSales$Response(params: {
    year: number;
    month: number;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerTenderSalesPath, 'get');
    if (params) {

      rb.path('year', params.year, {});
      rb.path('month', params.month, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerTenderSales$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerTenderSales(params: {
    year: number;
    month: number;

  }): Observable<void> {

    return this.qlikControllerTenderSales$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation qlikControllerAccounts
   */
  static readonly QlikControllerAccountsPath = '/api/qlick/accounts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `qlikControllerAccounts()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerAccounts$Response(params?: {

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, QlikService.QlikControllerAccountsPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `qlikControllerAccounts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  qlikControllerAccounts(params?: {

  }): Observable<void> {

    return this.qlikControllerAccounts$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
