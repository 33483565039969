/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DictionaryConfigDto } from '../models/dictionary-config-dto';
import { DictionaryExcelRequestDto } from '../models/dictionary-excel-request-dto';
import { DictionaryExcelResponseDto } from '../models/dictionary-excel-response-dto';
import { DictionaryRequestDto } from '../models/dictionary-request-dto';
import { DictionaryTableDto } from '../models/dictionary-table-dto';
import { SearchSelectItemDto } from '../models/search-select-item-dto';

@Injectable({
  providedIn: 'root',
})
export class DictionariesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation projectKindsDictionaryControllerDictionaryConfig
   */
  static readonly ProjectKindsDictionaryControllerDictionaryConfigPath = '/api/dictionaries/project-kinds/config';

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectKindsDictionaryControllerDictionaryConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectKindsDictionaryControllerDictionaryConfig$Response(params?: {
    query?: {  };

  }): Observable<StrictHttpResponse<DictionaryConfigDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.ProjectKindsDictionaryControllerDictionaryConfigPath, 'get');
    if (params) {

      rb.query('query', params.query, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryConfigDto>;
      })
    );
  }

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectKindsDictionaryControllerDictionaryConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectKindsDictionaryControllerDictionaryConfig(params?: {
    query?: {  };

  }): Observable<DictionaryConfigDto> {

    return this.projectKindsDictionaryControllerDictionaryConfig$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryConfigDto>) => r.body as DictionaryConfigDto)
    );
  }

  /**
   * Path part for operation projectKindsDictionaryControllerDictionaryTable
   */
  static readonly ProjectKindsDictionaryControllerDictionaryTablePath = '/api/dictionaries/project-kinds/table';

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectKindsDictionaryControllerDictionaryTable()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectKindsDictionaryControllerDictionaryTable$Response(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<StrictHttpResponse<DictionaryTableDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.ProjectKindsDictionaryControllerDictionaryTablePath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryTableDto>;
      })
    );
  }

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectKindsDictionaryControllerDictionaryTable$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectKindsDictionaryControllerDictionaryTable(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<DictionaryTableDto> {

    return this.projectKindsDictionaryControllerDictionaryTable$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryTableDto>) => r.body as DictionaryTableDto)
    );
  }

  /**
   * Path part for operation projectKindsDictionaryControllerDictionaryExcel
   */
  static readonly ProjectKindsDictionaryControllerDictionaryExcelPath = '/api/dictionaries/project-kinds/export';

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectKindsDictionaryControllerDictionaryExcel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectKindsDictionaryControllerDictionaryExcel$Response(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<StrictHttpResponse<DictionaryExcelResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.ProjectKindsDictionaryControllerDictionaryExcelPath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryExcelResponseDto>;
      })
    );
  }

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectKindsDictionaryControllerDictionaryExcel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectKindsDictionaryControllerDictionaryExcel(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<DictionaryExcelResponseDto> {

    return this.projectKindsDictionaryControllerDictionaryExcel$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryExcelResponseDto>) => r.body as DictionaryExcelResponseDto)
    );
  }

  /**
   * Path part for operation projectKindsDictionaryControllerRefresh
   */
  static readonly ProjectKindsDictionaryControllerRefreshPath = '/api/dictionaries/project-kinds/refresh';

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectKindsDictionaryControllerRefresh()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectKindsDictionaryControllerRefresh$Response(params?: {

  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.ProjectKindsDictionaryControllerRefreshPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectKindsDictionaryControllerRefresh$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectKindsDictionaryControllerRefresh(params?: {

  }): Observable<boolean> {

    return this.projectKindsDictionaryControllerRefresh$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation projectTypesDictionaryControllerDictionaryConfig
   */
  static readonly ProjectTypesDictionaryControllerDictionaryConfigPath = '/api/dictionaries/project-types/config';

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectTypesDictionaryControllerDictionaryConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTypesDictionaryControllerDictionaryConfig$Response(params?: {
    query?: {  };

  }): Observable<StrictHttpResponse<DictionaryConfigDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.ProjectTypesDictionaryControllerDictionaryConfigPath, 'get');
    if (params) {

      rb.query('query', params.query, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryConfigDto>;
      })
    );
  }

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectTypesDictionaryControllerDictionaryConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTypesDictionaryControllerDictionaryConfig(params?: {
    query?: {  };

  }): Observable<DictionaryConfigDto> {

    return this.projectTypesDictionaryControllerDictionaryConfig$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryConfigDto>) => r.body as DictionaryConfigDto)
    );
  }

  /**
   * Path part for operation projectTypesDictionaryControllerDictionaryTable
   */
  static readonly ProjectTypesDictionaryControllerDictionaryTablePath = '/api/dictionaries/project-types/table';

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectTypesDictionaryControllerDictionaryTable()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectTypesDictionaryControllerDictionaryTable$Response(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<StrictHttpResponse<DictionaryTableDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.ProjectTypesDictionaryControllerDictionaryTablePath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryTableDto>;
      })
    );
  }

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectTypesDictionaryControllerDictionaryTable$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectTypesDictionaryControllerDictionaryTable(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<DictionaryTableDto> {

    return this.projectTypesDictionaryControllerDictionaryTable$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryTableDto>) => r.body as DictionaryTableDto)
    );
  }

  /**
   * Path part for operation projectTypesDictionaryControllerDictionaryExcel
   */
  static readonly ProjectTypesDictionaryControllerDictionaryExcelPath = '/api/dictionaries/project-types/export';

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectTypesDictionaryControllerDictionaryExcel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectTypesDictionaryControllerDictionaryExcel$Response(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<StrictHttpResponse<DictionaryExcelResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.ProjectTypesDictionaryControllerDictionaryExcelPath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryExcelResponseDto>;
      })
    );
  }

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectTypesDictionaryControllerDictionaryExcel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectTypesDictionaryControllerDictionaryExcel(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<DictionaryExcelResponseDto> {

    return this.projectTypesDictionaryControllerDictionaryExcel$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryExcelResponseDto>) => r.body as DictionaryExcelResponseDto)
    );
  }

  /**
   * Path part for operation projectTypesDictionaryControllerRefresh
   */
  static readonly ProjectTypesDictionaryControllerRefreshPath = '/api/dictionaries/project-types/refresh';

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectTypesDictionaryControllerRefresh()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTypesDictionaryControllerRefresh$Response(params?: {

  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.ProjectTypesDictionaryControllerRefreshPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectTypesDictionaryControllerRefresh$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectTypesDictionaryControllerRefresh(params?: {

  }): Observable<boolean> {

    return this.projectTypesDictionaryControllerRefresh$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation areasDictionaryControllerDictionaryConfig
   */
  static readonly AreasDictionaryControllerDictionaryConfigPath = '/api/dictionaries/areas/config';

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `areasDictionaryControllerDictionaryConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  areasDictionaryControllerDictionaryConfig$Response(params?: {
    query?: {  };

  }): Observable<StrictHttpResponse<DictionaryConfigDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.AreasDictionaryControllerDictionaryConfigPath, 'get');
    if (params) {

      rb.query('query', params.query, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryConfigDto>;
      })
    );
  }

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `areasDictionaryControllerDictionaryConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  areasDictionaryControllerDictionaryConfig(params?: {
    query?: {  };

  }): Observable<DictionaryConfigDto> {

    return this.areasDictionaryControllerDictionaryConfig$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryConfigDto>) => r.body as DictionaryConfigDto)
    );
  }

  /**
   * Path part for operation areasDictionaryControllerDictionaryTable
   */
  static readonly AreasDictionaryControllerDictionaryTablePath = '/api/dictionaries/areas/table';

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `areasDictionaryControllerDictionaryTable()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  areasDictionaryControllerDictionaryTable$Response(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<StrictHttpResponse<DictionaryTableDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.AreasDictionaryControllerDictionaryTablePath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryTableDto>;
      })
    );
  }

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `areasDictionaryControllerDictionaryTable$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  areasDictionaryControllerDictionaryTable(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<DictionaryTableDto> {

    return this.areasDictionaryControllerDictionaryTable$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryTableDto>) => r.body as DictionaryTableDto)
    );
  }

  /**
   * Path part for operation areasDictionaryControllerDictionaryExcel
   */
  static readonly AreasDictionaryControllerDictionaryExcelPath = '/api/dictionaries/areas/export';

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `areasDictionaryControllerDictionaryExcel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  areasDictionaryControllerDictionaryExcel$Response(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<StrictHttpResponse<DictionaryExcelResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.AreasDictionaryControllerDictionaryExcelPath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryExcelResponseDto>;
      })
    );
  }

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `areasDictionaryControllerDictionaryExcel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  areasDictionaryControllerDictionaryExcel(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<DictionaryExcelResponseDto> {

    return this.areasDictionaryControllerDictionaryExcel$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryExcelResponseDto>) => r.body as DictionaryExcelResponseDto)
    );
  }

  /**
   * Path part for operation areasDictionaryControllerRefresh
   */
  static readonly AreasDictionaryControllerRefreshPath = '/api/dictionaries/areas/refresh';

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `areasDictionaryControllerRefresh()` instead.
   *
   * This method doesn't expect any request body.
   */
  areasDictionaryControllerRefresh$Response(params?: {

  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.AreasDictionaryControllerRefreshPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `areasDictionaryControllerRefresh$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  areasDictionaryControllerRefresh(params?: {

  }): Observable<boolean> {

    return this.areasDictionaryControllerRefresh$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation tenderTermsDictionaryControllerDictionaryConfig
   */
  static readonly TenderTermsDictionaryControllerDictionaryConfigPath = '/api/dictionaries/tender-terms/config';

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderTermsDictionaryControllerDictionaryConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTermsDictionaryControllerDictionaryConfig$Response(params?: {
    query?: {  };

  }): Observable<StrictHttpResponse<DictionaryConfigDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.TenderTermsDictionaryControllerDictionaryConfigPath, 'get');
    if (params) {

      rb.query('query', params.query, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryConfigDto>;
      })
    );
  }

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderTermsDictionaryControllerDictionaryConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTermsDictionaryControllerDictionaryConfig(params?: {
    query?: {  };

  }): Observable<DictionaryConfigDto> {

    return this.tenderTermsDictionaryControllerDictionaryConfig$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryConfigDto>) => r.body as DictionaryConfigDto)
    );
  }

  /**
   * Path part for operation tenderTermsDictionaryControllerDictionaryTable
   */
  static readonly TenderTermsDictionaryControllerDictionaryTablePath = '/api/dictionaries/tender-terms/table';

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderTermsDictionaryControllerDictionaryTable()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tenderTermsDictionaryControllerDictionaryTable$Response(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<StrictHttpResponse<DictionaryTableDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.TenderTermsDictionaryControllerDictionaryTablePath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryTableDto>;
      })
    );
  }

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderTermsDictionaryControllerDictionaryTable$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tenderTermsDictionaryControllerDictionaryTable(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<DictionaryTableDto> {

    return this.tenderTermsDictionaryControllerDictionaryTable$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryTableDto>) => r.body as DictionaryTableDto)
    );
  }

  /**
   * Path part for operation tenderTermsDictionaryControllerDictionaryExcel
   */
  static readonly TenderTermsDictionaryControllerDictionaryExcelPath = '/api/dictionaries/tender-terms/export';

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderTermsDictionaryControllerDictionaryExcel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tenderTermsDictionaryControllerDictionaryExcel$Response(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<StrictHttpResponse<DictionaryExcelResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.TenderTermsDictionaryControllerDictionaryExcelPath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryExcelResponseDto>;
      })
    );
  }

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderTermsDictionaryControllerDictionaryExcel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tenderTermsDictionaryControllerDictionaryExcel(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<DictionaryExcelResponseDto> {

    return this.tenderTermsDictionaryControllerDictionaryExcel$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryExcelResponseDto>) => r.body as DictionaryExcelResponseDto)
    );
  }

  /**
   * Path part for operation tenderTermsDictionaryControllerRefresh
   */
  static readonly TenderTermsDictionaryControllerRefreshPath = '/api/dictionaries/tender-terms/refresh';

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenderTermsDictionaryControllerRefresh()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTermsDictionaryControllerRefresh$Response(params?: {

  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.TenderTermsDictionaryControllerRefreshPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenderTermsDictionaryControllerRefresh$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenderTermsDictionaryControllerRefresh(params?: {

  }): Observable<boolean> {

    return this.tenderTermsDictionaryControllerRefresh$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation tendersDictionaryControllerDictionaryConfig
   */
  static readonly TendersDictionaryControllerDictionaryConfigPath = '/api/dictionaries/tenders/config';

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tendersDictionaryControllerDictionaryConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  tendersDictionaryControllerDictionaryConfig$Response(params?: {
    query?: {  };

  }): Observable<StrictHttpResponse<DictionaryConfigDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.TendersDictionaryControllerDictionaryConfigPath, 'get');
    if (params) {

      rb.query('query', params.query, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryConfigDto>;
      })
    );
  }

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tendersDictionaryControllerDictionaryConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tendersDictionaryControllerDictionaryConfig(params?: {
    query?: {  };

  }): Observable<DictionaryConfigDto> {

    return this.tendersDictionaryControllerDictionaryConfig$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryConfigDto>) => r.body as DictionaryConfigDto)
    );
  }

  /**
   * Path part for operation tendersDictionaryControllerDictionaryTable
   */
  static readonly TendersDictionaryControllerDictionaryTablePath = '/api/dictionaries/tenders/table';

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tendersDictionaryControllerDictionaryTable()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tendersDictionaryControllerDictionaryTable$Response(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<StrictHttpResponse<DictionaryTableDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.TendersDictionaryControllerDictionaryTablePath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryTableDto>;
      })
    );
  }

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tendersDictionaryControllerDictionaryTable$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tendersDictionaryControllerDictionaryTable(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<DictionaryTableDto> {

    return this.tendersDictionaryControllerDictionaryTable$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryTableDto>) => r.body as DictionaryTableDto)
    );
  }

  /**
   * Path part for operation tendersDictionaryControllerDictionaryExcel
   */
  static readonly TendersDictionaryControllerDictionaryExcelPath = '/api/dictionaries/tenders/export';

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tendersDictionaryControllerDictionaryExcel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tendersDictionaryControllerDictionaryExcel$Response(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<StrictHttpResponse<DictionaryExcelResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.TendersDictionaryControllerDictionaryExcelPath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryExcelResponseDto>;
      })
    );
  }

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tendersDictionaryControllerDictionaryExcel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tendersDictionaryControllerDictionaryExcel(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<DictionaryExcelResponseDto> {

    return this.tendersDictionaryControllerDictionaryExcel$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryExcelResponseDto>) => r.body as DictionaryExcelResponseDto)
    );
  }

  /**
   * Path part for operation tendersDictionaryControllerRefresh
   */
  static readonly TendersDictionaryControllerRefreshPath = '/api/dictionaries/tenders/refresh';

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tendersDictionaryControllerRefresh()` instead.
   *
   * This method doesn't expect any request body.
   */
  tendersDictionaryControllerRefresh$Response(params?: {

  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.TendersDictionaryControllerRefreshPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tendersDictionaryControllerRefresh$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tendersDictionaryControllerRefresh(params?: {

  }): Observable<boolean> {

    return this.tendersDictionaryControllerRefresh$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation ndProductLinksDictionaryControllerDictionaryConfig
   */
  static readonly NdProductLinksDictionaryControllerDictionaryConfigPath = '/api/dictionaries/nd-product-links/config';

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ndProductLinksDictionaryControllerDictionaryConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  ndProductLinksDictionaryControllerDictionaryConfig$Response(params?: {
    query?: {  };

  }): Observable<StrictHttpResponse<DictionaryConfigDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.NdProductLinksDictionaryControllerDictionaryConfigPath, 'get');
    if (params) {

      rb.query('query', params.query, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryConfigDto>;
      })
    );
  }

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ndProductLinksDictionaryControllerDictionaryConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ndProductLinksDictionaryControllerDictionaryConfig(params?: {
    query?: {  };

  }): Observable<DictionaryConfigDto> {

    return this.ndProductLinksDictionaryControllerDictionaryConfig$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryConfigDto>) => r.body as DictionaryConfigDto)
    );
  }

  /**
   * Path part for operation ndProductLinksDictionaryControllerDictionaryTable
   */
  static readonly NdProductLinksDictionaryControllerDictionaryTablePath = '/api/dictionaries/nd-product-links/table';

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ndProductLinksDictionaryControllerDictionaryTable()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ndProductLinksDictionaryControllerDictionaryTable$Response(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<StrictHttpResponse<DictionaryTableDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.NdProductLinksDictionaryControllerDictionaryTablePath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryTableDto>;
      })
    );
  }

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ndProductLinksDictionaryControllerDictionaryTable$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ndProductLinksDictionaryControllerDictionaryTable(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<DictionaryTableDto> {

    return this.ndProductLinksDictionaryControllerDictionaryTable$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryTableDto>) => r.body as DictionaryTableDto)
    );
  }

  /**
   * Path part for operation ndProductLinksDictionaryControllerDictionaryExcel
   */
  static readonly NdProductLinksDictionaryControllerDictionaryExcelPath = '/api/dictionaries/nd-product-links/export';

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ndProductLinksDictionaryControllerDictionaryExcel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ndProductLinksDictionaryControllerDictionaryExcel$Response(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<StrictHttpResponse<DictionaryExcelResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.NdProductLinksDictionaryControllerDictionaryExcelPath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryExcelResponseDto>;
      })
    );
  }

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ndProductLinksDictionaryControllerDictionaryExcel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ndProductLinksDictionaryControllerDictionaryExcel(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<DictionaryExcelResponseDto> {

    return this.ndProductLinksDictionaryControllerDictionaryExcel$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryExcelResponseDto>) => r.body as DictionaryExcelResponseDto)
    );
  }

  /**
   * Path part for operation ndProductLinksDictionaryControllerRefresh
   */
  static readonly NdProductLinksDictionaryControllerRefreshPath = '/api/dictionaries/nd-product-links/refresh';

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ndProductLinksDictionaryControllerRefresh()` instead.
   *
   * This method doesn't expect any request body.
   */
  ndProductLinksDictionaryControllerRefresh$Response(params?: {

  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.NdProductLinksDictionaryControllerRefreshPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ndProductLinksDictionaryControllerRefresh$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ndProductLinksDictionaryControllerRefresh(params?: {

  }): Observable<boolean> {

    return this.ndProductLinksDictionaryControllerRefresh$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation structDictionaryControllerLabel
   */
  static readonly StructDictionaryControllerLabelPath = '/api/dictionaries/struct/label';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `structDictionaryControllerLabel()` instead.
   *
   * This method doesn't expect any request body.
   */
  structDictionaryControllerLabel$Response(params?: {

  }): Observable<StrictHttpResponse<Array<SearchSelectItemDto>>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.StructDictionaryControllerLabelPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SearchSelectItemDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `structDictionaryControllerLabel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  structDictionaryControllerLabel(params?: {

  }): Observable<Array<SearchSelectItemDto>> {

    return this.structDictionaryControllerLabel$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SearchSelectItemDto>>) => r.body as Array<SearchSelectItemDto>)
    );
  }

  /**
   * Path part for operation structDictionaryControllerDictionaryConfig
   */
  static readonly StructDictionaryControllerDictionaryConfigPath = '/api/dictionaries/struct/config';

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `structDictionaryControllerDictionaryConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  structDictionaryControllerDictionaryConfig$Response(params?: {
    query?: {  };

  }): Observable<StrictHttpResponse<DictionaryConfigDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.StructDictionaryControllerDictionaryConfigPath, 'get');
    if (params) {

      rb.query('query', params.query, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryConfigDto>;
      })
    );
  }

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `structDictionaryControllerDictionaryConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  structDictionaryControllerDictionaryConfig(params?: {
    query?: {  };

  }): Observable<DictionaryConfigDto> {

    return this.structDictionaryControllerDictionaryConfig$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryConfigDto>) => r.body as DictionaryConfigDto)
    );
  }

  /**
   * Path part for operation structDictionaryControllerDictionaryTable
   */
  static readonly StructDictionaryControllerDictionaryTablePath = '/api/dictionaries/struct/table';

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `structDictionaryControllerDictionaryTable()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  structDictionaryControllerDictionaryTable$Response(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<StrictHttpResponse<DictionaryTableDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.StructDictionaryControllerDictionaryTablePath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryTableDto>;
      })
    );
  }

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `structDictionaryControllerDictionaryTable$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  structDictionaryControllerDictionaryTable(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<DictionaryTableDto> {

    return this.structDictionaryControllerDictionaryTable$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryTableDto>) => r.body as DictionaryTableDto)
    );
  }

  /**
   * Path part for operation structDictionaryControllerDictionaryExcel
   */
  static readonly StructDictionaryControllerDictionaryExcelPath = '/api/dictionaries/struct/export';

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `structDictionaryControllerDictionaryExcel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  structDictionaryControllerDictionaryExcel$Response(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<StrictHttpResponse<DictionaryExcelResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.StructDictionaryControllerDictionaryExcelPath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryExcelResponseDto>;
      })
    );
  }

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `structDictionaryControllerDictionaryExcel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  structDictionaryControllerDictionaryExcel(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<DictionaryExcelResponseDto> {

    return this.structDictionaryControllerDictionaryExcel$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryExcelResponseDto>) => r.body as DictionaryExcelResponseDto)
    );
  }

  /**
   * Path part for operation structDictionaryControllerRefresh
   */
  static readonly StructDictionaryControllerRefreshPath = '/api/dictionaries/struct/refresh';

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `structDictionaryControllerRefresh()` instead.
   *
   * This method doesn't expect any request body.
   */
  structDictionaryControllerRefresh$Response(params?: {

  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.StructDictionaryControllerRefreshPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `structDictionaryControllerRefresh$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  structDictionaryControllerRefresh(params?: {

  }): Observable<boolean> {

    return this.structDictionaryControllerRefresh$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation structHistoryDictionaryControllerDictionaryConfig
   */
  static readonly StructHistoryDictionaryControllerDictionaryConfigPath = '/api/dictionaries/struct-history/config';

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `structHistoryDictionaryControllerDictionaryConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  structHistoryDictionaryControllerDictionaryConfig$Response(params?: {
    query?: {  };

  }): Observable<StrictHttpResponse<DictionaryConfigDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.StructHistoryDictionaryControllerDictionaryConfigPath, 'get');
    if (params) {

      rb.query('query', params.query, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryConfigDto>;
      })
    );
  }

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `structHistoryDictionaryControllerDictionaryConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  structHistoryDictionaryControllerDictionaryConfig(params?: {
    query?: {  };

  }): Observable<DictionaryConfigDto> {

    return this.structHistoryDictionaryControllerDictionaryConfig$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryConfigDto>) => r.body as DictionaryConfigDto)
    );
  }

  /**
   * Path part for operation structHistoryDictionaryControllerDictionaryTable
   */
  static readonly StructHistoryDictionaryControllerDictionaryTablePath = '/api/dictionaries/struct-history/table';

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `structHistoryDictionaryControllerDictionaryTable()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  structHistoryDictionaryControllerDictionaryTable$Response(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<StrictHttpResponse<DictionaryTableDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.StructHistoryDictionaryControllerDictionaryTablePath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryTableDto>;
      })
    );
  }

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `structHistoryDictionaryControllerDictionaryTable$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  structHistoryDictionaryControllerDictionaryTable(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<DictionaryTableDto> {

    return this.structHistoryDictionaryControllerDictionaryTable$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryTableDto>) => r.body as DictionaryTableDto)
    );
  }

  /**
   * Path part for operation structHistoryDictionaryControllerDictionaryExcel
   */
  static readonly StructHistoryDictionaryControllerDictionaryExcelPath = '/api/dictionaries/struct-history/export';

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `structHistoryDictionaryControllerDictionaryExcel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  structHistoryDictionaryControllerDictionaryExcel$Response(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<StrictHttpResponse<DictionaryExcelResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.StructHistoryDictionaryControllerDictionaryExcelPath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryExcelResponseDto>;
      })
    );
  }

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `structHistoryDictionaryControllerDictionaryExcel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  structHistoryDictionaryControllerDictionaryExcel(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<DictionaryExcelResponseDto> {

    return this.structHistoryDictionaryControllerDictionaryExcel$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryExcelResponseDto>) => r.body as DictionaryExcelResponseDto)
    );
  }

  /**
   * Path part for operation structHistoryDictionaryControllerRefresh
   */
  static readonly StructHistoryDictionaryControllerRefreshPath = '/api/dictionaries/struct-history/refresh';

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `structHistoryDictionaryControllerRefresh()` instead.
   *
   * This method doesn't expect any request body.
   */
  structHistoryDictionaryControllerRefresh$Response(params?: {

  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.StructHistoryDictionaryControllerRefreshPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `structHistoryDictionaryControllerRefresh$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  structHistoryDictionaryControllerRefresh(params?: {

  }): Observable<boolean> {

    return this.structHistoryDictionaryControllerRefresh$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation projectsDictionaryControllerDictionaryConfig
   */
  static readonly ProjectsDictionaryControllerDictionaryConfigPath = '/api/dictionaries/projects/config';

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsDictionaryControllerDictionaryConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsDictionaryControllerDictionaryConfig$Response(params?: {
    query?: {  };

  }): Observable<StrictHttpResponse<DictionaryConfigDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.ProjectsDictionaryControllerDictionaryConfigPath, 'get');
    if (params) {

      rb.query('query', params.query, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryConfigDto>;
      })
    );
  }

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectsDictionaryControllerDictionaryConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsDictionaryControllerDictionaryConfig(params?: {
    query?: {  };

  }): Observable<DictionaryConfigDto> {

    return this.projectsDictionaryControllerDictionaryConfig$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryConfigDto>) => r.body as DictionaryConfigDto)
    );
  }

  /**
   * Path part for operation projectsDictionaryControllerDictionaryTable
   */
  static readonly ProjectsDictionaryControllerDictionaryTablePath = '/api/dictionaries/projects/table';

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsDictionaryControllerDictionaryTable()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsDictionaryControllerDictionaryTable$Response(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<StrictHttpResponse<DictionaryTableDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.ProjectsDictionaryControllerDictionaryTablePath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryTableDto>;
      })
    );
  }

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectsDictionaryControllerDictionaryTable$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsDictionaryControllerDictionaryTable(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<DictionaryTableDto> {

    return this.projectsDictionaryControllerDictionaryTable$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryTableDto>) => r.body as DictionaryTableDto)
    );
  }

  /**
   * Path part for operation projectsDictionaryControllerDictionaryExcel
   */
  static readonly ProjectsDictionaryControllerDictionaryExcelPath = '/api/dictionaries/projects/export';

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsDictionaryControllerDictionaryExcel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsDictionaryControllerDictionaryExcel$Response(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<StrictHttpResponse<DictionaryExcelResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.ProjectsDictionaryControllerDictionaryExcelPath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryExcelResponseDto>;
      })
    );
  }

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectsDictionaryControllerDictionaryExcel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsDictionaryControllerDictionaryExcel(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<DictionaryExcelResponseDto> {

    return this.projectsDictionaryControllerDictionaryExcel$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryExcelResponseDto>) => r.body as DictionaryExcelResponseDto)
    );
  }

  /**
   * Path part for operation projectsDictionaryControllerRefresh
   */
  static readonly ProjectsDictionaryControllerRefreshPath = '/api/dictionaries/projects/refresh';

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsDictionaryControllerRefresh()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsDictionaryControllerRefresh$Response(params?: {

  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.ProjectsDictionaryControllerRefreshPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectsDictionaryControllerRefresh$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsDictionaryControllerRefresh(params?: {

  }): Observable<boolean> {

    return this.projectsDictionaryControllerRefresh$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation networksDictionaryControllerDictionaryConfig
   */
  static readonly NetworksDictionaryControllerDictionaryConfigPath = '/api/dictionaries/networks/config';

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `networksDictionaryControllerDictionaryConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  networksDictionaryControllerDictionaryConfig$Response(params?: {
    query?: {  };

  }): Observable<StrictHttpResponse<DictionaryConfigDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.NetworksDictionaryControllerDictionaryConfigPath, 'get');
    if (params) {

      rb.query('query', params.query, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryConfigDto>;
      })
    );
  }

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `networksDictionaryControllerDictionaryConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  networksDictionaryControllerDictionaryConfig(params?: {
    query?: {  };

  }): Observable<DictionaryConfigDto> {

    return this.networksDictionaryControllerDictionaryConfig$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryConfigDto>) => r.body as DictionaryConfigDto)
    );
  }

  /**
   * Path part for operation networksDictionaryControllerDictionaryTable
   */
  static readonly NetworksDictionaryControllerDictionaryTablePath = '/api/dictionaries/networks/table';

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `networksDictionaryControllerDictionaryTable()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  networksDictionaryControllerDictionaryTable$Response(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<StrictHttpResponse<DictionaryTableDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.NetworksDictionaryControllerDictionaryTablePath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryTableDto>;
      })
    );
  }

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `networksDictionaryControllerDictionaryTable$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  networksDictionaryControllerDictionaryTable(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<DictionaryTableDto> {

    return this.networksDictionaryControllerDictionaryTable$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryTableDto>) => r.body as DictionaryTableDto)
    );
  }

  /**
   * Path part for operation networksDictionaryControllerDictionaryExcel
   */
  static readonly NetworksDictionaryControllerDictionaryExcelPath = '/api/dictionaries/networks/export';

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `networksDictionaryControllerDictionaryExcel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  networksDictionaryControllerDictionaryExcel$Response(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<StrictHttpResponse<DictionaryExcelResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.NetworksDictionaryControllerDictionaryExcelPath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryExcelResponseDto>;
      })
    );
  }

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `networksDictionaryControllerDictionaryExcel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  networksDictionaryControllerDictionaryExcel(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<DictionaryExcelResponseDto> {

    return this.networksDictionaryControllerDictionaryExcel$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryExcelResponseDto>) => r.body as DictionaryExcelResponseDto)
    );
  }

  /**
   * Path part for operation networksDictionaryControllerRefresh
   */
  static readonly NetworksDictionaryControllerRefreshPath = '/api/dictionaries/networks/refresh';

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `networksDictionaryControllerRefresh()` instead.
   *
   * This method doesn't expect any request body.
   */
  networksDictionaryControllerRefresh$Response(params?: {

  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.NetworksDictionaryControllerRefreshPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `networksDictionaryControllerRefresh$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  networksDictionaryControllerRefresh(params?: {

  }): Observable<boolean> {

    return this.networksDictionaryControllerRefresh$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation promotionsDictionaryControllerDictionaryConfig
   */
  static readonly PromotionsDictionaryControllerDictionaryConfigPath = '/api/dictionaries/promotions/config';

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promotionsDictionaryControllerDictionaryConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotionsDictionaryControllerDictionaryConfig$Response(params?: {
    query?: {  };

  }): Observable<StrictHttpResponse<DictionaryConfigDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.PromotionsDictionaryControllerDictionaryConfigPath, 'get');
    if (params) {

      rb.query('query', params.query, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryConfigDto>;
      })
    );
  }

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promotionsDictionaryControllerDictionaryConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotionsDictionaryControllerDictionaryConfig(params?: {
    query?: {  };

  }): Observable<DictionaryConfigDto> {

    return this.promotionsDictionaryControllerDictionaryConfig$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryConfigDto>) => r.body as DictionaryConfigDto)
    );
  }

  /**
   * Path part for operation promotionsDictionaryControllerDictionaryTable
   */
  static readonly PromotionsDictionaryControllerDictionaryTablePath = '/api/dictionaries/promotions/table';

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promotionsDictionaryControllerDictionaryTable()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  promotionsDictionaryControllerDictionaryTable$Response(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<StrictHttpResponse<DictionaryTableDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.PromotionsDictionaryControllerDictionaryTablePath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryTableDto>;
      })
    );
  }

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promotionsDictionaryControllerDictionaryTable$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  promotionsDictionaryControllerDictionaryTable(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<DictionaryTableDto> {

    return this.promotionsDictionaryControllerDictionaryTable$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryTableDto>) => r.body as DictionaryTableDto)
    );
  }

  /**
   * Path part for operation promotionsDictionaryControllerDictionaryExcel
   */
  static readonly PromotionsDictionaryControllerDictionaryExcelPath = '/api/dictionaries/promotions/export';

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promotionsDictionaryControllerDictionaryExcel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  promotionsDictionaryControllerDictionaryExcel$Response(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<StrictHttpResponse<DictionaryExcelResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.PromotionsDictionaryControllerDictionaryExcelPath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryExcelResponseDto>;
      })
    );
  }

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promotionsDictionaryControllerDictionaryExcel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  promotionsDictionaryControllerDictionaryExcel(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<DictionaryExcelResponseDto> {

    return this.promotionsDictionaryControllerDictionaryExcel$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryExcelResponseDto>) => r.body as DictionaryExcelResponseDto)
    );
  }

  /**
   * Path part for operation promotionsDictionaryControllerRefresh
   */
  static readonly PromotionsDictionaryControllerRefreshPath = '/api/dictionaries/promotions/refresh';

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promotionsDictionaryControllerRefresh()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotionsDictionaryControllerRefresh$Response(params?: {

  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.PromotionsDictionaryControllerRefreshPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promotionsDictionaryControllerRefresh$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotionsDictionaryControllerRefresh(params?: {

  }): Observable<boolean> {

    return this.promotionsDictionaryControllerRefresh$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation storeDictionaryControllerDictionaryConfig
   */
  static readonly StoreDictionaryControllerDictionaryConfigPath = '/api/dictionaries/store/config';

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeDictionaryControllerDictionaryConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  storeDictionaryControllerDictionaryConfig$Response(params?: {
    query?: {  };

  }): Observable<StrictHttpResponse<DictionaryConfigDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.StoreDictionaryControllerDictionaryConfigPath, 'get');
    if (params) {

      rb.query('query', params.query, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryConfigDto>;
      })
    );
  }

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeDictionaryControllerDictionaryConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  storeDictionaryControllerDictionaryConfig(params?: {
    query?: {  };

  }): Observable<DictionaryConfigDto> {

    return this.storeDictionaryControllerDictionaryConfig$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryConfigDto>) => r.body as DictionaryConfigDto)
    );
  }

  /**
   * Path part for operation storeDictionaryControllerDictionaryTable
   */
  static readonly StoreDictionaryControllerDictionaryTablePath = '/api/dictionaries/store/table';

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeDictionaryControllerDictionaryTable()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeDictionaryControllerDictionaryTable$Response(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<StrictHttpResponse<DictionaryTableDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.StoreDictionaryControllerDictionaryTablePath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryTableDto>;
      })
    );
  }

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeDictionaryControllerDictionaryTable$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeDictionaryControllerDictionaryTable(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<DictionaryTableDto> {

    return this.storeDictionaryControllerDictionaryTable$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryTableDto>) => r.body as DictionaryTableDto)
    );
  }

  /**
   * Path part for operation storeDictionaryControllerDictionaryExcel
   */
  static readonly StoreDictionaryControllerDictionaryExcelPath = '/api/dictionaries/store/export';

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeDictionaryControllerDictionaryExcel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeDictionaryControllerDictionaryExcel$Response(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<StrictHttpResponse<DictionaryExcelResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.StoreDictionaryControllerDictionaryExcelPath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryExcelResponseDto>;
      })
    );
  }

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeDictionaryControllerDictionaryExcel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeDictionaryControllerDictionaryExcel(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<DictionaryExcelResponseDto> {

    return this.storeDictionaryControllerDictionaryExcel$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryExcelResponseDto>) => r.body as DictionaryExcelResponseDto)
    );
  }

  /**
   * Path part for operation storeDictionaryControllerRefresh
   */
  static readonly StoreDictionaryControllerRefreshPath = '/api/dictionaries/store/refresh';

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeDictionaryControllerRefresh()` instead.
   *
   * This method doesn't expect any request body.
   */
  storeDictionaryControllerRefresh$Response(params?: {

  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.StoreDictionaryControllerRefreshPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeDictionaryControllerRefresh$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  storeDictionaryControllerRefresh(params?: {

  }): Observable<boolean> {

    return this.storeDictionaryControllerRefresh$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation mininetsDictionaryControllerDictionaryConfig
   */
  static readonly MininetsDictionaryControllerDictionaryConfigPath = '/api/dictionaries/mininets/config';

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mininetsDictionaryControllerDictionaryConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  mininetsDictionaryControllerDictionaryConfig$Response(params?: {
    query?: {  };

  }): Observable<StrictHttpResponse<DictionaryConfigDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.MininetsDictionaryControllerDictionaryConfigPath, 'get');
    if (params) {

      rb.query('query', params.query, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryConfigDto>;
      })
    );
  }

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mininetsDictionaryControllerDictionaryConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mininetsDictionaryControllerDictionaryConfig(params?: {
    query?: {  };

  }): Observable<DictionaryConfigDto> {

    return this.mininetsDictionaryControllerDictionaryConfig$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryConfigDto>) => r.body as DictionaryConfigDto)
    );
  }

  /**
   * Path part for operation mininetsDictionaryControllerDictionaryTable
   */
  static readonly MininetsDictionaryControllerDictionaryTablePath = '/api/dictionaries/mininets/table';

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mininetsDictionaryControllerDictionaryTable()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mininetsDictionaryControllerDictionaryTable$Response(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<StrictHttpResponse<DictionaryTableDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.MininetsDictionaryControllerDictionaryTablePath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryTableDto>;
      })
    );
  }

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mininetsDictionaryControllerDictionaryTable$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mininetsDictionaryControllerDictionaryTable(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<DictionaryTableDto> {

    return this.mininetsDictionaryControllerDictionaryTable$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryTableDto>) => r.body as DictionaryTableDto)
    );
  }

  /**
   * Path part for operation mininetsDictionaryControllerDictionaryExcel
   */
  static readonly MininetsDictionaryControllerDictionaryExcelPath = '/api/dictionaries/mininets/export';

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mininetsDictionaryControllerDictionaryExcel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mininetsDictionaryControllerDictionaryExcel$Response(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<StrictHttpResponse<DictionaryExcelResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.MininetsDictionaryControllerDictionaryExcelPath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryExcelResponseDto>;
      })
    );
  }

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mininetsDictionaryControllerDictionaryExcel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mininetsDictionaryControllerDictionaryExcel(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<DictionaryExcelResponseDto> {

    return this.mininetsDictionaryControllerDictionaryExcel$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryExcelResponseDto>) => r.body as DictionaryExcelResponseDto)
    );
  }

  /**
   * Path part for operation mininetsDictionaryControllerRefresh
   */
  static readonly MininetsDictionaryControllerRefreshPath = '/api/dictionaries/mininets/refresh';

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mininetsDictionaryControllerRefresh()` instead.
   *
   * This method doesn't expect any request body.
   */
  mininetsDictionaryControllerRefresh$Response(params?: {

  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.MininetsDictionaryControllerRefreshPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mininetsDictionaryControllerRefresh$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mininetsDictionaryControllerRefresh(params?: {

  }): Observable<boolean> {

    return this.mininetsDictionaryControllerRefresh$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation pharmacyParticipantsDictionaryControllerDictionaryConfig
   */
  static readonly PharmacyParticipantsDictionaryControllerDictionaryConfigPath = '/api/dictionaries/pharmacy-participants/config';

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pharmacyParticipantsDictionaryControllerDictionaryConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  pharmacyParticipantsDictionaryControllerDictionaryConfig$Response(params?: {
    query?: {  };

  }): Observable<StrictHttpResponse<DictionaryConfigDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.PharmacyParticipantsDictionaryControllerDictionaryConfigPath, 'get');
    if (params) {

      rb.query('query', params.query, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryConfigDto>;
      })
    );
  }

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `pharmacyParticipantsDictionaryControllerDictionaryConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pharmacyParticipantsDictionaryControllerDictionaryConfig(params?: {
    query?: {  };

  }): Observable<DictionaryConfigDto> {

    return this.pharmacyParticipantsDictionaryControllerDictionaryConfig$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryConfigDto>) => r.body as DictionaryConfigDto)
    );
  }

  /**
   * Path part for operation pharmacyParticipantsDictionaryControllerDictionaryTable
   */
  static readonly PharmacyParticipantsDictionaryControllerDictionaryTablePath = '/api/dictionaries/pharmacy-participants/table';

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pharmacyParticipantsDictionaryControllerDictionaryTable()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pharmacyParticipantsDictionaryControllerDictionaryTable$Response(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<StrictHttpResponse<DictionaryTableDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.PharmacyParticipantsDictionaryControllerDictionaryTablePath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryTableDto>;
      })
    );
  }

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `pharmacyParticipantsDictionaryControllerDictionaryTable$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pharmacyParticipantsDictionaryControllerDictionaryTable(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<DictionaryTableDto> {

    return this.pharmacyParticipantsDictionaryControllerDictionaryTable$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryTableDto>) => r.body as DictionaryTableDto)
    );
  }

  /**
   * Path part for operation pharmacyParticipantsDictionaryControllerDictionaryExcel
   */
  static readonly PharmacyParticipantsDictionaryControllerDictionaryExcelPath = '/api/dictionaries/pharmacy-participants/export';

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pharmacyParticipantsDictionaryControllerDictionaryExcel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pharmacyParticipantsDictionaryControllerDictionaryExcel$Response(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<StrictHttpResponse<DictionaryExcelResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.PharmacyParticipantsDictionaryControllerDictionaryExcelPath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryExcelResponseDto>;
      })
    );
  }

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `pharmacyParticipantsDictionaryControllerDictionaryExcel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pharmacyParticipantsDictionaryControllerDictionaryExcel(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<DictionaryExcelResponseDto> {

    return this.pharmacyParticipantsDictionaryControllerDictionaryExcel$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryExcelResponseDto>) => r.body as DictionaryExcelResponseDto)
    );
  }

  /**
   * Path part for operation pharmacyParticipantsDictionaryControllerRefresh
   */
  static readonly PharmacyParticipantsDictionaryControllerRefreshPath = '/api/dictionaries/pharmacy-participants/refresh';

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pharmacyParticipantsDictionaryControllerRefresh()` instead.
   *
   * This method doesn't expect any request body.
   */
  pharmacyParticipantsDictionaryControllerRefresh$Response(params?: {

  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.PharmacyParticipantsDictionaryControllerRefreshPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `pharmacyParticipantsDictionaryControllerRefresh$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pharmacyParticipantsDictionaryControllerRefresh(params?: {

  }): Observable<boolean> {

    return this.pharmacyParticipantsDictionaryControllerRefresh$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation pharmacyParticipantExtraPackDictionaryControllerDictionaryConfig
   */
  static readonly PharmacyParticipantExtraPackDictionaryControllerDictionaryConfigPath = '/api/dictionaries/pharmacy-participants-extrapack/config';

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pharmacyParticipantExtraPackDictionaryControllerDictionaryConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  pharmacyParticipantExtraPackDictionaryControllerDictionaryConfig$Response(params?: {
    query?: {  };

  }): Observable<StrictHttpResponse<DictionaryConfigDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.PharmacyParticipantExtraPackDictionaryControllerDictionaryConfigPath, 'get');
    if (params) {

      rb.query('query', params.query, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryConfigDto>;
      })
    );
  }

  /**
   * Получение настроек словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `pharmacyParticipantExtraPackDictionaryControllerDictionaryConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pharmacyParticipantExtraPackDictionaryControllerDictionaryConfig(params?: {
    query?: {  };

  }): Observable<DictionaryConfigDto> {

    return this.pharmacyParticipantExtraPackDictionaryControllerDictionaryConfig$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryConfigDto>) => r.body as DictionaryConfigDto)
    );
  }

  /**
   * Path part for operation pharmacyParticipantExtraPackDictionaryControllerDictionaryTable
   */
  static readonly PharmacyParticipantExtraPackDictionaryControllerDictionaryTablePath = '/api/dictionaries/pharmacy-participants-extrapack/table';

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pharmacyParticipantExtraPackDictionaryControllerDictionaryTable()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pharmacyParticipantExtraPackDictionaryControllerDictionaryTable$Response(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<StrictHttpResponse<DictionaryTableDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.PharmacyParticipantExtraPackDictionaryControllerDictionaryTablePath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryTableDto>;
      })
    );
  }

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `pharmacyParticipantExtraPackDictionaryControllerDictionaryTable$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pharmacyParticipantExtraPackDictionaryControllerDictionaryTable(params: {
    query?: {  };
      body: DictionaryRequestDto
  }): Observable<DictionaryTableDto> {

    return this.pharmacyParticipantExtraPackDictionaryControllerDictionaryTable$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryTableDto>) => r.body as DictionaryTableDto)
    );
  }

  /**
   * Path part for operation pharmacyParticipantExtraPackDictionaryControllerDictionaryExcel
   */
  static readonly PharmacyParticipantExtraPackDictionaryControllerDictionaryExcelPath = '/api/dictionaries/pharmacy-participants-extrapack/export';

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pharmacyParticipantExtraPackDictionaryControllerDictionaryExcel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pharmacyParticipantExtraPackDictionaryControllerDictionaryExcel$Response(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<StrictHttpResponse<DictionaryExcelResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.PharmacyParticipantExtraPackDictionaryControllerDictionaryExcelPath, 'post');
    if (params) {

      rb.query('query', params.query, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DictionaryExcelResponseDto>;
      })
    );
  }

  /**
   * Получение выгрузки.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `pharmacyParticipantExtraPackDictionaryControllerDictionaryExcel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pharmacyParticipantExtraPackDictionaryControllerDictionaryExcel(params: {
    query?: {  };
      body: DictionaryExcelRequestDto
  }): Observable<DictionaryExcelResponseDto> {

    return this.pharmacyParticipantExtraPackDictionaryControllerDictionaryExcel$Response(params).pipe(
      map((r: StrictHttpResponse<DictionaryExcelResponseDto>) => r.body as DictionaryExcelResponseDto)
    );
  }

  /**
   * Path part for operation pharmacyParticipantExtraPackDictionaryControllerRefresh
   */
  static readonly PharmacyParticipantExtraPackDictionaryControllerRefreshPath = '/api/dictionaries/pharmacy-participants-extrapack/refresh';

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pharmacyParticipantExtraPackDictionaryControllerRefresh()` instead.
   *
   * This method doesn't expect any request body.
   */
  pharmacyParticipantExtraPackDictionaryControllerRefresh$Response(params?: {

  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.PharmacyParticipantExtraPackDictionaryControllerRefreshPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Обновление записей словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `pharmacyParticipantExtraPackDictionaryControllerRefresh$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pharmacyParticipantExtraPackDictionaryControllerRefresh(params?: {

  }): Observable<boolean> {

    return this.pharmacyParticipantExtraPackDictionaryControllerRefresh$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
