import { Component, OnInit, AfterViewInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

import { RoutePartsService } from './shared/services/route-parts.service';

import { filter } from 'rxjs/operators';
import { UILibIconService } from './shared/services/ui-lib-icon.service';
import {SwUpdate} from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  appTitle = 'MDT';
  pageTitle = '';

  constructor(
    public title: Title,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    private iconService: UILibIconService,
    private swUpdate: SwUpdate
  ) {
    iconService.init();
  }

  ngOnInit() {
    // this.changePageTitle();
    this.title.setTitle(this.appTitle);
    this.updateServiceWorker();
  }

  ngAfterViewInit() {
  }

  changePageTitle() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
      // const routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
      // if (!routeParts.length) {
      //   return this.title.setTitle(this.appTitle);
      // }
      // Extract title from parts;
      // this.pageTitle = routeParts
      //                 .reverse()
      //                 .map((part) => part.title )
      //                 .reduce((partA, partI) => `${partA} > ${partI}`);
      // this.pageTitle += ` | ${this.appTitle}`;
      // this.title.setTitle(this.pageTitle);
    });
  }

  private updateServiceWorker() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        window.location.reload();
      });
    }
  }
}
