import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {SelectPrototype} from '../prototypes/select.prototype';
import {StructService} from '../../../../api/services/struct.service';
import {switchMap, takeUntil} from 'rxjs/operators';
import {BehaviorSubject, combineLatest} from 'rxjs';

@Component({
  selector: 'app-struct-select',
  template: `
    <mat-form-field fxFlex>
      <mat-label translate *ngIf="!multiple && !label">STRUCT</mat-label>
      <mat-label translate *ngIf="multiple && !label">STRUCTS</mat-label>
      <mat-label translate *ngIf="label">{{label | translate}}</mat-label>
      <app-search-select
        [formControl]="control"
        [loading]="loading"
        [disabled]="disabled"
        [multiple]="multiple"
        [options]="options">
      </app-search-select>
    </mat-form-field>
  `,
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => StructSelectComponent),
    },
  ],
})
export class StructSelectComponent extends SelectPrototype implements OnInit {

  company$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  area$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  fieldForce$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  withMe$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Input()
  label: string = undefined;

  private _multiple: boolean;

  @Input()
  set company(value) {
    this.company$.next(value);
  }

  get company() {
    return this.company$.value;
  }

  @Input()
  set area(value) {
    this.area$.next(value);
  }

  get area() {
    return this.area$.value;
  }

  @Input()
  set fieldForce(value) {
    this.fieldForce$.next(value);
  }

  get fieldForce() {
    return this.fieldForce$.value;
  }

  @Input()
  set withMe(value) {
    this.withMe$.next(value);
  }

  get withMe() {
    return this.withMe$.value;
  }

  @Input()
  get multiple() {
    return this._multiple;
  }

  set multiple(req) {
    this._multiple = coerceBooleanProperty(req);
  }

  constructor(private structService: StructService) {
    super();
  }

  ngOnInit() {
    combineLatest([
      this.company$,
      this.area$,
      this.fieldForce$,
      this.withMe$
    ])
      .pipe(
        takeUntil(this.destroyed$),
        switchMap(([company, area, ff, withMe]) => {
          return this.structService.structControllerScopeSubordinatesSelect({
            area,
            company,
            ff,
            withMe
          });
        })
      )
      .subscribe((options) => {
        this.options = options;
        this.loading = false;
      });
    super.ngOnInit();
  }

}
