import * as memoizee from 'memoizee';

export function freeze(maxAge?: number, preFetch?: boolean) {
  return (target, key, descriptor) => {
    const oldFunction = descriptor.value;
    const newFunction = memoizee(oldFunction, {maxAge, preFetch});
    descriptor.value = function () {
      return newFunction.apply(this, arguments);
    };
  };
}
