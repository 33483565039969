import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, FormArray, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ProjectTypeSettings} from '../project-type-settings-form/project-type-settings-form.component';

@Component({
  selector: 'app-project-type-prizes-form',
  template: `
    <div fxLayout="column">
      <div fxFlex fxLayout="row">
        <mat-checkbox fxFlex
                      [checked]="isAllQuantityEditable()"
                      [indeterminate]="isSomeQuantityEditable()"
                      (change)="setAllQuantityEditable($event.checked)">
          Редактирование колличества
        </mat-checkbox>
        <mat-checkbox fxFlex
                      [checked]="isAllCostEditable()"
                      [indeterminate]="isSomeCostEditable()"
                      (change)="setAllCostEditable($event.checked)">
          Редактирование стоимости
        </mat-checkbox>
        <button mat-button color="primary" (click)="addPrize()" *ngIf="visibility.addButton">Добавить</button>
      </div>
      <div *ngFor="let control of this.form.controls; let i = index">
        <mat-card fxFlex>
          <mat-card-content>
            <div fxLayout="column">
              <mat-form-field fxFlex>
                <mat-label>Название</mat-label>
                <input matInput [formControl]="control.get('name')"/>
              </mat-form-field>
              <mat-form-field fxFlex>
                <mat-label>Описание</mat-label>
                <input matInput [formControl]="control.get('description')"/>
              </mat-form-field>
              <mat-form-field fxFlex>
                <mat-label>Стоимость</mat-label>
                <input matInput type="number" [formControl]="control.get('cost')"/>
              </mat-form-field>
              <mat-form-field fxFlex *ngIf="control?.get('settings')?.get('quantityEdit')?.value">
                <mat-label>Кол-во</mat-label>
                <input matInput type="number" [formControl]="control.get('quantity')"/>
              </mat-form-field>
              <fieldset fxLayout="column">
                <mat-form-field fxFlex *ngIf="control?.get('settings')?.get('quantityEdit')?.value">
                  <mat-label>Минимальное кол-во</mat-label>
                  <input matInput type="number" [formControl]="control.get('settings').get('minQuantity')"/>
                </mat-form-field>
                <mat-form-field fxFlex *ngIf="control?.get('settings')?.get('quantityEdit')?.value">
                  <mat-label>Максимальное кол-во</mat-label>
                  <input matInput type="number" [formControl]="control.get('settings').get('maxQuantity')"/>
                </mat-form-field>
                <mat-form-field fxFlex *ngIf="control?.get('settings')?.get('costEdit')?.value">
                  <mat-label>Минимальная цена</mat-label>
                  <input matInput type="number" [formControl]="control.get('settings').get('minCost')"/>
                </mat-form-field>
                <mat-form-field fxFlex *ngIf="control?.get('settings')?.get('costEdit')?.value">
                  <mat-label>Максимальная цена</mat-label>
                  <input matInput type="number" [formControl]="control.get('settings').get('maxCost')"/>
                </mat-form-field>
                <mat-checkbox [formControl]="control.get('settings').get('quantityEdit')" fxFlex>Редактирование колличества</mat-checkbox>
                <mat-checkbox [formControl]="control.get('settings').get('costEdit')" fxFlex>Редактирование стоимости</mat-checkbox>
              </fieldset>
            </div>
          </mat-card-content>
          <mat-card-actions align="end">
            <button mat-button color="warn" (click)="removePrize(i)">Удалить</button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  `,
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ProjectTypePrizesFormComponent),
    },
  ]
})
export class ProjectTypePrizesFormComponent implements OnInit, ControlValueAccessor, OnDestroy {

  settings$: BehaviorSubject<ProjectTypeSettings> = new BehaviorSubject<ProjectTypeSettings>(undefined);
  form: FormArray = new FormArray([]);
  visibility = {
    addButton: true,
  };
  private destroyed$: Subject<void> = new Subject<void>();

  @Input()
  set settings(value: ProjectTypeSettings) {
    this.settings$.next(value);
  }

  get settings() {
    return this.settings$.value;
  }

  constructor() {
  }

  createPrizeForm(value?: any) {
    const prizeForm = new FormGroup({
      name: new FormControl('', {validators: Validators.required}),
      description: new FormControl(''),
      cost: new FormControl(0, {validators: Validators.required}),
      quantity: new FormControl(!!value?.settings?.quantityEdit ? 0 : 1, {validators: Validators.required}),
      url: new FormControl('', {validators: Validators.required}),
      bill: new FormControl('', {validators: Validators.required}),
      cardNumber: new FormControl('', {validators: Validators.required}),
      item: new FormControl('', {validators: Validators.required}),
      settings: new FormGroup({
        minQuantity: new FormControl(1, {validators: Validators.required}),
        maxQuantity: new FormControl(999, {validators: Validators.required}),
        minCost: new FormControl(1, {validators: Validators.required}),
        maxCost: new FormControl(9999999999999, {validators: Validators.required}),
        quantityEdit: new FormControl(false, {validators: Validators.required}),
        costEdit: new FormControl(false, {validators: Validators.required}),
      })
    });
    if (!!value) {
      prizeForm.patchValue(value);
    }
    return prizeForm;
  }

  addPrize() {
    this.form.push(this.createPrizeForm());
  }

  removePrize(i: number) {
    this.form.removeAt(i);
  }

  ngOnInit(): void {
    this.settings$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe((settings) => {
        this.calculateFormVisibility(settings);
      });

    this.form
      .valueChanges
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe((value) => {
        this.onChange(value);
        this.calculateFormVisibility();
      });
  }

  calculateFormVisibility(settings?: ProjectTypeSettings) {
    if (!settings) {
      settings = this.settings$.value;
    }

    if (this.settings?.prizeUseStore) {
      this.visibility.addButton = false;
    } else {
      if (this.form?.length > 0 && !settings.prizeMultiple) {
        this.visibility.addButton = false;
      } else {
        this.visibility.addButton = true;
      }
    }


  }

  generateForm(prizes: any[]) {
    while (this.form.controls.length) {
      this.form.removeAt(0);
    }
    for (const prize of prizes) {
      this.form.push(
        this.createPrizeForm(prize)
      );
    }
  }

  writeValue(obj: any): void {
    if (!!obj && Array.isArray(obj)) {
      this.generateForm(obj);
    }
    this.form.patchValue(obj, {emitEvent: false});
    this.calculateFormVisibility();
  }

  onChange(value: any) {
  }

  onTouched() {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnDestroy(): void {
    if (this.destroyed$) {
      this.destroyed$.next();
      this.destroyed$.complete();
    }
  }

  isAllQuantityEditable() {
    const prizes = this.form?.value;
    const selectedLength = prizes?.filter(p => p?.settings?.quantityEdit)?.length || 0;
    const allLength = prizes?.length || 0;
    return selectedLength === allLength;
  }

  isSomeQuantityEditable() {
    const prizes = this.form?.value;
    const selectedLength = prizes?.filter(p => p?.settings?.quantityEdit)?.length || 0;
    const allLength = prizes?.length || 0;
    return selectedLength > 0 && selectedLength < allLength;
  }

  setAllQuantityEditable(checked: boolean) {
    this.form.controls.forEach((control, index) => {
      this.form.at(index).get('settings').get('quantityEdit').setValue(checked);
    });
  }

  isAllCostEditable() {
    const prizes = this.form?.value;
    const selectedLength = prizes?.filter(p => p?.settings?.costEdit)?.length || 0;
    const allLength = prizes?.length || 0;
    return selectedLength === allLength;
  }

  isSomeCostEditable() {
    const prizes = this.form?.value;
    const selectedLength = prizes?.filter(p => p?.settings?.costEdit)?.length || 0;
    const allLength = prizes?.length || 0;
    return selectedLength > 0 && selectedLength < allLength;
  }

  setAllCostEditable(checked: boolean) {
    this.form.controls.forEach((control, index) => {
      this.form.at(index).get('settings').get('costEdit').setValue(checked);
    });
  }
}
