import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  template: `
    <div class="text-center">
      <h6 class="m-0 pb-16" style="white-space: pre-wrap; line-height: 1.4;">{{ data.message }}</h6>
      <div mat-dialog-content>
        <mat-icon class="icon">check_circle_outline</mat-icon>
      </div>
    </div>`,
  styles: [`
    .mat-dialog-content {
      min-height: 250px;
    }`, `
    mat-icon {
      color: lightgreen;
      font-size: 250px;
      height: 250px;
      width: 250px;
      line-height: 250px;
      overflow: hidden;
    }
  `
  ]
})
export class AppSuccessComponent implements OnInit {
  title;
  message;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
  }

}
