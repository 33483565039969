import {Component, Input, OnInit} from '@angular/core';
import {NgxPermissionsService} from 'ngx-permissions';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.template.html'
})
export class SidenavComponent implements OnInit {
  @Input('items') public menuItems: any[] = [];
  @Input('hasIconMenu') public hasIconTypeMenuItem: boolean;
  @Input('iconMenuTitle') public iconTypeMenuTitle: string;

  constructor(private permissions: NgxPermissionsService) {
  }

  ngOnInit() {
  }
}
