import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormArray, FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {FormsService} from '../forms.service';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import * as _ from 'lodash';
import {ProductMorionGroup} from '../../../../api/models/product-morion-group';
import {ProductDto} from '../../../../api/models/product-dto';

@Component({
  selector: 'app-product-form',
  template: `
    <form [formGroup]="form" fxLayout="column">
      <mat-form-field fxFlex>
        <mat-label ngx-translate>NAME</mat-label>
        <input matInput formControlName="name">
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label ngx-translate>FULL_NAME</mat-label>
        <input matInput formControlName="fullName">
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label ngx-translate>PACK_SIZE</mat-label>
        <input matInput formControlName="packSize">
      </mat-form-field>
      <app-checkbox formControlName="govPriceReg" fxFlex>
        {{'GOV_PRICE_REG' | translate}}
      </app-checkbox>
      <app-checkbox formControlName="reimbursement" fxFlex>
        {{'REIMBURSEMENT' | translate}}
      </app-checkbox>
      <app-company-select formControlName="company"></app-company-select>
      <mat-form-field fxFlex>
        <mat-label ngx-translate>VALID_FROM</mat-label>
        <app-date-picker formControlName="validFrom"></app-date-picker>
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label ngx-translate>VALID_TO</mat-label>
        <app-date-picker formControlName="validTo"></app-date-picker>
      </mat-form-field>
      <app-division-select multiple="true" formControlName="divisions" [company]="company" fxFlex>
      </app-division-select>
      <mat-form-field fxFlex>
        <mat-label ngx-translate>DIRECTION</mat-label>
        <input matInput formControlName="direction">
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label ngx-translate>BRAND</mat-label>
        <input matInput formControlName="brand">
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label ngx-translate>ROW</mat-label>
        <input matInput formControlName="row">
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label ngx-translate>PRIORITY_GROUP</mat-label>
        <input matInput formControlName="priorityGroup">
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label ngx-translate>PRODUCT_MANAGER</mat-label>
        <input matInput formControlName="productManager">
      </mat-form-field>
      <div formGroupName="morion" fxLayout="column">
        <mat-form-field fxFlex>
          <mat-label ngx-translate>MORION_CODE</mat-label>
          <input matInput formControlName="code">
        </mat-form-field>
        <mat-card>
          <mat-card-header fxLayout="row">
            <span ngx-translate fxFlex style="line-height: 40px">MORION_GROUPS</span>
            <button mat-button color="primary" (click)="addMorionGroup()" fxFlex="100px">
              <mat-icon>add</mat-icon>
            </button>
          </mat-card-header>
          <mat-card-content>
            <mat-list formGroupName="groups">
              <mat-list-item *ngFor="let control of morionGroups.controls; let i = index" [formGroupName]="i">
                <mat-form-field fxFlex>
                  <mat-label ngx-translate>NAME</mat-label>
                  <input matInput formControlName="name">
                </mat-form-field>
                <app-morion-group-type-select formControlName="type"></app-morion-group-type-select>
                <button mat-button color="warn" (click)="deleteMorionGroup(i)">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-list-item>
            </mat-list>
          </mat-card-content>
        </mat-card>
      </div>
    </form>
  `
})
export class ProductFormComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  value: ProductDto;

  @Output()
  valueChange: EventEmitter<ProductDto> = new EventEmitter<ProductDto>();

  @Output()
  validChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  loading = true;
  form: FormGroup;
  destroyed$: Subject<void> = new Subject<void>();

  get morionGroups(): FormArray {
    return this.form.get('morion').get('groups') as FormArray;
  }

  get company() {
    return this.form?.get('company')?.value || undefined;
  }

  constructor(private formsService: FormsService) {
    this.form = this.formsService.product();
  }

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        this.valueChange.emit(value);
      });

    this.form.statusChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.validChange.emit(this.form.valid);
      });

    this.form.get('company').valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.form.get('divisions').setValue([]);
      });
  }

  ngOnDestroy(): void {
    if (this.destroyed$) {
      this.destroyed$.next();
      this.destroyed$.complete();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('value')) {
      if (_.isEqual(changes.value.currentValue, changes.value.previousValue)) {
        return;
      }
      const groups = _.get(changes, 'value.currentValue.morion.groups', []) as ProductMorionGroup[];
      this.morionGroups.clear();
      groups.forEach(() => {
        this.morionGroups.push(this.formsService.productMorionGroup());
      });
      this.form.patchValue(changes.value.currentValue);
      this.form.updateValueAndValidity();
    }
  }

  addMorionGroup() {
    this.morionGroups.push(this.formsService.productMorionGroup());
  }

  deleteMorionGroup(index: number) {
    this.morionGroups.removeAt(index);
  }

}
