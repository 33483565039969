import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule as AngularFormsModule, ReactiveFormsModule} from '@angular/forms';
import {ProductFormComponent} from './product-form/product-form.component';
import {SharedMaterialModule} from '../../shared-material.module';
import {TranslateModule} from '@ngx-translate/core';
import {FormsService} from './forms.service';
import {FlexModule} from '@angular/flex-layout';
import {SharedComponentsModule} from '../shared-components.module';
import {AreaSelectComponent} from './area-select/area-select.component';
import {DatePickerComponent} from './date-picker/date-picker.component';
import {ListSelectComponent} from './list-select/list-select.component';
import {MonthPickerComponent} from './month-picker/month-picker.component';
import {OrganizationInAreaSelectComponent} from './organization-in-area-select/organization-in-area-select.component';
import {TenderTypeSelectComponent} from './tender-type-select/tender-type-select.component';
import {MonthPickerHeaderComponent} from './month-picker/month-picker-header.component';
import {DivisionSelectComponent} from './division-select/division-select.component';
import {CheckboxComponent} from './checkbox/checkbox.component';
import {CompanySelectComponent} from './company-select/company-select.component';
import {MorionGroupTypeSelectComponent} from './morion-group-type-select/morion-group-type-select.component';
import {BaseProductSelectComponent} from './base-product-select/base-product-select.component';
import {TenderProductPercentsFormComponent} from './tender-product-percents-form/tender-product-percents-form.component';
import {StructFormComponent} from './struct-form/struct-form.component';
import {StructSelectComponent} from './struct-select/struct-select.component';
import {PositionSelectComponent} from './position-select/position-select.component';
import {PermissionSelectComponent} from './permission-select/permission-select.component';
import {UserSelectComponent} from './user-select/user-select.component';
import {UserFormComponent} from './user-form/user-form.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {TenderTermFormComponent} from './tender-term-form/tender-term-form.component';
import {MpSelectComponent} from './mp-select/mp-select.component';
import {ProjectTypeSelectComponent} from './project-type-select/project-type-select.component';
import {ContactSelectComponent} from './contact-select/contact-select.component';
import {ProjectPrizesFormComponent} from './project-prizes-form/project-prizes-form.component';
import {ProjectPlanFormComponent} from './project-plan-form/project-plan-form.component';
import {SharedPipesModule} from '../../pipes/shared-pipes.module';
import {RxStructSelectComponent} from './rx-struct-select/rx-struct-select.component';
import {RxTotalStructSelectComponent} from './rx-total-struct-select/rx-total-struct-select.component';
import {TotalStructSelectComponent} from './total-struct-select/total-struct-select.component';
import {TenderFormComponent} from './tender-form/tender-form.component';
import {TenderTermSelectComponent} from './tender-term-select/tender-term-select.component';
import {TenderMonthSelectComponent} from './tender-month-select/tender-month-select.component';
import {TenderProductSalesFormComponent} from './tender-product-sales-form/tender-product-sales-form.component';
import {TenderTermProductsSelectComponent} from './tender-term-products-select/tender-term-products-select.component';
import {TenderCardsFormComponent} from './tender-cards-form/tender-cards-form.component';
import {ProjectTypeFormComponent} from './project-type-form/project-type-form.component';
import {ProjectKindSelectComponent} from './project-kind-select/project-kind-select.component';
import {PrizeTypeSelectComponent} from './prize-type-select/prize-type-select.component';
import {SpecialitySelectComponent} from './speciality-select/speciality-select.component';
import {ProductSelectComponent} from './product-select/product-select.component';
import {ProjectTypeScoreFormComponent} from './project-type-score-form/project-type-score-form.component';
import {ProjectTypeSettingsFormComponent} from './project-type-settings-form/project-type-settings-form.component';
import {YearPickerComponent} from './year-picker/year-picker.component';
import {YearPickerHeaderComponent} from './year-picker/year-picker-header.component';
import {ProjectTypePrizesFormComponent} from './project-type-prizes/project-type-prizes-form.component';
import {DividedProjectFormComponent} from './divided-project-form/divided-project-form.component';
import {DividedProjectPercentFormComponent} from './divided-project-percent-form/divided-project-percent-form.component';
import {LabelInputComponent} from './label-input/label-input.component';
import {FormlyModule} from '@ngx-formly/core';
import {FormlyMaterialModule} from '@ngx-formly/material';


const components = [
  AreaSelectComponent,
  DatePickerComponent,
  ListSelectComponent,
  MonthPickerHeaderComponent,
  MonthPickerComponent,
  OrganizationInAreaSelectComponent,
  ProductFormComponent,
  TenderTypeSelectComponent,
  DivisionSelectComponent,
  CheckboxComponent,
  CompanySelectComponent,
  MorionGroupTypeSelectComponent,
  BaseProductSelectComponent,
  TenderProductPercentsFormComponent,
  StructFormComponent,
  StructSelectComponent,
  PositionSelectComponent,
  PermissionSelectComponent,
  UserSelectComponent,
  UserFormComponent,
  MpSelectComponent,
  ProjectTypeSelectComponent,
  ContactSelectComponent,
  ProjectPrizesFormComponent,
  TenderTermFormComponent,
  ProjectPlanFormComponent,
  RxStructSelectComponent,
  RxTotalStructSelectComponent,
  TotalStructSelectComponent,
  TenderFormComponent,
  TenderTermSelectComponent,
  TenderMonthSelectComponent,
  TenderProductSalesFormComponent,
  TenderTermProductsSelectComponent,
  TenderCardsFormComponent,
  ProjectTypeFormComponent,
  ProjectKindSelectComponent,
  PrizeTypeSelectComponent,
  SpecialitySelectComponent,
  ProductSelectComponent,
  ProjectTypeScoreFormComponent,
  ProjectTypeSettingsFormComponent,
  YearPickerComponent,
  YearPickerHeaderComponent,
  ProjectTypePrizesFormComponent,
  DividedProjectFormComponent,
  DividedProjectPercentFormComponent,
  LabelInputComponent
];

@NgModule({
  declarations: [
    ...components,
  ],
  imports: [
    CommonModule,
    SharedMaterialModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    TranslateModule,
    FlexModule,
    SharedComponentsModule,
    AngularFormsModule,
    SharedPipesModule,
    FormlyModule.forRoot(),
    FormlyMaterialModule
  ],
  exports: [
    AngularFormsModule,
    ReactiveFormsModule,
    ...components
  ],
  providers: [
    FormsService
  ]
})
export class FormsModule {
}
