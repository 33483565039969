/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { NdRemainDto } from '../models/nd-remain-dto';
import { NdUploadReportDto } from '../models/nd-upload-report-dto';
import { NdWarehouseInvalidDto } from '../models/nd-warehouse-invalid-dto';
import { SearchSelectItemDto } from '../models/search-select-item-dto';

@Injectable({
  providedIn: 'root',
})
export class NdService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation ndControllerUploadReports
   */
  static readonly NdControllerUploadReportsPath = '/api/nd/upload';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ndControllerUploadReports()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  ndControllerUploadReports$Response(params: {
      body: NdUploadReportDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, NdService.NdControllerUploadReportsPath, 'post');
    if (params) {


      rb.body(params.body, 'multipart/form-data');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ndControllerUploadReports$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  ndControllerUploadReports(params: {
      body: NdUploadReportDto
  }): Observable<void> {

    return this.ndControllerUploadReports$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation ndControllerWarehousesSelect
   */
  static readonly NdControllerWarehousesSelectPath = '/api/nd/warehouses/select';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ndControllerWarehousesSelect()` instead.
   *
   * This method doesn't expect any request body.
   */
  ndControllerWarehousesSelect$Response(params?: {

  }): Observable<StrictHttpResponse<Array<SearchSelectItemDto>>> {

    const rb = new RequestBuilder(this.rootUrl, NdService.NdControllerWarehousesSelectPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SearchSelectItemDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ndControllerWarehousesSelect$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ndControllerWarehousesSelect(params?: {

  }): Observable<Array<SearchSelectItemDto>> {

    return this.ndControllerWarehousesSelect$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SearchSelectItemDto>>) => r.body as Array<SearchSelectItemDto>)
    );
  }

  /**
   * Path part for operation ndControllerNotSyncedProducts
   */
  static readonly NdControllerNotSyncedProductsPath = '/api/nd/not-synced/products';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ndControllerNotSyncedProducts()` instead.
   *
   * This method doesn't expect any request body.
   */
  ndControllerNotSyncedProducts$Response(params?: {

  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, NdService.NdControllerNotSyncedProductsPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ndControllerNotSyncedProducts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ndControllerNotSyncedProducts(params?: {

  }): Observable<Array<string>> {

    return this.ndControllerNotSyncedProducts$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation ndControllerNotSyncedProductsSave
   */
  static readonly NdControllerNotSyncedProductsSavePath = '/api/nd/not-synced/products';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ndControllerNotSyncedProductsSave()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ndControllerNotSyncedProductsSave$Response(params: {
      body: Array<string>
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, NdService.NdControllerNotSyncedProductsSavePath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ndControllerNotSyncedProductsSave$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ndControllerNotSyncedProductsSave(params: {
      body: Array<string>
  }): Observable<void> {

    return this.ndControllerNotSyncedProductsSave$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation ndControllerNotSyncedAreas
   */
  static readonly NdControllerNotSyncedAreasPath = '/api/nd/not-synced/areas';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ndControllerNotSyncedAreas()` instead.
   *
   * This method doesn't expect any request body.
   */
  ndControllerNotSyncedAreas$Response(params?: {

  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, NdService.NdControllerNotSyncedAreasPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ndControllerNotSyncedAreas$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ndControllerNotSyncedAreas(params?: {

  }): Observable<Array<string>> {

    return this.ndControllerNotSyncedAreas$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation ndControllerNotSyncedAreasSave
   */
  static readonly NdControllerNotSyncedAreasSavePath = '/api/nd/not-synced/areas';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ndControllerNotSyncedAreasSave()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ndControllerNotSyncedAreasSave$Response(params: {
      body: Array<string>
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, NdService.NdControllerNotSyncedAreasSavePath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ndControllerNotSyncedAreasSave$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ndControllerNotSyncedAreasSave(params: {
      body: Array<string>
  }): Observable<void> {

    return this.ndControllerNotSyncedAreasSave$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation ndControllerNotSyncedWarehouses
   */
  static readonly NdControllerNotSyncedWarehousesPath = '/api/nd/not-synced/warehouses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ndControllerNotSyncedWarehouses()` instead.
   *
   * This method doesn't expect any request body.
   */
  ndControllerNotSyncedWarehouses$Response(params?: {

  }): Observable<StrictHttpResponse<Array<NdWarehouseInvalidDto>>> {

    const rb = new RequestBuilder(this.rootUrl, NdService.NdControllerNotSyncedWarehousesPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<NdWarehouseInvalidDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ndControllerNotSyncedWarehouses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ndControllerNotSyncedWarehouses(params?: {

  }): Observable<Array<NdWarehouseInvalidDto>> {

    return this.ndControllerNotSyncedWarehouses$Response(params).pipe(
      map((r: StrictHttpResponse<Array<NdWarehouseInvalidDto>>) => r.body as Array<NdWarehouseInvalidDto>)
    );
  }

  /**
   * Path part for operation ndControllerNotSyncedWarehousesSave
   */
  static readonly NdControllerNotSyncedWarehousesSavePath = '/api/nd/not-synced/warehouses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ndControllerNotSyncedWarehousesSave()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ndControllerNotSyncedWarehousesSave$Response(params: {
      body: Array<string>
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, NdService.NdControllerNotSyncedWarehousesSavePath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ndControllerNotSyncedWarehousesSave$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ndControllerNotSyncedWarehousesSave(params: {
      body: Array<string>
  }): Observable<void> {

    return this.ndControllerNotSyncedWarehousesSave$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation ndControllerRemainsByArea
   */
  static readonly NdControllerRemainsByAreaPath = '/api/nd/remains/by-area';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ndControllerRemainsByArea()` instead.
   *
   * This method doesn't expect any request body.
   */
  ndControllerRemainsByArea$Response(params: {

    /**
     * id области
     */
    area: string;

    /**
     * дата
     */
    date?: string;

  }): Observable<StrictHttpResponse<Array<NdRemainDto>>> {

    const rb = new RequestBuilder(this.rootUrl, NdService.NdControllerRemainsByAreaPath, 'get');
    if (params) {

      rb.query('area', params.area, {});
      rb.query('date', params.date, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<NdRemainDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ndControllerRemainsByArea$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ndControllerRemainsByArea(params: {

    /**
     * id области
     */
    area: string;

    /**
     * дата
     */
    date?: string;

  }): Observable<Array<NdRemainDto>> {

    return this.ndControllerRemainsByArea$Response(params).pipe(
      map((r: StrictHttpResponse<Array<NdRemainDto>>) => r.body as Array<NdRemainDto>)
    );
  }

  /**
   * Path part for operation ndControllerSyncCallback
   */
  static readonly NdControllerSyncCallbackPath = '/api/nd/callback';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ndControllerSyncCallback()` instead.
   *
   * This method doesn't expect any request body.
   */
  ndControllerSyncCallback$Response(params?: {

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, NdService.NdControllerSyncCallbackPath, 'post');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ndControllerSyncCallback$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ndControllerSyncCallback(params?: {

  }): Observable<void> {

    return this.ndControllerSyncCallback$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
