import {HttpClient} from '@angular/common/http';
import {TranslateLoader} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

export class TranslationLoaderService implements TranslateLoader {

  constructor(private http: HttpClient) {
  }

  getTranslation(lang: string): Observable<any> {
    return this.http.get(`${environment.apiURL}/api/translations/${lang}`);
  }
}
