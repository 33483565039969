<button
  mat-icon-button
  matTooltip="Messages"
  (click)="gotoMessages()"
  [style.overflow]="'visible'"
  class="topbar-button-right"
>
  <mat-icon>messages</mat-icon>
  <span class="notification-number mat-bg-warn" *ngIf="(count$ | async) > 0" >{{count$ | async}}</span>
</button>
