import {Pipe, PipeTransform} from '@angular/core';
import {freeze} from '../decorators/freeze';

@Pipe({
  name: 'yesNo',
})
export class YesNoPipe implements PipeTransform {

  @freeze()
  transform(value: any, yesString: string = 'YES', noString: string = 'NO'): any {
    if (typeof value === 'string') {
      return value === 'true' ? yesString : noString;
    }
    return !!value ? yesString : noString;
  }

}
