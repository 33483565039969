import {ChangeDetectorRef, Component, forwardRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FormArray, FormControl, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {StructService} from '../../../../api/services/struct.service';
import {filter, startWith, switchMap, takeUntil, tap} from 'rxjs/operators';
import {combineLatest, Subject} from 'rxjs';
import {DivisionStructsSelectsDto} from '../../../../api/models/division-structs-selects-dto';

@Component({
  selector: 'app-rx-struct-select',
  template: `
    <form [formGroup]="this.control" *ngIf="!loading" fxLayout="column">
      <mat-form-field fxFlex *ngFor="let division of options; let i = index">
        <mat-label translate>{{division.division}}</mat-label>
        <app-search-select [formControlName]="i" [loading]="loading"
                           [multiple]="false" [options]="division.options">
        </app-search-select>
      </mat-form-field>
    </form>
    <mat-spinner *ngIf="loading && this.company && this.area"></mat-spinner>
  `,
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => RxStructSelectComponent),
    },
  ],
})
export class RxStructSelectComponent implements OnInit, OnDestroy, OnChanges {

  // @Input() year: number;
  // year$: Subject<number> = new Subject<number>();
  // @Input() month: number;
  // month$: Subject<number> = new Subject<number>();
  @Input() company: string;
  company$: Subject<string> = new Subject<string>();
  @Input() area: string;
  area$: Subject<string> = new Subject<string>();

  destroyed$: Subject<void> = new Subject<void>();

  options: DivisionStructsSelectsDto[];
  value: any[];
  loading = true;
  control: FormArray = new FormArray([]);

  constructor(private structService: StructService,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    combineLatest([
      this.company$,
      this.area$
    ])
      .pipe(
        tap(() => this.loading = true),
        takeUntil(this.destroyed$),
        startWith([this.company, this.area]),
        filter(([company, area]) => !!company && !!area),
        switchMap(([company, area]) => {
          return this.structService.structControllerMpByDivisionSelects({
            company, area
          });
        })
      )
      .subscribe((options) => {
        this.options = options;
        this.generateForm(options, this.value);
        this.loading = false;
        this.cdr.detectChanges();
      });

    this.control.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.onChange(value);
      });
  }

  /**
   * Function to call when the date changes.
   * @param {any} value
   */
  private onChange(value: any) {
  }

  /**
   * Function to call when the input is touched.
   */
  private onTouched() {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
    this.control.patchValue(obj);
  }

  ngOnDestroy(): void {
    if (this.destroyed$) {
      this.destroyed$.next();
      this.destroyed$.complete();
    }
  }

  generateForm(options, values: any[]) {
    if (options.length !== this.control.length) {
      while (this.control.controls.length) {
        this.control.removeAt(0);
      }
      options.forEach(() => {
        this.control.push(new FormControl(undefined));
      });
      this.control.patchValue(values);
    } else {
      this.control.patchValue(values);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes.hasOwnProperty('year')) {
    //   this.year$.next(changes.year.currentValue);
    // }
    // if (changes.hasOwnProperty('month')) {
    //   this.month$.next(changes.month.currentValue);
    // }
    if (changes.hasOwnProperty('area')) {
      this.area$.next(changes.area.currentValue);
    }
    if (changes.hasOwnProperty('company')) {
      this.company$.next(changes.company.currentValue);
    }
  }

}
