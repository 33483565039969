/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ParticipartMapDto } from '../models/participart-map-dto';
import { ParticipartPrizeDto } from '../models/participart-prize-dto';
import { PharmacyParticipant } from '../models/pharmacy-participant';
import { PharmacyPromotionDto } from '../models/pharmacy-promotion-dto';
import { PharmacyPromotionExtraPacksRequstDto } from '../models/pharmacy-promotion-extra-packs-requst-dto';
import { PharmacyPromotionPeriodDto } from '../models/pharmacy-promotion-period-dto';

@Injectable({
  providedIn: 'root',
})
export class PromotionTermsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation promotionTermsControllerActivateMininet
   */
  static readonly PromotionTermsControllerActivateMininetPath = '/api/promotion-terms/activate-mininet/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promotionTermsControllerActivateMininet()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotionTermsControllerActivateMininet$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<PharmacyParticipant>> {

    const rb = new RequestBuilder(this.rootUrl, PromotionTermsService.PromotionTermsControllerActivateMininetPath, 'post');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PharmacyParticipant>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promotionTermsControllerActivateMininet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotionTermsControllerActivateMininet(params: {
    id: string;

  }): Observable<PharmacyParticipant> {

    return this.promotionTermsControllerActivateMininet$Response(params).pipe(
      map((r: StrictHttpResponse<PharmacyParticipant>) => r.body as PharmacyParticipant)
    );
  }

  /**
   * Path part for operation promotionTermsControllerDeactivateMininet
   */
  static readonly PromotionTermsControllerDeactivateMininetPath = '/api/promotion-terms/deactivate-mininet/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promotionTermsControllerDeactivateMininet()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotionTermsControllerDeactivateMininet$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<PharmacyParticipant>> {

    const rb = new RequestBuilder(this.rootUrl, PromotionTermsService.PromotionTermsControllerDeactivateMininetPath, 'post');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PharmacyParticipant>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promotionTermsControllerDeactivateMininet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotionTermsControllerDeactivateMininet(params: {
    id: string;

  }): Observable<PharmacyParticipant> {

    return this.promotionTermsControllerDeactivateMininet$Response(params).pipe(
      map((r: StrictHttpResponse<PharmacyParticipant>) => r.body as PharmacyParticipant)
    );
  }

  /**
   * Path part for operation promotionTermsControllerUpdatePrizes
   */
  static readonly PromotionTermsControllerUpdatePrizesPath = '/api/promotion-terms/set-prizes/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promotionTermsControllerUpdatePrizes()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  promotionTermsControllerUpdatePrizes$Response(params: {
    id: string;
      body: Array<ParticipartPrizeDto>
  }): Observable<StrictHttpResponse<PharmacyParticipant>> {

    const rb = new RequestBuilder(this.rootUrl, PromotionTermsService.PromotionTermsControllerUpdatePrizesPath, 'post');
    if (params) {

      rb.path('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PharmacyParticipant>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promotionTermsControllerUpdatePrizes$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  promotionTermsControllerUpdatePrizes(params: {
    id: string;
      body: Array<ParticipartPrizeDto>
  }): Observable<PharmacyParticipant> {

    return this.promotionTermsControllerUpdatePrizes$Response(params).pipe(
      map((r: StrictHttpResponse<PharmacyParticipant>) => r.body as PharmacyParticipant)
    );
  }

  /**
   * Path part for operation promotionTermsControllerCreate
   */
  static readonly PromotionTermsControllerCreatePath = '/api/promotion-terms/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promotionTermsControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  promotionTermsControllerCreate$Response(params: {
      body: PharmacyPromotionDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PromotionTermsService.PromotionTermsControllerCreatePath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promotionTermsControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  promotionTermsControllerCreate(params: {
      body: PharmacyPromotionDto
  }): Observable<void> {

    return this.promotionTermsControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation promotionTermsControllerList
   */
  static readonly PromotionTermsControllerListPath = '/api/promotion-terms/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promotionTermsControllerList()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotionTermsControllerList$Response(params: {
    year: number;
    month: number;

  }): Observable<StrictHttpResponse<Array<PharmacyPromotionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PromotionTermsService.PromotionTermsControllerListPath, 'get');
    if (params) {

      rb.query('year', params.year, {});
      rb.query('month', params.month, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PharmacyPromotionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promotionTermsControllerList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotionTermsControllerList(params: {
    year: number;
    month: number;

  }): Observable<Array<PharmacyPromotionDto>> {

    return this.promotionTermsControllerList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PharmacyPromotionDto>>) => r.body as Array<PharmacyPromotionDto>)
    );
  }

  /**
   * Path part for operation promotionTermsControllerParticipantMap
   */
  static readonly PromotionTermsControllerParticipantMapPath = '/api/promotion-terms/participant-map';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promotionTermsControllerParticipantMap()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotionTermsControllerParticipantMap$Response(params: {
    limit: number;
    skip: number;
    year: number;
    month: number;
    withScore: boolean;
    search: string;

  }): Observable<StrictHttpResponse<ParticipartMapDto>> {

    const rb = new RequestBuilder(this.rootUrl, PromotionTermsService.PromotionTermsControllerParticipantMapPath, 'get');
    if (params) {

      rb.query('limit', params.limit, {});
      rb.query('skip', params.skip, {});
      rb.query('year', params.year, {});
      rb.query('month', params.month, {});
      rb.query('withScore', params.withScore, {});
      rb.query('search', params.search, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ParticipartMapDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promotionTermsControllerParticipantMap$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotionTermsControllerParticipantMap(params: {
    limit: number;
    skip: number;
    year: number;
    month: number;
    withScore: boolean;
    search: string;

  }): Observable<ParticipartMapDto> {

    return this.promotionTermsControllerParticipantMap$Response(params).pipe(
      map((r: StrictHttpResponse<ParticipartMapDto>) => r.body as ParticipartMapDto)
    );
  }

  /**
   * Path part for operation promotionTermsControllerParticipantPoints
   */
  static readonly PromotionTermsControllerParticipantPointsPath = '/api/promotion-terms/participant/{id}/points';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promotionTermsControllerParticipantPoints()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotionTermsControllerParticipantPoints$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<Array<PharmacyParticipant>>> {

    const rb = new RequestBuilder(this.rootUrl, PromotionTermsService.PromotionTermsControllerParticipantPointsPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PharmacyParticipant>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promotionTermsControllerParticipantPoints$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotionTermsControllerParticipantPoints(params: {
    id: string;

  }): Observable<Array<PharmacyParticipant>> {

    return this.promotionTermsControllerParticipantPoints$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PharmacyParticipant>>) => r.body as Array<PharmacyParticipant>)
    );
  }

  /**
   * Path part for operation promotionTermsControllerGetParticipant
   */
  static readonly PromotionTermsControllerGetParticipantPath = '/api/promotion-terms/participant/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promotionTermsControllerGetParticipant()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotionTermsControllerGetParticipant$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<PharmacyParticipant>> {

    const rb = new RequestBuilder(this.rootUrl, PromotionTermsService.PromotionTermsControllerGetParticipantPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PharmacyParticipant>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promotionTermsControllerGetParticipant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotionTermsControllerGetParticipant(params: {
    id: string;

  }): Observable<PharmacyParticipant> {

    return this.promotionTermsControllerGetParticipant$Response(params).pipe(
      map((r: StrictHttpResponse<PharmacyParticipant>) => r.body as PharmacyParticipant)
    );
  }

  /**
   * Path part for operation promotionTermsControllerPeriods
   */
  static readonly PromotionTermsControllerPeriodsPath = '/api/promotion-terms/periods';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promotionTermsControllerPeriods()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotionTermsControllerPeriods$Response(params?: {

  }): Observable<StrictHttpResponse<Array<PharmacyPromotionPeriodDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PromotionTermsService.PromotionTermsControllerPeriodsPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PharmacyPromotionPeriodDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promotionTermsControllerPeriods$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotionTermsControllerPeriods(params?: {

  }): Observable<Array<PharmacyPromotionPeriodDto>> {

    return this.promotionTermsControllerPeriods$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PharmacyPromotionPeriodDto>>) => r.body as Array<PharmacyPromotionPeriodDto>)
    );
  }

  /**
   * Path part for operation promotionTermsControllerGetTermProducts
   */
  static readonly PromotionTermsControllerGetTermProductsPath = '/api/promotion-terms/{id}/products';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promotionTermsControllerGetTermProducts()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotionTermsControllerGetTermProducts$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, PromotionTermsService.PromotionTermsControllerGetTermProductsPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promotionTermsControllerGetTermProducts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotionTermsControllerGetTermProducts(params: {
    id: string;

  }): Observable<Array<string>> {

    return this.promotionTermsControllerGetTermProducts$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation promotionTermsControllerGet
   */
  static readonly PromotionTermsControllerGetPath = '/api/promotion-terms/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promotionTermsControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotionTermsControllerGet$Response(params: {
    id: string;

  }): Observable<StrictHttpResponse<PharmacyPromotionDto>> {

    const rb = new RequestBuilder(this.rootUrl, PromotionTermsService.PromotionTermsControllerGetPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PharmacyPromotionDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promotionTermsControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotionTermsControllerGet(params: {
    id: string;

  }): Observable<PharmacyPromotionDto> {

    return this.promotionTermsControllerGet$Response(params).pipe(
      map((r: StrictHttpResponse<PharmacyPromotionDto>) => r.body as PharmacyPromotionDto)
    );
  }

  /**
   * Path part for operation promotionTermsControllerUpdate
   */
  static readonly PromotionTermsControllerUpdatePath = '/api/promotion-terms/update/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promotionTermsControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  promotionTermsControllerUpdate$Response(params: {
    id: string;
      body: PharmacyPromotionDto
  }): Observable<StrictHttpResponse<PharmacyPromotionDto>> {

    const rb = new RequestBuilder(this.rootUrl, PromotionTermsService.PromotionTermsControllerUpdatePath, 'post');
    if (params) {

      rb.path('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PharmacyPromotionDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promotionTermsControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  promotionTermsControllerUpdate(params: {
    id: string;
      body: PharmacyPromotionDto
  }): Observable<PharmacyPromotionDto> {

    return this.promotionTermsControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<PharmacyPromotionDto>) => r.body as PharmacyPromotionDto)
    );
  }

  /**
   * Path part for operation promotionTermsControllerAddExtraPacks
   */
  static readonly PromotionTermsControllerAddExtraPacksPath = '/api/promotion-terms/extra-packs/{participantId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promotionTermsControllerAddExtraPacks()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  promotionTermsControllerAddExtraPacks$Response(params: {
    participantId: string;
      body: PharmacyPromotionExtraPacksRequstDto
  }): Observable<StrictHttpResponse<PharmacyPromotionDto>> {

    const rb = new RequestBuilder(this.rootUrl, PromotionTermsService.PromotionTermsControllerAddExtraPacksPath, 'post');
    if (params) {

      rb.path('participantId', params.participantId, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PharmacyPromotionDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promotionTermsControllerAddExtraPacks$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  promotionTermsControllerAddExtraPacks(params: {
    participantId: string;
      body: PharmacyPromotionExtraPacksRequstDto
  }): Observable<PharmacyPromotionDto> {

    return this.promotionTermsControllerAddExtraPacks$Response(params).pipe(
      map((r: StrictHttpResponse<PharmacyPromotionDto>) => r.body as PharmacyPromotionDto)
    );
  }

  /**
   * Path part for operation promotionTermsControllerReloadView
   */
  static readonly PromotionTermsControllerReloadViewPath = '/api/promotion-terms/reloadView';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promotionTermsControllerReloadView()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotionTermsControllerReloadView$Response(params: {
    year: number;
    month: number;
    company: string;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PromotionTermsService.PromotionTermsControllerReloadViewPath, 'delete');
    if (params) {

      rb.query('year', params.year, {});
      rb.query('month', params.month, {});
      rb.query('company', params.company, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promotionTermsControllerReloadView$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotionTermsControllerReloadView(params: {
    year: number;
    month: number;
    company: string;

  }): Observable<void> {

    return this.promotionTermsControllerReloadView$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
