import {AfterViewChecked, Component, forwardRef, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, FormArray, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-project-type-form',
  template: `
    <form [formGroup]="form">
      <mat-vertical-stepper>
        <mat-step>
          <ng-template matStepLabel>{{'GENERAL_INFORMATION' | translate}}</ng-template>
          <div fxLayout="column">
            <app-company-select formControlName="company" required></app-company-select>
            <app-project-kind-select [company]="company" formControlName="kind" required></app-project-kind-select>
            <mat-form-field fxFlex>
              <mat-label>Название</mat-label>
              <input matInput type="text" formControlName="name" required/>
            </mat-form-field>
            <mat-form-field fxFlex>
              <mat-label>Описание</mat-label>
              <textarea matInput type="text" formControlName="description" required></textarea>
            </mat-form-field>
            <mat-form-field fxFlex>
              <mat-label>Категория</mat-label>
              <input matInput type="text" formControlName="category"/>
            </mat-form-field>
            <mat-form-field fxFlex>
              <mat-label>Длительность (месяцы)</mat-label>
              <input matInput type="number" formControlName="period" required/>
            </mat-form-field>
            <mat-form-field fxFlex>
              <mat-label>Минимальная стоимость проекта</mat-label>
              <input matInput type="number" formControlName="minCost"/>
            </mat-form-field>
            <mat-form-field fxFlex>
              <mat-label>Порог оплаты (%)</mat-label>
              <input matInput type="number" min="0" max="100" formControlName="paymentLimit" required/>
            </mat-form-field>
            <app-area-select formControlName="areas" [multiple]="true">
            </app-area-select>
            <app-speciality-select [multiple]="true" formControlName="specialities" [company]="company">
            </app-speciality-select>
            <app-product-select [multiple]="true" formControlName="products" [company]="company">
            </app-product-select>
            <mat-form-field fxFlex>
              <mat-label>Ивент менеджер</mat-label>
              <input matInput type="text" formControlName="eventManager" required/>
            </mat-form-field>
            <mat-form-field fxFlex>
              <mat-label>Год</mat-label>
              <input matInput type="number" formControlName="year" required/>
            </mat-form-field>
          </div>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Настройки</ng-template>
          <app-project-type-settings-form formControlName="settings"></app-project-type-settings-form>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Призы</ng-template>
          <div fxLayout="column">
            <app-project-type-prizes-form formControlName="prizes" [settings]="settings">
            </app-project-type-prizes-form>
          </div>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Баллы</ng-template>
          <app-project-type-score-form formControlName="scores"
                                       [company]="company"
                                       [products]="products">
          </app-project-type-score-form>
        </mat-step>
      </mat-vertical-stepper>
    </form>`,
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ProjectTypeFormComponent),
    },
  ]
})
export class ProjectTypeFormComponent implements OnInit, ControlValueAccessor, AfterViewChecked, OnDestroy {

  form: FormGroup = new FormGroup({
    company: new FormControl(undefined, Validators.required),
    kind: new FormControl(undefined, Validators.required),
    name: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    category: new FormControl(''),
    period: new FormControl(12, Validators.required),
    minCost: new FormControl(0, Validators.required),
    paymentLimit: new FormControl(100, Validators.required),
    eventManager: new FormControl('', Validators.required),
    year: new FormControl(moment().year(), Validators.required),
    areas: new FormControl([], Validators.required),
    specialities: new FormControl(undefined, Validators.required),
    products: new FormControl([], Validators.required),
    active: new FormControl(undefined, Validators.required),
    scores: new FormControl([], Validators.required),
    prizes: new FormControl([], Validators.required),
    settings: new FormControl({
      isParallel: false,
      isBalanceAllowed: true,
      isWithoutPlan: false,
      isDivided: false,
      prizeCreateByUser: false,
      prizeLinkInvoice: false,
      prizeCardNumber: false,
      prizeMultiple: true,
      prizeChoice: false,
      prizeMultiChoice: false,
      prizeUseStore: false
    }, Validators.required),
  });

  destroyed$: Subject<void> = new Subject<void>();

  get company() {
    return this.form.get('company').value;
  }

  get products() {
    return this.form.get('products').value;
  }

  get settings() {
    return this.form.get('settings').value;
  }

  get scores() {
    return this.form.get('scores') as FormArray;
  }

  constructor() {
  }

  ngOnInit(): void {
    // this.form.get('company')
    //   .valueChanges
    //   .pipe(takeUntil(this.destroyed$))
    //   .subscribe(() => {
    //     this.form.get('kind').setValue(undefined);
    //   });
    this.form
      .valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(val => this.onChange(val));
  }

  onChange: any = () => {
  }
  onTouched: any = () => {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    this.form.patchValue(obj, {emitEvent: false});
  }

  ngAfterViewChecked(): void {
  }

  ngOnDestroy(): void {
    if (this.destroyed$) {
      this.destroyed$.next();
      this.destroyed$.complete();
    }
  }
}
