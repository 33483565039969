/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { NetworkMarketingMatrixDto } from '../models/network-marketing-matrix-dto';
import { ProductSalesInfoDto } from '../models/product-sales-info-dto';
import { SaleDistributionRow } from '../models/sale-distribution-row';
import { StructSaleAggregationRowDto } from '../models/struct-sale-aggregation-row-dto';

@Injectable({
  providedIn: 'root',
})
export class SalesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation salesControllerOrganizationSales
   */
  static readonly SalesControllerOrganizationSalesPath = '/api/sales/organizations/{year}/{month}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `salesControllerOrganizationSales()` instead.
   *
   * This method doesn't expect any request body.
   */
  salesControllerOrganizationSales$Response(params: {
    year: number;
    month: number;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SalesService.SalesControllerOrganizationSalesPath, 'get');
    if (params) {

      rb.path('year', params.year, {});
      rb.path('month', params.month, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `salesControllerOrganizationSales$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  salesControllerOrganizationSales(params: {
    year: number;
    month: number;

  }): Observable<void> {

    return this.salesControllerOrganizationSales$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation salesControllerDividedAreaData
   */
  static readonly SalesControllerDividedAreaDataPath = '/api/sales/divided-area/form-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `salesControllerDividedAreaData()` instead.
   *
   * This method doesn't expect any request body.
   */
  salesControllerDividedAreaData$Response(params: {
    year: number;
    month: number;
    company: string;
    area: string;

  }): Observable<StrictHttpResponse<Array<SaleDistributionRow>>> {

    const rb = new RequestBuilder(this.rootUrl, SalesService.SalesControllerDividedAreaDataPath, 'get');
    if (params) {

      rb.query('year', params.year, {});
      rb.query('month', params.month, {});
      rb.query('company', params.company, {});
      rb.query('area', params.area, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SaleDistributionRow>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `salesControllerDividedAreaData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  salesControllerDividedAreaData(params: {
    year: number;
    month: number;
    company: string;
    area: string;

  }): Observable<Array<SaleDistributionRow>> {

    return this.salesControllerDividedAreaData$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SaleDistributionRow>>) => r.body as Array<SaleDistributionRow>)
    );
  }

  /**
   * Path part for operation salesControllerAreaData
   */
  static readonly SalesControllerAreaDataPath = '/api/sales/area/form-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `salesControllerAreaData()` instead.
   *
   * This method doesn't expect any request body.
   */
  salesControllerAreaData$Response(params: {
    year: number;
    month: number;
    company: string;
    struct: string;

  }): Observable<StrictHttpResponse<Array<SaleDistributionRow>>> {

    const rb = new RequestBuilder(this.rootUrl, SalesService.SalesControllerAreaDataPath, 'get');
    if (params) {

      rb.query('year', params.year, {});
      rb.query('month', params.month, {});
      rb.query('company', params.company, {});
      rb.query('struct', params.struct, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SaleDistributionRow>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `salesControllerAreaData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  salesControllerAreaData(params: {
    year: number;
    month: number;
    company: string;
    struct: string;

  }): Observable<Array<SaleDistributionRow>> {

    return this.salesControllerAreaData$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SaleDistributionRow>>) => r.body as Array<SaleDistributionRow>)
    );
  }

  /**
   * Path part for operation salesControllerNotSyncedAreas
   */
  static readonly SalesControllerNotSyncedAreasPath = '/api/sales/not-synced/areas';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `salesControllerNotSyncedAreas()` instead.
   *
   * This method doesn't expect any request body.
   */
  salesControllerNotSyncedAreas$Response(params?: {

  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, SalesService.SalesControllerNotSyncedAreasPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `salesControllerNotSyncedAreas$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  salesControllerNotSyncedAreas(params?: {

  }): Observable<Array<string>> {

    return this.salesControllerNotSyncedAreas$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation salesControllerNotSyncedProducts
   */
  static readonly SalesControllerNotSyncedProductsPath = '/api/sales/not-synced/products';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `salesControllerNotSyncedProducts()` instead.
   *
   * This method doesn't expect any request body.
   */
  salesControllerNotSyncedProducts$Response(params?: {

  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, SalesService.SalesControllerNotSyncedProductsPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `salesControllerNotSyncedProducts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  salesControllerNotSyncedProducts(params?: {

  }): Observable<Array<string>> {

    return this.salesControllerNotSyncedProducts$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation salesControllerProductSalesInfo
   */
  static readonly SalesControllerProductSalesInfoPath = '/api/sales/product-sales-info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `salesControllerProductSalesInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  salesControllerProductSalesInfo$Response(params: {
    year: number;
    month: number;
    company: string;
    product: string;
    area: string;

  }): Observable<StrictHttpResponse<Array<ProductSalesInfoDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SalesService.SalesControllerProductSalesInfoPath, 'get');
    if (params) {

      rb.query('year', params.year, {});
      rb.query('month', params.month, {});
      rb.query('company', params.company, {});
      rb.query('product', params.product, {});
      rb.query('area', params.area, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProductSalesInfoDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `salesControllerProductSalesInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  salesControllerProductSalesInfo(params: {
    year: number;
    month: number;
    company: string;
    product: string;
    area: string;

  }): Observable<Array<ProductSalesInfoDto>> {

    return this.salesControllerProductSalesInfo$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProductSalesInfoDto>>) => r.body as Array<ProductSalesInfoDto>)
    );
  }

  /**
   * Path part for operation salesControllerNetworkMarketingMatrix
   */
  static readonly SalesControllerNetworkMarketingMatrixPath = '/api/sales/network-marketing-matrix';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `salesControllerNetworkMarketingMatrix()` instead.
   *
   * This method doesn't expect any request body.
   */
  salesControllerNetworkMarketingMatrix$Response(params: {
    year: number;
    month: number;
    company: string;

  }): Observable<StrictHttpResponse<NetworkMarketingMatrixDto>> {

    const rb = new RequestBuilder(this.rootUrl, SalesService.SalesControllerNetworkMarketingMatrixPath, 'post');
    if (params) {

      rb.query('year', params.year, {});
      rb.query('month', params.month, {});
      rb.query('company', params.company, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NetworkMarketingMatrixDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `salesControllerNetworkMarketingMatrix$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  salesControllerNetworkMarketingMatrix(params: {
    year: number;
    month: number;
    company: string;

  }): Observable<NetworkMarketingMatrixDto> {

    return this.salesControllerNetworkMarketingMatrix$Response(params).pipe(
      map((r: StrictHttpResponse<NetworkMarketingMatrixDto>) => r.body as NetworkMarketingMatrixDto)
    );
  }

  /**
   * Path part for operation salesControllerNetworkMarketingMatrixSave
   */
  static readonly SalesControllerNetworkMarketingMatrixSavePath = '/api/sales/network-marketing-matrix/save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `salesControllerNetworkMarketingMatrixSave()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  salesControllerNetworkMarketingMatrixSave$Response(params: {
    year: number;
    month: number;
    company: string;
      body: Array<string>
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SalesService.SalesControllerNetworkMarketingMatrixSavePath, 'post');
    if (params) {

      rb.query('year', params.year, {});
      rb.query('month', params.month, {});
      rb.query('company', params.company, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `salesControllerNetworkMarketingMatrixSave$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  salesControllerNetworkMarketingMatrixSave(params: {
    year: number;
    month: number;
    company: string;
      body: Array<string>
  }): Observable<boolean> {

    return this.salesControllerNetworkMarketingMatrixSave$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation salesControllerStructYearSales
   */
  static readonly SalesControllerStructYearSalesPath = '/api/sales/struct-sales/{year}/{struct}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `salesControllerStructYearSales()` instead.
   *
   * This method doesn't expect any request body.
   */
  salesControllerStructYearSales$Response(params: {
    year: number;
    struct: string;

  }): Observable<StrictHttpResponse<Array<StructSaleAggregationRowDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SalesService.SalesControllerStructYearSalesPath, 'get');
    if (params) {

      rb.path('year', params.year, {});
      rb.path('struct', params.struct, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StructSaleAggregationRowDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `salesControllerStructYearSales$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  salesControllerStructYearSales(params: {
    year: number;
    struct: string;

  }): Observable<Array<StructSaleAggregationRowDto>> {

    return this.salesControllerStructYearSales$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StructSaleAggregationRowDto>>) => r.body as Array<StructSaleAggregationRowDto>)
    );
  }

}
